define(() => {
    return Object.freeze({
        HOUSE: '/house',
        DEVICE: '/device',
        CONTROLLER: '/controller',
        ALARM: '/alarm',
        REPORT: '/report',
        RULE: '/rule',
        SCRIPT: '/script',
        USER: '/user',
        ANALYTIC: '/analytic',
        LICENSE: '/license',
        NO_SEND: 'noSend',
    });
});
