define([
    'knockout',
    'text!./template.html'
], (ko, template) => {

    class ViewModel {
        constructor(params) {
            this.deviceLocation = params.deviceLocation;
            this.locations = params.locations;
        }
    }

    ko.components.register('device-location-editor', {viewModel: ViewModel, template});

});
