define(['knockout'], function (ko) {
    'use strict';

    ko.bindingHandlers.maxLength = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
            const maxlength = element.getAttribute('maxlength');
            valueAccessor().extend({truncateValue: maxlength});
            ko.bindingHandlers.value.init(element, valueAccessor, allBindingsAccessor, viewModel);
        }
    };
});
