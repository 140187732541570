define([
    'knockout',
    'text!./template.html',
    'core/viewport-scroller',
], function (ko, template, ViewportScroller) {
    function ViewModel(params) {
        this.currentPage = params.currentPage;
        this.pageSize = params.pageSize;
        this.total = params.total;
        this.maxPage = ko.computed(function () {
            return Math.ceil(this.total() / this.pageSize());
        }, this);
        this.paginationNumbers = ko.computed(function () {
            var pages = [];
            var paginationRange = 9;
            var halfWay = Math.ceil(paginationRange / 2);
            var position;
            if (this.currentPage() <= halfWay) {
                position = 'start';
            } else if (this.maxPage() - halfWay < this.currentPage()) {
                position = 'end';
            } else {
                position = 'middle';
            }
            var ellipsesNeeded = paginationRange < this.maxPage();
            var i = 1;
            while (i <= this.maxPage() && i <= paginationRange) {
                var pageNumber = calculatePageNumber(i, this.currentPage(), paginationRange, this.maxPage());
                var openingEllipsesNeeded = (i === 2 && (position === 'middle' || position === 'end'));
                var closingEllipsesNeeded = (i === paginationRange - 1 && (position === 'middle' || position === 'start'));
                if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
                    pages.push('...');
                } else {
                    pages.push(pageNumber);
                }
                i++;
            }
            return pages;
        }, this);
    }

    var calculatePageNumber = function (i, currentPage, paginationRange, totalPages) {
        var halfWay = Math.ceil(paginationRange / 2);
        if (i === paginationRange) {
            return totalPages;
        } else if (i === 1) {
            return i;
        } else if (paginationRange < totalPages) {
            if (totalPages - halfWay < currentPage) {
                return totalPages - paginationRange + i;
            } else if (halfWay < currentPage) {
                return currentPage - halfWay + i;
            } else {
                return i;
            }
        } else {
            return i;
        }
    };

    ViewModel.prototype.goToPage = function (page) {
        if (typeof page === 'number' && page > 0 && page <= this.maxPage() && this.currentPage() !== page) {
            this.currentPage(page);
            ViewportScroller.scrollToPosition([1, 1]);
        }
    };

    ko.components.register('pagination', {
        viewModel: ViewModel,
        template: template
    });
});
