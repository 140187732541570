define(['knockout'], function (ko) {
    'use strict';

    ko.bindingHandlers.scrollTo = {
        update: function (element, valueAccessor) {
            const valueUnwrapped = ko.unwrap(valueAccessor());
            if (valueUnwrapped === true) {
                element.scrollIntoView();
            }
        }
    };
});
