define([], function() {
    //addEventListener(type, callback, scope)
    //dispatch(type, target)
    //removeEventListener(type, callback, scope)
    var scopes = {
        NOTIFICATIONS_BETWEEN_PAGES: "NOTIFICATIONS_BETWEEN_PAGES",
    };

    var types = {};
    types[scopes.NOTIFICATIONS_BETWEEN_PAGES] = {
        HOUSE_RENAME: "HOUSE_RENAME",
        MODAL_VISIBILITY_CHANGE: "MODAL_VISIBILITY_CHANGE"
    };

    return {
        scopes: scopes,
        types: types,
    };
});
