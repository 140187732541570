define([
    'knockout',
    'momentwithlocales'
], (ko, moment) => {
    'use strict';

    ko.bindingHandlers.convertDate = {
        update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
            const value = ko.utils.unwrapObservable(valueAccessor());
            const textContent = moment(value.date, value.inputFormat).format(value.outputFormat);
            ko.bindingHandlers.text.update(
                element,
                () => textContent,
                allBindingsAccessor,
                viewModel,
                context
            );
        }
    };
});
