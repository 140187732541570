define([
    'knockout',
    'momentwithlocales',
    'cleave.js',
    // not used:
    'daterangepicker'
], function (ko, moment, Cleave) {
    'use strict';

    ko.bindingHandlers.dateRangePicker = {
        init: function (element, valueAccessor, allBindings) {
            var bindings = allBindings();
            var momentLocale, dateRangePickerLocale;

            switch (bindings.locale) {
                case 'ru':
                    momentLocale = 'ru';
                    dateRangePickerLocale = {cancelLabel: 'Отмена', applyLabel: 'Применить'};
                    break;
                default:
                    momentLocale = 'en';
                    dateRangePickerLocale = {cancelLabel: 'Cancel', applyLabel: 'Apply'};
                    break;
            }

            moment.locale(momentLocale);

            Object.assign(dateRangePickerLocale, {format: bindings.format});

            var value = ko.unwrap(valueAccessor());

            var dateRangePickerConfig = {
                startDate: value.startDate(),
                endDate: value.endDate(),
                locale: dateRangePickerLocale,
                cancelClass: ""
            };

            if (bindings.timePicker) {
                Object.assign(dateRangePickerConfig, {timePicker: true, timePicker24Hour: true});
            }

            var $picker = $(element).on('apply.daterangepicker', function (ev, picker) {
                const format = 'YYYY MM DD';
                if (picker.startDate.format(format) === picker.endDate.format(format)){
                    picker.endDate  = picker.endDate.endOf('day');
                } else {
                    picker.endDate  = picker.endDate.startOf('day');
                }
                value.startDate(picker.startDate);
                value.endDate(picker.endDate);
            }).daterangepicker(dateRangePickerConfig);

            if (bindings.customContainerClass) {
                $picker.data('daterangepicker').container.addClass(bindings.customContainerClass);
            }

            var updateDate = function (newValue, isStart) {
                var dataDateRangePicker = $picker.data('daterangepicker');
                if (dataDateRangePicker !== undefined) {
                    isStart ? dataDateRangePicker.setStartDate(newValue) : dataDateRangePicker.setEndDate(newValue);
                }
            };

            value.startDate.subscribe(function (newValue) {
                updateDate(newValue, true);
            });

            value.endDate.subscribe(function (newValue) {
                updateDate(newValue, false);
            });

            let separator = $picker.data('daterangepicker').locale.separator;
            let dateFormat = $picker.data('daterangepicker').locale.format;
            let format = dateFormat + separator + dateFormat;
            new Cleave(element, {
                blocks: format.match(/[a-zA-Z]+/g).map(function (item) {
                    return item.length;
                }),
                delimiters: format.match(/[^a-zA-Z]+/g),
                numericOnly: true
            });
        }
    };
});
