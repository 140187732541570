define(['vue'], function (Vue) {
    var handler = function (el, binding) {
        const elTextNode = el.firstChild;

        if (el.dataset.key === undefined) {
            el.dataset.key = elTextNode.textContent.trim();
        }

        var mutationObserverConfig = {characterData: true, subtree: true};
        el.observer = new MutationObserver(function () {
            el.dataset.key = elTextNode.textContent.trim();
            localeChangesSubscriber();
        });
        el.observer.observe(el, mutationObserverConfig);

        var localeChangesAction;
        if (binding.value === undefined) {
            localeChangesAction = function () {
                elTextNode.textContent = T(el.dataset.key);
            };
        } else {
            localeChangesAction = function () {
                elTextNode.textContent = T({key: el.dataset.key, params: binding.value});
            };
        }

        var localeChangesSubscriber = function () {
            el.observer.disconnect();
            localeChangesAction();
            el.observer.observe(el, mutationObserverConfig);
        };

        localeChangesSubscriber();
        el.subscription = window.globalState.locale.subscribe(localeChangesSubscriber);
    };

    Vue.directive('translate', {
        bind: handler,
        update: function (el, binding) {
            el.observer.disconnect();
            el.subscription.dispose();
            handler(el, binding);
        }
    });
});
