define([], function () {
    'use strict';

    return {
        HouseManagement: {
            en: 'Object Management',
            ru: 'Управление объектами',
        },
        HouseSettings: {
            en: 'Settings of object',
            ru: 'Настройки объекта',
        },
        HouseName: {
            en: 'Object Name',
            ru: 'Название объекта',
        },
        houseNotFound: {
            en: 'Object with id="#id" is not found',
            ru: 'Объект с id="#id" не найден',
        },
        AddNewHouseVariable: {
            en: 'Add new object variable',
            ru: 'Добавить новую переменную',
        },
        HouseAddress: {
            en: 'Object address',
            ru: 'Адрес объекта',
        },
        enterHouseName: {
            en: 'please, enter the name of object',
            ru: 'введите название объекта',
        },
        enterAddress: {
            en: 'please, enter the address of object',
            ru: 'введите адрес объекта',
        },
        emptyAddress: {
            en: 'The input line is empty. To perform the operation, you must enter the address of the object.',
            ru: 'Строка ввода пуста. Для выполнения операции необходимо ввести адрес объекта.',
        },
        SET_HOUSE_VARIABLEScriptType: {
            en: 'Object variable',
            ru: 'Переменная объекта',
        },
        HOUSE_VARIABLERuleType: {
            en: 'If object mode',
            ru: 'Если режим объекта',
        },
        NoEventsBecauseNoHouses: {
            en: 'You do not have any events, because there is no objects',
            ru: 'У Вас нет ни одного события, т.к. нет ни одного объекта',
        },
        HouseEvents: {
            en: 'Object events',
            ru: 'События объекта',
        },
        categoryHOUSE: {
            en: 'object',
            ru: 'объект',
        },
        SaveAndCreateHouse: {
            en: 'Save And Create a New Object',
            ru: 'Сохранить и перейти к добавлению объекта',
        },
        CreateHouse: {
            en: 'Create a New Object',
            ru: 'Создать новый объект',
        },
        Houses: {
            en: 'List of objects',
            ru: 'Список объектов',
        },
        ALL_HOUSES: {
            en: 'All objects',
            ru: 'Все объекты',
        },
        NoHouses: {
            en: 'No objects',
            ru: 'Нет ни одного объекта',
        },
        AddNewHouse: {
            en: 'Add new object',
            ru: 'Добавить объект',
        },
        AddNewHouseError: {
            en: 'Add new object error',
            ru: 'Произошла ошибка при добавлении объекта',
        },
        HouseNameEmptyError: {
            en: 'The object name can not be empty!',
            ru: 'Название объекта не может быть пустым!',
        },
        NoScriptsBecauseNoHouses: {
            en: 'You do not have any scripts, because there is no objects',
            ru: 'У Вас нет ни одного сценария, т.к. нет ни одного объекта',
        },
        NoMeasuresBecauseNoHouses: {
            en: 'You do not have any measures, because there is no objects',
            ru: 'У Вас нет текущих показаний, т.к. нет ни одного объекта',
        },
        NoDevicesBecauseNoHouses: {
            en: 'No devices yet. To connect devices, add object.',
            ru: 'Устройств пока нет. Для подключения устройств добавьте объект.',
        },
        ConfirmRemoveHouse: {
            en: 'Do you really want to remove this object?',
            ru: 'Вы действительно хотите удалить этот объект?',
        },
        RemoveHouseError: {
            en: 'Remove object error',
            ru: 'Произошла ошибка при удалении объекта',
        },
        MyHouse: {
            en: 'My object',
            ru: 'Мой объект',
        },
        House: {
            en: 'Object',
            ru: 'Объект',
        },
        GoToHouse: {
            en: 'Go to object',
            ru: 'Перейти к объекту',
        },
        HouseError: {
            en: 'Error of getting object info',
            ru: 'Ошибка получения данных объекта',
        },
        ConfirmUnlinkHouse: {
            en: 'Do you really want to unlink this object?',
            ru: 'Вы действительно хотите отвязать этот объект?',
        },
        UnlinkHouseError: {
            en: 'Unlink object error',
            ru: 'Произошла ошибка при отвязывании объекта',
        },
        RemoveHouse: {
            en: 'Remove object',
            ru: 'Удалить объект',
        },
        UnlinkHouse: {
            en: 'Unlink object',
            ru: 'Отвязать объект',
        },
        GetUserAccessError: {
            en: 'Get user access error!',
            ru: 'Ошибка получения списка доступных объектов и уровеней доступа к ним для пользователя!',
        },
        ChooseHouse: {
            en: 'Choose object...',
            ru: 'Объект...',
        },
        CreateHouseDifferentOwner: {
            en: 'No rights to create object for this user',
            ru: 'Нет прав для создания объекта для этого пользователя',
        },
        ScriptImportErrorNoVariables: {
            en: 'There is no suitable object variable for the imported script!',
            ru: 'Для импортируемого сценария не найдено подходящей переменной объекта!',
        },
        SOURCE_TYPE_HOUSE_VARIABLE: {
            en: 'object variable',
            ru: 'переменная объекта',
        },
        HOUSE_VARIABLE_houseMode: {
            en: 'object mode',
            ru: 'режим объекта',
        },
        ACTION_SET_HOUSE_VARIABLE: {
            en: 'set object variable',
            ru: 'изменение переменной объекта',
        },
        NoReportsBecauseNoHouses: {
            en: 'You do not have any reports, because there is no objects',
            ru: 'У Вас нет ни одного отчета, т.к. нет ни одного объекта',
        },
        AddHouseVariableError: {
            en: 'Error object variable creating!',
            ru: 'Ошибка создания переменной объекта!',
        },
        UpdateHouseVariableError: {
            en: 'Error object variable updating!',
            ru: 'Ошибка обновления переменной объекта!',
        },
        HouseVariables: {
            en: 'Object variables',
            ru: 'Переменные объекта',
        },
        RemoveHouseVariableError: {
            ru: 'Ошибка удаления переменной объекта!',
            en: 'Error removing object variable!',
        },
        ChangeHouseVariableTo: {
            en: 'Change value of the object variable «#variable» to',
            ru: 'Изменить значение переменной объекта «#variable» на',
        },
        HousesNeedRefresh: {
            en: 'Objects need refresh',
            ru: 'В следующих объектах произошли изменения',
        },
        NoApartmentsInSelectedHouse: {
            en: 'No apartments in selected object',
            ru: 'В выбранном объекте квартиры не заданы'
        },
        FindHouse: {
            en: 'Find object',
            ru: 'Найти объект',
        },
        ToHouse: {
            en: 'to object',
            ru: 'к объекту',
        },
        NoHouseVariables: {
            en: 'No object variables',
            ru: 'У объекта нет переменных',
        }
    };
});
