define([
    'pathjs',
    'core/utils'
], function (Path, Utils) {
    'use strict';

    function Routing(params) {
        this.defaultPage = params.defaultPage;
        this.layout = params.layout;
    }

    Routing.prototype.decodeUrlParams = function (urlParamsString) {
        if(urlParamsString == undefined || urlParamsString == null || urlParamsString == '')
            return;

        var urlParams = {};
        urlParamsString.split('&').forEach(function(paramStr) {
            var param = paramStr.split('=');
            urlParams[param[0]] = decodeURIComponent(param[1]);
        });
        return urlParams;
    };

    Routing.prototype.startListen = function () {
        var routing = this;
        Path.rescue(function(){
            var path = this.current;
            var pathWithParam = path;
            var urlParamsString = path.match(/\/\?.*$/);
            if(urlParamsString) {
                urlParamsString = urlParamsString[0].replace(/^\/\?/, '');
                path = path.replace(/\/\?.*$/, '');
                pathWithParam = path + '/?(:p)';
            }

            if(path == '' || path == '#') {
                path = routing.defaultPage;
                window.location = routing.defaultPage;
                return;
            }

            const pathWithoutHash = path.substring(1);
            if (Utils.isNgLink(pathWithoutHash)) {
                window.location = pathWithoutHash;
                return;
            }

            routing.layout.to(path, routing.decodeUrlParams(urlParamsString));

            Path.map(pathWithParam).to(function() {
                var urlParamsString = this.params['p'];
                routing.layout.to(path, routing.decodeUrlParams(urlParamsString));
            });
        });

        Path.root(this.defaultPage);

        Path.map('').to(function() {
            window.location = this.defaultPage;
        }.bind(this));

        Path.listen();
    };

    return {
        init: function (params) {
            var routing = new Routing(params);
            routing.startListen();
            return routing;
        }
    };
});
