import firebase from 'firebase/app';
import 'firebase/messaging';
import Ajv from 'ajv';

import localStorageService from '../local-storage-service';
import config from '../config';
import PushWrapper from '../../layout/push-wrapper/init';

const ajv = new Ajv({allErrors: true});

// схема дублируется в firebase-messaging-sw.js
const pushSchema = {
    type: 'object',
    properties: {
        data: {
            type: 'object',
            properties: {
                web_title: {type: 'string'},
                web_body: {type: 'string'},
                web_image: {type: 'string'},
            },
            required: ['web_title', 'web_body'],
            additionalProperties: true
        },
    },
    required: ['data'],
    additionalProperties: true
};

// конфигурация дублируется в firebase-messaging-sw.js
export const firebaseConfig = {
    apiKey: 'AIzaSyCJoB9ulU1N45C2TLOM0fhy2JtOOLn3NIY',
    authDomain: 'smarthome-3e2a6.firebaseapp.com',
    databaseURL: 'https://smarthome-3e2a6.firebaseio.com',
    projectId: 'smarthome-3e2a6',
    storageBucket: 'smarthome-3e2a6.appspot.com',
    messagingSenderId: '720080971428',
    appId: '1:720080971428:web:f9a6526cb0c20f8ee2e831',
    measurementId: 'G-33D7GF9T5Y'
};

class PushService {

    isPushEnabled = false;
    messaging = null;

    constructor() {
        this.isPushEnabled = localStorageService.isPushEnabled();
    }

    init() {
        if (this.isPushEnabled) {
            firebase.initializeApp(firebaseConfig);
            this.messaging = firebase.messaging();

            this.addFirebaseToken();

            // Этот колбэк выполнится, если вкладка в фокусе. Иначе будет нативное браузерное уведомление
            this.messaging.onMessage(payload => {
                if (ajv.validate(pushSchema, payload)) {
                    console.debug('Получен push', payload);
                    PushWrapper.show(payload);
                } else {
                    console.warn('Получен push неверного формата', payload);
                }
            });

            this.messaging.onTokenRefresh(() => this.addFirebaseToken());
        }
    }

    addFirebaseToken() {
        this.messaging.getToken()
            .then(token => {
                config.addFirebaseToken(token)
                    .next(() => {
                        localStorageService.setFirebaseToken(token);
                        localStorageService.setFirebaseTokenRegApi(true);
                        localStorageService.setFirebaseTokenRegFirebase(true);
                        console.debug(`Firebase-token ${token} успешно зарегистрирован`);
                    })
                    .error(e => console.error(e));
            })
            .catch(e => console.error('Ошибка получения firebase-токена', e));
    }

    cleanUpAsync() {
        const token = localStorageService.getFirebaseToken();
        if (token == null) {
            return Promise.resolve();
        }
        return this.revokeToken(token)
            .then(([firebaseRevokeStatus, apiRevokeStatus]) => {
                if (firebaseRevokeStatus && apiRevokeStatus) {
                    localStorageService.setFirebaseToken(null);
                }
            });
    }

    revokeToken(token) {
        if (this.messaging == null) {
            return;
        }

        const firebaseRevoke = this.messaging.deleteToken(token)
            .then(() => {
                localStorageService.setFirebaseTokenRegFirebase(false);
                return true;
            })
            .catch(e => {
                console.error('Ошибка отозвания Firebase токена у Google API', e);
                return false;
            });

        const apiRevoke = new Promise(resolve => {
            config.deleteFirebaseToken(token)
                .next(() => {
                    localStorageService.setFirebaseTokenRegApi(false);
                    resolve(true);
                })
                .error(e => {
                    console.error('Ошибка отозвания Firebase токена у API', e);
                    resolve(false);
                });
        });

        return Promise.all([firebaseRevoke, apiRevoke]);
    }

}

export default new PushService();
