require ('vue-components/v-alert-bottom/init');
require ('vue-components/v-card/init');
require ('vue-components/v-date-picker/init');
require ('vue-components/v-date-range-picker/init');
require ('vue-components/v-emails-input/init');
require ('vue-components/v-house-variable-editor/init');
require ('vue-components/v-house-variable-editor-extended/init');
require ('vue-components/v-license-alert/init');
require ('vue-components/v-modal/init');
require ('vue-components/v-multi-input/init');
require ('vue-components/v-not-available/init');
require ('vue-components/v-stepper/init');
require ('vue-components/v-widget-monitoring-meter/init');
require ('vue-components/v-add-device-feedback/init');
require ('vue-components/v-splice-history-modal/init');
require ('vue-components/v-splice-history-for-edit-device-modal/init');
