/**
 * Unique ID attribute
 *
 * Snipped from: https://stackoverflow.com/a/15013922
 */

define(['knockout'], function(ko) {
    'use strict';

    ko.bindingHandlers.koFocus = {
        update: function (element, valueAccessor) {
            var value = valueAccessor();
            var $element = $(element);
            if (value()) {
                $element.focus();
            }
        }
    };
});
