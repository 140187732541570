define(() => {
    let localLanguage = localStorage.getItem('locale');

    if (localLanguage === null) {
        localLanguage = navigator.language || navigator.userLanguage;
    }

    let language = localLanguage.toLowerCase();

    if (language.startsWith('ru')) {
        language = 'ru';
    } else {
        language = 'en';
    }

    localStorage.setItem('locale', language);
});
