define([], function () {

    const SCRIPT_STATUSES = Object.freeze({
        RUNNING: 'RUNNING',
        READY: 'READY'
    });

    return {
        SCRIPT_STATUSES,
    };
});
