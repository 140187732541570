import Vue from 'vue';

import template from 'text!./template.html';
import {menuItemsMap} from '../../core/menu-items-map';
import config from '../../core/config';
import Utils from '../../core/utils';
import User from '../../core/user';
import {PageId} from '../../core/page-id';

class Sidenav {

    items;
    footer = {
        year: new Date().getFullYear(),
        coreVersion: '',
        webVersion: localStorage.getItem('webVersion')
    };

    constructor(params) {
        this.$container = params.$container;
        this.template = template;

        let pageIdArray = User.pageIdArray;
        const camerasAvailable = User.cameraPlatforms.length > 0;

        if (!camerasAvailable) {
            pageIdArray = pageIdArray.filter(pageId => pageId !== PageId.Cameras);
        }

        this.items = pageIdArray.map(pageId => {
            const item = {...menuItemsMap[pageId]};
            item.active = false;
            if (!item.link.startsWith('/')) {
                item.link = Utils.getNavigationLink(item.link);
            }
            return item;
        });

        config.getVersion().next(json => this.footer.coreVersion = json.version.split(' ')[0]);
    }

    alive() {
        const vm = new Vue({
            template: this.template,
            data: {
                items: this.items,
                footer: this.footer,
            }
        });
        vm.$mount(this.$container);
        this.$container.appendChild(vm.$el);
    }

    isThatPath(link, path) {
        if (link.startsWith('#')) {
            link = link.substring(1);
        }
        path = path.replace(/\/.*$/, '').replace(/^#/, '');
        return link === path;
    }

    selectMenu(itemPath) {
        this.items.forEach(item => item.active = this.isThatPath(item.link, itemPath));
    }

}

export default {
    init(params) {
        const sidenav = new Sidenav(params);
        sidenav.alive();
        return sidenav;
    }
};
