import Vue from 'vue';

import template from 'text!./template.html';
import Push from './push/init';
import Utils from '../../core/utils';

function getCurrentTime() {
    const pad = number => Utils.anyToString(number).padStart(2, '0');
    const now = new Date();
    return pad(now.getHours()) + ':' + pad(now.getMinutes());
}

class PushWrapper {

    template = template;
    pushes = [];

    init(params) {
        this.$container = params.$container;
        this.alive();
    }

    alive() {
        const vm = new Vue({
            template: this.template,
            data: {
                pushes: this.pushes
            },
            methods: {
                deletePush: this.deletePush
            },
            components: {
                Push
            }
        });
        vm.$mount(this.$container);
        this.$container.appendChild(vm.$el);
    }

    show(push) {
        this.pushes.push({...push, id: Utils.generateRandomId(), time: getCurrentTime()});
    }

    deletePush(index) {
        this.pushes.splice(index, 1);
    }

}

export default new PushWrapper();
