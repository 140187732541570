define([
    'knockout',
], function (ko) {
    'use strict';

    ko.extenders.truncateValue = function (target, option) {
        target.subscribe(function (newValue) {
            if (newValue.length > option) {
                target(newValue.substring(0, option));
            }
        });

        return target;
    };
});
