define([
    'vue',
    'text!./template.html',
    'momentwithlocales'
], function (Vue, template, moment) {

    const PICKER_FORMAT = 'YYYY-MM-DD';

    Vue.component('v-date-picker', {
        template: template,
        props: {
            value: String,
            format: String,
            id: String,
            label: String,
            errorMessages: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        data: function () {
            return {
                pickerValue: moment(this.value, this.format).format(PICKER_FORMAT)
            };
        },
        watch: {
            'pickerValue': function (newPickerValue) {
                const momentInstance = moment(newPickerValue, PICKER_FORMAT);
                const newValue = momentInstance.isValid() ? momentInstance.format(this.format) : null;
                this.$emit('input', newValue);
            }
        }
    });

});
