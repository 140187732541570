export const Permission = Object.freeze({
    needHeaderVariablesEditor: 'needHeaderVariablesEditor',
    needHouseUsers: 'needHouseUsers',
    needHouseLimits: 'needHouseLimits',
    needHouseEvents: 'needHouseEvents',
    needHouseTypes: 'needHouseTypes',
    needHousePage: 'needHousePage',
    needNewHousePage: 'needNewHousePage',
    needHouseControllersPage: 'needHouseControllersPage',
    needHouseVariablesPage: 'needHouseVariablesPage',
    needHouseGroupsPage: 'needHouseGroupsPage',
    needDevicePage: 'needDevicePage',
    needReportByAddresses: 'needReportByAddresses',
    needAllMeasures: 'needAllMeasures',
    needDeviceAnalytics: 'needDeviceAnalytics',
    canDeleteZWayDevicesForcibly: 'canDeleteZWayDevicesForcibly',
    cannotEditDevice: 'cannotEditDevice',
    computeDefaultPage: 'computeDefaultPage',
    needPersonalInformation: 'needPersonalInformation',
    admin: 'admin',
    device_r: 'device_r',
    device_w: 'device_w',
    controller_r: 'controller_r',
    controller_w: 'controller_w',
    house_r: 'house_r',
    house_w: 'house_w',
    camera_r: 'camera_r',
    camera_w: 'camera_w',
    script_r: 'script_r',
    script_w: 'script_w'
});
