/**
 * Trimmed observables
 *
 * Snipped from: https://gist.github.com/joeyespo/4041403
 */

define(['knockout'], function (ko) {
    'use strict';

    ko.subscribable.fn.trimmed = function () {
        return ko.computed({
            read: function () {
                return this().trim();
            },
            write: function (value) {
                this(value.trim());
                this.valueHasMutated();
            },
            owner: this
        });
    };
});
