define([
    'deep-freeze',
    'knockout',
    'core/controller-types'
], function (deepFreeze, ko, ControllerTypes) {

    const DEVICE_TYPES = deepFreeze({
        Z_WAY: {
            Z_WAY_DEVICE: 'Z_WAY_DEVICE'
        },
        ETHERNET_COM: {
            MERCURY_230: 'MERCURY230',
            MERCURY_206: 'MERCURY206',
            TSRV_034: 'TSRV034',
            TSRV_043: 'TSRV043',
            TSRV_026M: 'TSRV026M',
            PULSAR: 'PULSAR',
            LOGIKA_941_10: 'LOGIKA941_10',
            LOGIKA_941_20: 'LOGIKA941_20',
            LOGIKA_942: 'LOGIKA942',
            LOGIKA_943: 'LOGIKA943',
            LOGIKA_944: 'LOGIKA944',
            LOGIKA_761: 'LOGIKA761',
            TW7: 'TW7',
            SANEXT_MONO_RS485: 'SANEXT_MONO_RS485',
            VKT7: 'VKT7',
            ENERGOMERA_CE102M_R5: 'CE102M_RS485',
            ASWEGA_SA943A: 'ASWEGA_SA943A'
        },
        ETHERNET_M_BUS: {
            SANEXT_MONO_MBUS: 'SANEXT_MONO_MBUS',
            PULSAR_MBUS: 'PULSAR_MBUS'
        },
        VIRTUAL_HTTP: {}
    });

    const ETHERNET_COM_DEVICES = [
        DEVICE_TYPES.ETHERNET_COM.MERCURY_230,
        DEVICE_TYPES.ETHERNET_COM.MERCURY_206,
        DEVICE_TYPES.ETHERNET_COM.TSRV_034,
        DEVICE_TYPES.ETHERNET_COM.TSRV_043,
        DEVICE_TYPES.ETHERNET_COM.TSRV_026M,
        DEVICE_TYPES.ETHERNET_COM.PULSAR,
        DEVICE_TYPES.ETHERNET_COM.LOGIKA_941_10,
        DEVICE_TYPES.ETHERNET_COM.LOGIKA_941_20,
        DEVICE_TYPES.ETHERNET_COM.LOGIKA_942,
        DEVICE_TYPES.ETHERNET_COM.LOGIKA_943,
        DEVICE_TYPES.ETHERNET_COM.LOGIKA_944,
        DEVICE_TYPES.ETHERNET_COM.LOGIKA_761,
        DEVICE_TYPES.ETHERNET_COM.SANEXT_MONO_RS485,
        DEVICE_TYPES.ETHERNET_COM.TW7,
        DEVICE_TYPES.ETHERNET_COM.VKT7,
        DEVICE_TYPES.ETHERNET_COM.ENERGOMERA_CE102M_R5,
        DEVICE_TYPES.ETHERNET_COM.ASWEGA_SA943A
    ];

    const DEVICE_TYPES_BULK_ADD = deepFreeze({
        [ControllerTypes.ETHERNET_COM]: ETHERNET_COM_DEVICES,
        [ControllerTypes.ETHERNET_COM_TCP_CLIENT]: ETHERNET_COM_DEVICES,
        [ControllerTypes.ETHERNET_M_BUS]: [
            DEVICE_TYPES.ETHERNET_M_BUS.SANEXT_MONO_MBUS,
            DEVICE_TYPES.ETHERNET_M_BUS.PULSAR_MBUS
        ]
    });

    /**
     * Надо синхронизировать с IoT-web-mc.
     * CustomZWayDeviceType (src/app/shared/data/devices-info.ts)
     */
    const Z_WAY_DEVICE_TYPES = Object.freeze({
        METER_COMPLEX: 'METER_COMPLEX',
        WATER_METER_COMPLEX: 'WATER_METER_COMPLEX',
        SOCKET: 'SOCKET',
        DIMMER: 'DIMMER',
        DOOR_AND_MOTION: 'DOOR_AND_MOTION',
        OPEN_CLOSE: 'OPEN_CLOSE',
        MOTION: 'MOTION',
        GLASS_BREAKAGE: 'GLASS_BREAKAGE',
        LEAK: 'LEAK',
        SMOKE: 'SMOKE',
        GAS: 'GAS',
        VOLTAGE_METER: 'VOLTAGE_METER',
        RELAY: 'RELAY',
        RGB_LAMP: 'RGB_LAMP',
        SWITCH_MULTI_LEVEL: 'SWITCH_MULTI_LEVEL',
        THERMOSTAT: 'THERMOSTAT',
        PAR01: 'PAR01',
        SZ_AIR_T01: 'SZ_AIR_T01',
        SZ_AIR_HT01: 'SZ_AIR_HT01',
        SZ_AIR_Q01: 'SZ_AIR_Q01',
        SZ_AIR_HTQ01: 'SZ_AIR_HTQ01',
        ALARM_BUTTON: "ALARM_BUTTON",
        SZ_P01S: "SZ_P01S"
    });

    const Z_WAY_PROPERTIES = Object.freeze({
        METER_COMPLEX: 'METER_COMPLEX',
        WATER_METER_COMPLEX: 'WATER_METER_COMPLEX',
        ON_OFF: 'ON_OFF',
        VOLTAGE: 'VOLTAGE',
        SWITCH_MULTI_LEVEL: 'SWITCH_MULTI_LEVEL',
        OPEN_CLOSE: 'OPEN_CLOSE',
        MOTION: 'MOTION',
        GLASS_BREAKAGE: 'GLASS_BREAKAGE',
        LEAK: 'LEAK',
        SMOKE: 'SMOKE',
        GAS: 'GAS',
        CONSUME_ENERGY: 'CONSUME_ENERGY',
        POWER: 'POWER',
        AMPERAGE: 'AMPERAGE',
        POWER_FACTOR: 'POWER_FACTOR',
        RGB_LAMP: 'RGB_LAMP',
        LAMP_WARM_WHITE: 'LAMP_WARM_WHITE',
        LAMP_COLD_WHITE: 'LAMP_COLD_WHITE',
        THERMOSTAT_SET_POINT: 'THERMOSTAT_SET_POINT',
        THERMOSTAT_MODE: 'THERMOSTAT_MODE',
        WAKEUP_INTERVAL: 'WAKEUP_INTERVAL',
        REMOTE_LEARNING: 'REMOTE_LEARNING',
        LEARNING_STATUS: 'LEARNING_STATUS',
        CODE_MODEL: 'CODE_MODEL',
        TEMPERATURE_DIFF: 'TEMPERATURE_DIFF',
        REPORT_INTERVAL: 'REPORT_INTERVAL',
        TEMPERATURE: 'TEMPERATURE',
        BATTERY: 'BATTERY',
        LUMINOSITY: 'LUMINOSITY',
        HUMIDITY: 'HUMIDITY',
        COOLING: 'COOLING',
        HEAT: 'HEAT',
        CO2: 'CO2',
        CO2_ALARM: 'CO2_ALARM',
        VOC: 'VOC',
        VOC_LEVEL: 'VOC_LEVEL',
        EMERGENCY: 'EMERGENCY',
        SERIAL_DATA: 'SERIAL_DATA'
    });

    const Z_WAY_FLIRS_PROPERTIES = Object.freeze({
        GUARD_MODE: 'GUARD_MODE',
        GUARD_STATUS: 'GUARD_STATUS',
        KEEP_ALIVE: 'KEEP_ALIVE',
        ZWAY_CONFIGURATION: 'ZWAY_CONFIGURATION'
    });

    /**
     * Правила определяющие тип устройства по ключевым видам свойств.
     * Правила необходимо отсортировывать по убыванию количества ключевых видов свойств.
     * isComplex - устройство, требующее двух статусов, иконок и пр.
     * В keyProps первое свойство отвечает за цвет иконки, в случае isComplex === false.
     *
     * Надо синхронизировать с IoT-web-mc.
     * zWayDevicesRules (src/app/shared/data/devices-info.ts)
     */
    const Z_WAY_DEVICES_RULES = deepFreeze([
        {
            type: Z_WAY_DEVICE_TYPES.PAR01,
            keyProps: [
                Z_WAY_PROPERTIES.TEMPERATURE_DIFF,
                Z_WAY_PROPERTIES.REMOTE_LEARNING,
                Z_WAY_PROPERTIES.LEARNING_STATUS,
                Z_WAY_PROPERTIES.CODE_MODEL,
                Z_WAY_PROPERTIES.REPORT_INTERVAL
            ],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.SZ_AIR_HTQ01,
            keyProps: [Z_WAY_PROPERTIES.CO2, Z_WAY_PROPERTIES.VOC, Z_WAY_PROPERTIES.HEAT, Z_WAY_PROPERTIES.HUMIDITY],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.SZ_AIR_Q01,
            keyProps: [Z_WAY_PROPERTIES.CO2, Z_WAY_PROPERTIES.VOC, Z_WAY_PROPERTIES.HEAT],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.SZ_AIR_HT01,
            keyProps: [Z_WAY_PROPERTIES.HEAT, Z_WAY_PROPERTIES.TEMPERATURE, Z_WAY_PROPERTIES.HUMIDITY],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.SZ_AIR_T01,
            keyProps: [Z_WAY_PROPERTIES.HEAT, Z_WAY_PROPERTIES.TEMPERATURE],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.SOCKET,
            keyProps: [Z_WAY_PROPERTIES.ON_OFF, Z_WAY_PROPERTIES.VOLTAGE],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.DIMMER,
            keyProps: [Z_WAY_PROPERTIES.ON_OFF, Z_WAY_PROPERTIES.SWITCH_MULTI_LEVEL],
            isComplex: true
        },
        {
            type: Z_WAY_DEVICE_TYPES.DOOR_AND_MOTION,
            keyProps: [Z_WAY_PROPERTIES.OPEN_CLOSE, Z_WAY_PROPERTIES.MOTION],
            isComplex: true
        },
        {
            type: Z_WAY_DEVICE_TYPES.RGB_LAMP,
            keyProps: [Z_WAY_PROPERTIES.SWITCH_MULTI_LEVEL, Z_WAY_PROPERTIES.RGB_LAMP],
            isComplex: true
        },
        {
            type: Z_WAY_DEVICE_TYPES.THERMOSTAT,
            keyProps: [Z_WAY_PROPERTIES.THERMOSTAT_MODE, Z_WAY_PROPERTIES.THERMOSTAT_SET_POINT],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.SWITCH_MULTI_LEVEL,
            keyProps: [Z_WAY_PROPERTIES.SWITCH_MULTI_LEVEL],
            isComplex: true
        },
        {
            type: Z_WAY_DEVICE_TYPES.METER_COMPLEX,
            keyProps: [Z_WAY_PROPERTIES.METER_COMPLEX],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.WATER_METER_COMPLEX,
            keyProps: [Z_WAY_PROPERTIES.WATER_METER_COMPLEX],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.LEAK,
            keyProps: [Z_WAY_PROPERTIES.LEAK],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.SMOKE,
            keyProps: [Z_WAY_PROPERTIES.SMOKE],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.GAS,
            keyProps: [Z_WAY_PROPERTIES.GAS],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.MOTION,
            keyProps: [Z_WAY_PROPERTIES.MOTION],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.GLASS_BREAKAGE,
            keyProps: [Z_WAY_PROPERTIES.GLASS_BREAKAGE],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.OPEN_CLOSE,
            keyProps: [Z_WAY_PROPERTIES.OPEN_CLOSE],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.VOLTAGE_METER,
            keyProps: [Z_WAY_PROPERTIES.VOLTAGE],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.RELAY,
            keyProps: [Z_WAY_PROPERTIES.ON_OFF],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.ALARM_BUTTON,
            keyProps: [Z_WAY_PROPERTIES.EMERGENCY],
            isComplex: false
        },
        {
            type: Z_WAY_DEVICE_TYPES.SZ_P01S,
            keyProps: [Z_WAY_PROPERTIES.SERIAL_DATA],
            isComplex: false
        }
    ]);

    /**
     * Надо синхронизировать с IoT-web-mc.
     * zWayDeviceIcons (src/app/shared/data/devices-info.ts)
     */
    const Z_WAY_ICONS = Object.freeze({
        [Z_WAY_DEVICE_TYPES.SOCKET]: 'iot-smart-socket',
        [Z_WAY_DEVICE_TYPES.DIMMER]: 'iot-dimmer',
        [Z_WAY_DEVICE_TYPES.SWITCH_MULTI_LEVEL]: 'iot-switch-multi-level',
        [Z_WAY_DEVICE_TYPES.DOOR_AND_MOTION]: {
            [Z_WAY_PROPERTIES.OPEN_CLOSE]: 'iot-open-half-left',
            [Z_WAY_PROPERTIES.MOTION]: 'iot-motion-half-right'
        },
        [Z_WAY_DEVICE_TYPES.METER_COMPLEX]: 'iot-energy-meter',
        [Z_WAY_DEVICE_TYPES.WATER_METER_COMPLEX]: 'iot-water-meter',
        [Z_WAY_DEVICE_TYPES.LEAK]: 'iot-leak',
        [Z_WAY_DEVICE_TYPES.SMOKE]: 'iot-smoke',
        [Z_WAY_DEVICE_TYPES.GAS]: 'iot-gas',
        [Z_WAY_DEVICE_TYPES.MOTION]: 'iot-motion',
        [Z_WAY_DEVICE_TYPES.GLASS_BREAKAGE]: 'iot-glass-breakage',
        [Z_WAY_DEVICE_TYPES.OPEN_CLOSE]: 'iot-open',
        [Z_WAY_DEVICE_TYPES.VOLTAGE_METER]: 'iot-energy',
        [Z_WAY_DEVICE_TYPES.RELAY]: 'iot-relay',
        [Z_WAY_DEVICE_TYPES.RGB_LAMP]: 'iot-luminosity',
        [Z_WAY_DEVICE_TYPES.THERMOSTAT]: 'iot-thermostat',
        [Z_WAY_DEVICE_TYPES.PAR01]: 'iot-remote-control',
        [Z_WAY_DEVICE_TYPES.SZ_AIR_T01]: 'iot-thermostat',
        [Z_WAY_DEVICE_TYPES.SZ_AIR_HT01]: 'iot-thermostat-humidity',
        [Z_WAY_DEVICE_TYPES.SZ_AIR_Q01]: 'iot-co2-leaf',
        [Z_WAY_DEVICE_TYPES.SZ_AIR_HTQ01]: 'iot-thermostat-leaf-humidity-co2',
        [Z_WAY_DEVICE_TYPES.ALARM_BUTTON]: 'iot-alarm-button',
        [Z_WAY_DEVICE_TYPES.SZ_P01S]: 'iot-hub'
    });

    /**
     * Надо синхронизировать с IoT-web-mc.
     * nonZWayDeviceIcons (src/app/shared/data/devices-info.ts)
     */
    const DEVICE_ICONS = deepFreeze({
        Z_WAY: {
            ...Z_WAY_ICONS
        },

        [DEVICE_TYPES.ETHERNET_COM.MERCURY_230]: 'iot-energy-meter',
        [DEVICE_TYPES.ETHERNET_COM.MERCURY_206]: 'iot-energy-meter',
        [DEVICE_TYPES.ETHERNET_COM.TSRV_034]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.TSRV_043]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.TSRV_026M]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.PULSAR]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.LOGIKA_941_10]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.LOGIKA_941_20]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.LOGIKA_942]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.LOGIKA_943]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.LOGIKA_944]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.LOGIKA_761]: 'iot-gas-meter',
        [DEVICE_TYPES.ETHERNET_M_BUS.SANEXT_MONO_MBUS]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_M_BUS.PULSAR_MBUS]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.TW7]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.SANEXT_MONO_RS485]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.VKT7]: 'iot-warm-meter',
        [DEVICE_TYPES.ETHERNET_COM.ENERGOMERA_CE102M_R5]: 'iot-energy-meter',
        [DEVICE_TYPES.ETHERNET_COM.ASWEGA_SA943A]: 'iot-warm-meter'
    });

    const DEVICE_WITH_PERSONAL_FIELD = [
            DEVICE_TYPES.ETHERNET_COM.MERCURY_230,
            DEVICE_TYPES.ETHERNET_COM.MERCURY_206,
            DEVICE_TYPES.ETHERNET_COM.TSRV_034,
            DEVICE_TYPES.ETHERNET_COM.TSRV_043,
            DEVICE_TYPES.ETHERNET_COM.TSRV_026M,
            DEVICE_TYPES.ETHERNET_COM.LOGIKA_941_10,
            DEVICE_TYPES.ETHERNET_COM.LOGIKA_941_20,
            DEVICE_TYPES.ETHERNET_COM.LOGIKA_942,
            DEVICE_TYPES.ETHERNET_COM.LOGIKA_943,
            DEVICE_TYPES.ETHERNET_COM.LOGIKA_944,
            DEVICE_TYPES.ETHERNET_COM.LOGIKA_761,
            DEVICE_TYPES.ETHERNET_COM.TW7,
            DEVICE_TYPES.ETHERNET_COM.ENERGOMERA_CE102M_R5,
            DEVICE_TYPES.ETHERNET_COM.ASWEGA_SA943A
        ];

    const CUSTOM_CODE_MODEL_VALUE = '0';

    return {
        Z_WAY_DEVICES_RULES,
        DEVICE_TYPES,
        DEVICE_TYPES_BULK_ADD,
        Z_WAY_DEVICE_TYPES,
        Z_WAY_PROPERTIES,
        Z_WAY_ICONS,
        DEVICE_ICONS,
        Z_WAY_FLIRS_PROPERTIES,
        CUSTOM_CODE_MODEL_VALUE,
        DEVICE_WITH_PERSONAL_FIELD,

        getZwayDeviceType(device) {
            let deviceKinds = [];

            for (let key in device.channels) {
                deviceKinds = deviceKinds.concat(device.channels[key].deviceProperties.map(property => property.kind));
            }

            const rule = Z_WAY_DEVICES_RULES.find(rule => rule.keyProps.every(prop => deviceKinds.includes(prop)));

            return rule ? rule.type : 'UNKNOWN';
        },

        getIconTag(icon) {
            return `<i class='iot ${icon}'></i>`;
        },

        getDeviceIcon(device) {
            let deviceIcon = device.deviceType === DEVICE_TYPES.Z_WAY.Z_WAY_DEVICE ?
                DEVICE_ICONS.Z_WAY[this.getZwayDeviceType(device)] :
                DEVICE_ICONS[device.deviceType];

            deviceIcon = deviceIcon !== undefined ? deviceIcon : 'iot-unknown-type';

            return typeof deviceIcon === 'object' ?
                Object.values(deviceIcon).map(icon => this.getIconTag(icon)).join('') :
                this.getIconTag(deviceIcon);
        },

        getPropertyById(koDevice, propertyId) {
            if (!ko.isObservable(koDevice.channels)) {
                for (const channel in koDevice.channels) {
                    if (koDevice.channels.hasOwnProperty(channel)) {
                        const property = koDevice.channels[channel].deviceProperties()
                            .find(property => property.id() === propertyId);
                        if (property) {
                            return property;
                        }
                    }
                }
            }
        },

        isDeviceWithPersonalField(deviceType) {
            return DEVICE_WITH_PERSONAL_FIELD.includes(deviceType);
        },

        isZWayDevice(deviceType) {
            return Object.values(DEVICE_TYPES.Z_WAY).includes(deviceType);
        },

        isEthernetComDevice(deviceType) {
            return Object.values(DEVICE_TYPES.ETHERNET_COM).includes(deviceType);
        },

        isEthernetMBusDevice(deviceType) {
            return Object.values(DEVICE_TYPES.ETHERNET_M_BUS).includes(deviceType);
        },

        getCodeModel(device) {
            for (const channel of Object.values(device.channels)) {
                const property = channel.deviceProperties.find(property => property.kind === Z_WAY_PROPERTIES.CODE_MODEL);
                if (property) {
                    return property.value;
                }
            }
        }

    };
});
