define(['vue'], function (Vue) {
    Vue.directive('translate-text', function (el, binding) {
        const localeChangesSubscriber = function () {
            /**
             * binding.value может быть строкой или
             * принимать объект {key, params},
             * где key - ключ для перевода,
             * params - переменные внути перевода.
             */
            el.innerText = T(binding.value);
        };

        localeChangesSubscriber();
        window.globalState.locale.subscribe(localeChangesSubscriber);
    });
});
