/**
 * Unique ID attribute
 *
 * Snipped from: http://stackoverflow.com/a/9233786
 */

define(['knockout'], function(ko) {
	'use strict';
	ko.bindingHandlers.uniqueId = {
		init : function(element) {
			element.id = ko.bindingHandlers.uniqueId.prefix + (++ko.bindingHandlers.uniqueId.counter);
		},
		counter : 0,
		prefix : "unique"
	};

	ko.bindingHandlers.uniqueFor = {
		init : function(element, valueAccessor) {
			var after = ko.bindingHandlers.uniqueId.counter + (ko.utils.unwrapObservable(valueAccessor()) === "after" ? 0 : 1);
			element.setAttribute("for", ko.bindingHandlers.uniqueId.prefix + after);
		}
	};

	ko.bindingHandlers.dataTarget = {
		init : function(element, valueAccessor) {
			var after = ko.bindingHandlers.uniqueId.counter + (ko.utils.unwrapObservable(valueAccessor()) === "after" ? 0 : 1);
			element.setAttribute("data-target", "#" + ko.bindingHandlers.uniqueId.prefix + after);
		}
	};
});
