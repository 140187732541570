define([
    'knockout',
    'text!./template.html'
], (ko, template) => {

    /**
     * Режимы термостата для ИК модуля.
     * Не удалять закомментированные режимы.
     */
    const ThermostatMode = Object.freeze({
        Off: '0',
        Heat: '1',
        Cool: '2',
        // Auto: '3',
        Resume: '5',
        // Fan: '6',
        // Dry: '8'
    });

    class ViewModel {
        constructor(params) {
            this.modeCopy = params.modeCopy;
            this.heatCopy = params.heatCopy;
            this.coolCopy = params.coolCopy;
            this.heat = params.heat;
            this.cool = params.cool;

            this.valueLimits = {min: 17, max: 30};
            this.thermostatModes = Object.values(ThermostatMode);
            this.ThermostatMode = ThermostatMode;

            this.modeCopySub = this.modeCopy.value.subscribe(newMode => {
                switch (newMode) {
                    case ThermostatMode.Heat:
                        this.resetCool();
                        break;
                    case ThermostatMode.Cool:
                        this.resetHeat();
                        break;
                    default:
                        this.resetAll();
                        break;
                }
            });
        }

        dispose() {
            this.modeCopySub.dispose();
        }

        getThermostatModeRestext(mode) {
            return 'PAR01_THERMOSTAT_MODE_' + mode;
        }

        resetHeat() {
            this.heatCopy.value(this.heat.value());
        }

        resetCool() {
            this.coolCopy.value(this.cool.value());
        }

        resetAll() {
            this.resetHeat();
            this.resetCool();
        }
    }

    ko.components.register('par01-control-list', {viewModel: ViewModel, template});

});
