import Vue from 'vue';

import localStorageService from '../../core/local-storage-service';
import config from '../../core/config';
import template from 'text!./template.html';
import HeaderVariablesEditor from './header-variables-editor/init';
import PushService from '../../core/push/push-service';
import User from '../../core/user';
import {Permission} from '../../core/permission'

class Header {

    user;
    currentLocale = window.globalState.locale();

    constructor(params) {
        this.$container = params.$container;
        this.template = template;

        this.computed = {
            username() {
                const login = window.globalState.login;
                const username = login.username();
                if (!User.hasPermission(Permission.needPersonalInformation)) {
                    return username;
                }
                const name = [login.surname(), login.name(), login.patronymic()].join(' ').trim();
                return name === '' ? username : name;
            }
        };

        config.getUser().next(user => this.user = user);
    }

    logout() {
        config.deleteAccessToken()
            .error(e => console.error(e))
            .next(() => {
                (localStorageService.isPushEnabled() ? PushService.cleanUpAsync() : Promise.resolve())
                    .then(() => {
                        localStorageService.removeAccessToken();
                        localStorageService.removeRefreshToken();
                        location.replace('signin/signin.html');
                    });
            });
    }

    locale(newValue) {
        if (newValue !== localStorage.getItem('locale')) {
            localStorage.setItem('locale', newValue);
            this.currentLocale = newValue;
            window.globalState.locale(newValue);
        }
    }

    alive() {
        const vm = new Vue({
            template: this.template,
            data: {
                user: this.user,
                currentLocale: this.currentLocale,
            },
            methods: {
                logout: this.logout,
                locale: this.locale
            },
            computed: this.computed,
            components: {
                HeaderVariablesEditor
            }
        });
        vm.$mount(this.$container);
        this.$container.appendChild(vm.$el);
    }

}

export default {
    init(params) {
        const header = new Header(params);
        header.alive();
        return header;
    }
}
