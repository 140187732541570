define(['knockout'], function (ko) {
    'use strict';

    const animate = ($targetElement, speed) => {
        const scrollWidth = $targetElement.get(0).scrollWidth;
        const clientWidth = $targetElement.get(0).clientWidth;

        $targetElement.animate({scrollLeft: scrollWidth - clientWidth},
            {
                duration: speed,
                complete: () => {
                    $targetElement.animate({scrollLeft: 0},
                        {
                            duration: speed,
                            complete: () => {
                                animate($targetElement, speed);
                            }
                        }
                    );
                }
            }
        );
    };

    ko.bindingHandlers.autoScrollIfTextOverflow = {
        init: (element, valueAccessor) => {
            const $element = $(element);
            $element.css('white-space', 'nowrap');
            $element.css('overflow', 'hidden');

            const params = ko.unwrap(valueAccessor());
            animate($element, params.speed);
        }
    };
});
