define([
    'vue',
    'text!./template.html'
], function (Vue, template) {

    Vue.component('v-emails-input', {
        template: template,
        props: {
            value: Array,
            id: String,
            label: String
        },
        data: function () {
            return {
                emails: this.value,
                newEmail: ''
            };
        },
        watch: {
            'emails': function (newInputValue) {
                this.$emit('input', newInputValue);
            }
        },
        methods: {
            onSubmitButtonClick() {
                if (!this.newEmail) {
                    return;
                }
                this.emails.push(this.newEmail);
                this.newEmail = '';
                this.$refs.newEmail.focus();
            },
            deleteItem(index) {
                this.emails.splice(index, 1);
            }
        }
    });

});
