define([
    'knockout',
    'text!./template.html',
    '@material/slider'
], function (ko, template, mdcSlider) {

    const ViewModel = {
        createViewModel(params, componentInfo) {

            const sliderEl = componentInfo.element.querySelector('.mdc-slider');
            const slider = new mdcSlider.MDCSlider(sliderEl);

            const updateSlider = (value, min, max) => {
                slider.value = value;
                slider.min = Number(ko.unwrap(min)) || 0;
                slider.max = Number(ko.unwrap(max)) || 99;
            };

            class ViewModel {
                constructor(params) {
                    /* layout() Пересчитывает размеры и заново раскладывает компонент.
                    * timeout 500 мс необходим, т.к. иначе компонент не успевает перестроиться */
                    setTimeout(() => slider.layout(), 500);

                    ko.computed(() => updateSlider(params.value(), params.min, params.max));

                    this.value = params.value;
                    sliderEl.addEventListener('MDCSlider:input', () => {
                        params.value(slider.value.toString());
                    });
                    updateSlider(params.value(), params.min, params.max);
                }
            }

            return new ViewModel(params);
        }
    };

    ko.components.register('slider', {viewModel: ViewModel, template: template});
});
