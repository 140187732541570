define(['knockout'], ko => {
    'use strict';

    ko.bindingHandlers.bootstrapTypeahead = {
        init: function (element, valueAccessor) {
            const $element = $(element);
            const bootstrapTypeaheadOptions = ko.utils.unwrapObservable(valueAccessor());
            $element.typeahead(bootstrapTypeaheadOptions);
        }
    };
});
