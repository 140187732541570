import Vue from 'vue';
import template from 'text!./template.html';
import EventService from 'core/websocket/event-service';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

Vue.component('v-not-available', {
    template,
    data() {
        return {
            online: true // изначально true, чтобы не показывать сообщение до первого подключения
        };
    },
    created() {
        EventService.online$
            .pipe(
                debounceTime(2000), // фильтруем быстрые изменения при реконнекте
                distinctUntilChanged(), // отслеживаем только новые статусы
            )
            .subscribe(status => this.online = status);
    }
});
