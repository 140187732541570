define([
    'vue',
    'core/config',
    'text!./primitive-variable-editor.html',
    'text!./template.html'
], function (Vue, config, primitiveVariableEditorTemplate, template) {
    var PrimitiveVariableEditor = {
        template: primitiveVariableEditorTemplate,
        props: {
            variable: Object,
            queryInProgress: Boolean,
            canChangeMode: Boolean,
            singleVariable: Boolean,
            updateVariable: Function
        },
        data: function () {
            return {
                newValue: this.variable.value
            }
        },
        methods: {
            reset: function () {
                this.newValue = this.variable.value;
            }
        },
        computed: {
            variableValueChanged: function () {
                return this.variable.value != this.newValue;
            },
            inputType: function () {
                return this.variable.type === 'TEXT' ? 'text' : 'number';
            }
        },
        watch: {
            'variable.value': function (newValue) {
                this.newValue = newValue;
            }
        }
    };

    var ENUM_VIEWS = Object.freeze({DROPDOWN: 'DROPDOWN', RADIO: 'RADIO'});

    Vue.component('v-house-variable-editor', {
        template: template,
        props: {
            variable: Object,
            canChangeMode: Boolean,
            singleVariable: Boolean,
            houseId: String,
            enumView: {
                type: String,
                default: ENUM_VIEWS.DROPDOWN,
                validator: function (value) {
                    return value in ENUM_VIEWS;
                }
            }
        },
        data: function () {
            return {
                queryInProgress: false,
                ENUM_VIEWS: ENUM_VIEWS
            }
        },
        computed: {
            variableValueRepresentation: function () {
                var result;
                switch (this.variable.type) {
                    case 'ENUMERATION':
                        var enumValue = this.variable.enumValues.find(function (value) {
                            return value.id === this.variable.value;
                        }, this);
                        result = enumValue ? enumValue.title : '';
                        break;
                    default:
                        result = '';
                        break;
                }
                return result;
            }
        },
        methods: {
            updateVariable: function (newValue) {
                if (this.variable.value === newValue) {
                    return;
                }
                this.queryInProgress = true;
                var variable = Object.clone(this.variable);
                variable.value = newValue;
                config.updateHouseVariable({
                    houseId: this.houseId,
                    variableId: this.variable.id,
                    newValue: variable
                }).next(function () {
                    this.variable.value = newValue;
                }.bind(this)).error(function (e) {
                    console.error(e);
                    alert(T('UpdateHouseVariableError'));
                }).next(function () {
                    this.queryInProgress = false;
                }.bind(this));
            }
        },
        components: {
            'primitive-variable-editor': PrimitiveVariableEditor
        }
    });
});
