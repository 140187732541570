define([
    'knockout',
    'text!./template.html',
    'qrcode',
], function (ko, template, QRCode) {

    function updateQRCode(canvas, string) {
        QRCode.toCanvas(canvas, string, error => {
            if (error) {
                console.error(error);
            }
        });
    }

    const ViewModel = {
        createViewModel(params, componentInfo) {
            params.canvas = componentInfo.element.querySelector('canvas');

            class ViewModel {
                constructor(params) {
                    this.string = params.string();

                    const updateQRCodeFunction = updateQRCode.bind(null, params.canvas);
                    setTimeout(() => updateQRCodeFunction(this.string));

                    params.string.subscribe(newValue => {
                        this.string = newValue;
                        updateQRCodeFunction(this.string);
                    });
                }
            }

            return new ViewModel(params);
        }
    };
    ko.components.register('qr-code', {viewModel: ViewModel, template: template});
});