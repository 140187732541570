/**
 * Модуль для кэширования результатов запросов.
 *
 * Важно! Не гарантируется правильная работа при одновременном вызове функции.
 *
 */

define(['core/config'], function (config) {

    const ttl = 3600000;

    const getInitialState = configMethod => {
        return {method: configMethod, data: null, time: 0};
    };

    const states = {
        accountingElements: getInitialState('getAccountingElements'),
        accountingGroups: getInitialState('getAccountingGroups'),
        metersGroups: getInitialState('getMetersGroups'),
        inputKinds: getInitialState('getInputKinds'),
        allKinds: getInitialState('getAllKinds'),
    };

    const getCurrentTime = () => +new Date();

    const cacheable = (state) => {
        const d = Deferred();
        const currentTime = getCurrentTime();
        if ((currentTime - state.time) > ttl) {
            config[state.method]()
                .next(response => {
                    state.data = response;
                    state.time = getCurrentTime();
                    d.call(state.data);
                })
                .error(e => d.fail(e));
        } else {
            setTimeout(function () {
                d.call(state.data);
            }, 0);
        }
        return d;
    };

    return {

        getAccountingElements() {
            return cacheable(states.accountingElements);
        },

        getAccountingGroups() {
            return cacheable(states.accountingGroups);
        },

        getMetersGroups() {
            return cacheable(states.metersGroups);
        },

        getInputKinds() {
            return cacheable(states.inputKinds);
        },

        getAllKinds() {
            return cacheable(states.allKinds);
        }

    };

});
