define([
    'knockout',
    'knockoutmapping',
    'kolocale',
    'core/resources',
    'core/object-resources',
    'core/config',
    'core/websocket/event-service',
    'core/push/push-service',
    'layout/init',
    'core/routing',
    'core/navigation',
    'vue',
    'vuex',
    'core/utils',
    'core/user',
    'globaleventbus',
    'core/Events',
    'v-mask',
    'vue-infinite-scroll',
    'core/menu-items-map',
    'core/permission',
    'core/local-storage-service',
    'core/page-id',
    // not used:
    'core/set-language',
    'jquerymaskedinput',
    'jquery-binarytransport',
    'core/vue-directives/vue-directives',
    'core/vue-components',
    'bootstrap',
    'mdbootstrap',
    'bootstrap-select',
    'bootstrap-3-typeahead',
    'core/bindings',
    'core/ko.uniqId',
    'core/components',
    'core/ko.focus',
    'core/ko.animation',
    'core/ko.indeterminateValue',
    'core/ko.stringChecked',
    'core/ko.trimmedObservables',
    'core/ko.init',
    'core/ko.dateRangePicker',
    'core/ko.telText',
    'core/ko.textInputWithAllowedChars',
    'core/ko.autoScrollIfTextOverflow',
    'core/ko.bootstrapSelect',
    'core/ko.bootstrapTypeahead',
    'core/ko.tooltip',
    'core/ko.truncateValue',
    'core/ko.maxLength',
    'core/ko.convertDate',
    'core/ko.scrollTo',
    'core/ko.clickOutside',
    'spectrum',
    '../node_modules/spectrum-colorpicker/i18n/jquery.spectrum-ru',
    'knockout-sortable',
    '../node_modules/spectrum-colorpicker/spectrum.css',
    '../node_modules/bootstrap-select/dist/css/bootstrap-select.min.css',
    '../node_modules/jquery.kladr/jquery.kladr.min.js',
    '../node_modules/jquery.kladr/jquery.kladr.min.css',
    '../node_modules/font-awesome/css/font-awesome.min.css',
    '../node_modules/bootstrap-daterangepicker/daterangepicker.css',
    'styles/main.scss',
    '../node_modules/material-design-icons-iconfont/dist/material-design-icons.css'
], function (
    ko,
    komapping,
    kolocale,
    resources,
    objectResources,
    config,
    EventService,
    PushService,
    Layout,
    Routing,
    Navigation,
    Vue,
    Vuex,
    Utils,
    User,
    EventBus,
    Events,
    VueMask,
    infiniteScroll,
    MenuItemsMapModule,
    PermissionModule,
    localStorageService,
    PageIdModule
) {
    Layout = Layout.default;
    User = User.default;
    const Permission = PermissionModule.Permission;
    PushService = PushService.default;

    $.mask.definitions['h'] = "[A-Fa-f0-9]";

    window.globalState = window.globalState || {};
    window.globalState.locale = ko.observable(localStorage.getItem('locale'));
    kolocale.init(window.globalState.locale, resources);
    window.T = kolocale.getLocalizedText;

    var body = {
        $layout: ko.observable(),
        coreVersion: ko.observable(''),
        webVersion: localStorage.getItem('webVersion'),
        modalOpenedCount: ko.observable(0),
    };

    config.getVersion().next(function (json) {
        body.coreVersion(json.version.split(" ")[0]);
    });

    config.getUser()
        .next(function (login) {
            const permissions = [];
            const pagesIds = [];

            login.permissions.forEach(perm => {
                if (perm.startsWith('view.') && PageIdModule.isPageId(perm)) {
                    pagesIds.push(perm);
                } else {
                    permissions.push(perm);
                }
            });

            // Если у пользователя нет разрешений, то выполняется logout()
            if (pagesIds.length === 0) {
                config.deleteAccessToken()
                    .error(e => console.error(e))
                    .next(() => {
                        alert(T('NoPages'));
                        localStorageService.removeAccessToken();
                        localStorageService.removeRefreshToken();
                        location.replace('signin/signin.html');
                    });
                return;
            }

            login.permissions = permissions;
            login.pagesIds = pagesIds;

            if (!login.pagesIds.some(MenuItemsMapModule.isKnockoutPageId)) {
                throw new Error('OnlyAngularRole');
            }

            User.setUser(login);
            EventService.connect();
            PushService.init();

            window.globalState.login = komapping.fromJS(login);
            if (login.permissions.find(permission => permission === 'needHouseTypes')) {
                kolocale.update(objectResources);
            }
        })
        .next(function () {
            Vue.prototype.globalState = window.globalState;
            Vue.prototype.Utils = Utils;
            Vue.prototype.T = T;
            // использование глобальных переменных в коде - плохая практика
            // использорвать gUser можно только в шаблонах HTML
            Vue.prototype.gUser = User;
            window.gUser = User;
            Vue.use(infiniteScroll);
            Vue.use(Vuex);
            Vue.directive('mask', VueMask.VueMaskDirective);
            require('core/vee-validate-config');
        })
        .next(function () {
            const toggleSidebar = function () {
                const sidebarToggleClass = 'sidebar-show';
                $('.iot-menu').toggleClass(sidebarToggleClass);
            };
            const removeSidebar = function () {
                const sidebarToggleClass = 'sidebar-show';
                if ($('.iot-menu').hasClass(sidebarToggleClass))
                    $('.iot-menu').removeClass(sidebarToggleClass);
            };

            const $body = $('body');
            $body.on('click', '.iot-sidebarToggle', toggleSidebar);
            $body.on('click', '.iot-menu a', toggleSidebar);
            $body.on('click', 'main', removeSidebar);
        })
        .next(() => {
            EventBus.addEventListener(
                Events.types.NOTIFICATIONS_BETWEEN_PAGES.MODAL_VISIBILITY_CHANGE,
                (event, data) => body.modalOpenedCount(body.modalOpenedCount() + (data ? 1 : -1)),
                Events.scopes.NOTIFICATIONS_BETWEEN_PAGES
            );
        })
        .error(function (e) {
            if (e.message === 'OnlyAngularRole') {
                window.location.href = '/ng';
                return;
            }
            console.error(e);
            localStorageService.removeAccessToken();
            localStorageService.removeRefreshToken();
            window.location = 'signin/signin.html';
        })
        .next(() => {
            const roleDefaultPage = User.defaultPageLink;
            if (!User.hasPermission(Permission.computeDefaultPage)) {
                return roleDefaultPage;
            }
            let user = User.instance;
            if (user.houseIds.length === 0) {
                return '/ng/houses';
            }
            let info = {
                housesCount: user.houseIds.length,
                controllersCount: 0,
                devicesCount: 0,
                houseId: null
            };
            const requests = user.houseIds.map(houseId => config.getHouse.bind(config, {houseId}));
            return Deferred.parallel(requests)
                .next(houses => {
                    info.houseId = houses[0].id;

                    houses.forEach(house => {
                        info.controllersCount += house.controllers.length;
                        house.controllers.forEach(controller => info.devicesCount += controller.nodeIds.length)
                    });

                    if (info.controllersCount === 0) {
                        if (info.housesCount === 1) {
                            return '/ng/houses/' + info.houseId;
                        }

                        return '/ng/houses';
                    }

                    if (info.devicesCount === 0) {
                        return '#devices';
                    }

                    return roleDefaultPage;
                })
                .error(e => {
                    console.error(e);
                    return roleDefaultPage;
                });
        })
        .next((defaultPage) => {
            ko.applyBindings(body, document.body);
            const layout = Layout.init({kocontainer: body.$layout});
            Routing.init({layout, defaultPage});
            window.Navigation = new Navigation({layout});
            Vue.prototype.Navigation = window.Navigation;
        });
});
