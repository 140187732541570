define([
    'knockout',
    'core/config',
    'text!./template.html'
], (ko, config, template) => {

    class ViewModel {
        constructor(params) {
            this.controllerId = ko.unwrap(params.controllerId);
            this.nodeId = ko.unwrap(params.nodeId);
            this.commands = ko.observableArray();
            config.getDeviceCommands({controllerId: this.controllerId, nodeId: this.nodeId})
                .next(commands => ko.utils.arrayPushAll(this.commands, commands.filter(c => c.favorite)))
                .error(e => console.error(e));
        }

        sendCommand(commandId) {
            const params = {
                controllerId: this.controllerId,
                nodeId: this.nodeId,
                commandId
            };
            config.runDeviceCommand(params)
                .error(e => {
                    alert(T('RunDeviceCommandError'));
                    console.error(e);
                });
        }
    }

    ko.components.register('device-command-sender', {viewModel: ViewModel, template});

});
