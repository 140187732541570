define([
    'knockout',
    'text!./template.html',
    'core/splice-history-actions',
    'core/shift-history-actions',
], function (ko, template, SPLICE_HISTORY_ACTIONS, SHIFT_HISTORY_ACTIONS) {

    class ViewModel {
        constructor(params) {
            this.isShift = params.isShift;
            this.isOpen = params.isOpen;
            this.callback = params.callback();
            this.selectedItem = ko.observable(null);
            this.ACTIONS = this.isShift() ? SHIFT_HISTORY_ACTIONS : SPLICE_HISTORY_ACTIONS;
        }

        close() {
            this.isOpen(false);
            this.isShift(false);
        }

        submit() {
            this.callback(this.selectedItem());
            this.isOpen(false);
            this.isShift(false);
        }
    }

    ko.components.register('splice-history', {viewModel: ViewModel, template: template});
});
