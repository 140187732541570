define(['knockout'], function (ko) {
    'use strict';

    // по аналогии с v-click-outside
    ko.bindingHandlers.clickOutside = {
        init(el, valueAccessor) {
            const callback = ko.utils.unwrapObservable(valueAccessor());

            el.clickOutsideEvent = event => {
                if (!(el == event.target || el.contains(event.target))) {
                    callback();
                }
            };

            // без таймаута здесь ловится клик, по которому был показан элемент с помощью ko if
            setTimeout(() => document.body.addEventListener('click', el.clickOutsideEvent));

            ko.utils.domNodeDisposal.addDisposeCallback(el, () => {
                document.body.removeEventListener('click', el.clickOutsideEvent);
            });
        }
    };

});
