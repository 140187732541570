define(() => {
    const FEEDBACK_MODE = Object.freeze({
        Waiting:  'WAITING',
        Patterns: 'PATTERNS',
        Security: 'SECURITY',
        Success:  'SUCCESS'
    });

    const TITLES_MODAL_FEEDBACK = Object.freeze({
        [FEEDBACK_MODE.Waiting]:  'DeviceInitialization',
        [FEEDBACK_MODE.Security]: 'AddDeviceMode',
        [FEEDBACK_MODE.Patterns]: 'ScriptsAutoCreation',
        [FEEDBACK_MODE.Success]:  'DeviceSuccessfullyAdded',
    });

    return {
        FEEDBACK_MODE,
        TITLES_MODAL_FEEDBACK
    }
});
