define([
    'moment',
    'autobind-decorator',
    'knockout'
], function (moment, autobind, ko) {

    @autobind.boundClass
    class Utils {

        LOCALE_COMPARE_OPTIONS = Object.freeze({numeric: true, sensitivity: 'base'});

        getInputDescriptionTranslateKey(inputDescription) {
            return inputDescription.replace(/^@/, 'InputDesc_');
        }

        removeDuplicateInArray(array) {
            return array.filter((elem, pos) => array.indexOf(elem) === pos);
        }

        /**
         * Деление нацело
         * @param a - делимое
         * @param b - делитель
         * @returns {number} - частное без остатка
         */
        div(a, b) {
            return (a - a % b) / b;
        }

        removeExtraSpaceInString(string) {
            return string == null ? null : string.trim().replace(/\s\s+/ig, ' ');
        }

        unixTimestampToFormattedDate(unixTimestamp, format) {
            if (!moment(unixTimestamp, 'X').isValid()) {
                return '';
            }
            format = format || 'YYYY-MM-DD HH:mm:ss';
            return moment.unix(unixTimestamp).format(format);
        }

        dateToTimestamp(date) {
            return date.getTime() / 1000;
        }

        getCurrentTimestamp() {
            return Math.floor(this.dateToTimestamp(new Date()));
        }

        deepCopy(any) {
            return JSON.parse(JSON.stringify(any));
        }

        deepEquals(x, y) {
            return JSON.stringify(x) === JSON.stringify(y);
        }

        escapeFileNameComponent(fileName) {
            return fileName.replace(/[^a-z0-9а-я]/gi, '-');
        }

        anyToString(any) {
            return any + '';
        }

        stringEqualsIgnoreCase(str1, str2) {
            return str1.toLowerCase() === str2.toLowerCase();
        }

        formatNumberForCalculation(x, group, delimiter, separator) {
            if (x == null) {
                return '';
            }
            x = x * group.multiplier;
            if (this.stringEqualsIgnoreCase(group.units, 'Гкал')) { // http://red.eltex.loc/issues/116538
                x = x.toFixed(4);
            } else if (this.stringEqualsIgnoreCase(group.units, 'кВт*ч')) {
                x = Math.trunc(x);
            } else if (this.stringEqualsIgnoreCase(group.units, 'м3')) {
                x = x.toFixed(3);
            }
            if (delimiter === undefined) {
                delimiter = ' ';
            }
            separator = separator || ',';

            const parts = x.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, delimiter);

            return parts.join(separator);
        }

        snakeToCamel(input) {
            return input.toLowerCase().replace(/(\_\w)/g, m => m[1].toUpperCase());
        }

        onlyDigits(str) {
            return /^\d+$/.test(str);
        }

        trimIfNotNull(string) {
            return string == null ? null : string.trim();
        }

        compareNumeric(a, b) {
            return a - b;
        }

        compareNumericDescending(a, b) {
            return b - a;
        }

        compareNumericStrings(a, b) {
            return a.localeCompare(b, undefined, this.LOCALE_COMPARE_OPTIONS);
        }

        /**
         * Клонирование массива, которое используется,
         * когда обычные [...array] и array.slice() не помогают.
         */
        cloneArray(array) {
            return array.map((b) => Object.assign(b));
        }

        isBooleanString(string) {
            return (string === 'true' || string === 'false');
        }

        isNgLink(link) {
            return link.startsWith('/ng/');
        }

        applyAutoWidth(XLSXWorkSheet, widthMultiplier) {
            widthMultiplier = widthMultiplier || 1.25;

            const cellsKeys = Object.keys(XLSXWorkSheet).filter(key => key.match(/^[A-Z]+\d+$/)),
                columns = this.removeDuplicateInArray(cellsKeys.map(key => key.replace(/\d/g, '')));

            const getMaxLengthOfColumn = column => {
                const regExp = new RegExp('^' + column + '\\d+$'),
                    keysOfColumn = cellsKeys.filter(key => key.match(regExp));

                return keysOfColumn.reduce((max, key) => {
                    const cellLength = this.anyToString(XLSXWorkSheet[key].v).length;
                    return cellLength > max ? cellLength : max;
                }, 0);
            };

            if (XLSXWorkSheet['!cols'] == null) {
                XLSXWorkSheet['!cols'] = [];
            }

            columns.forEach((column, index) => {
                if (XLSXWorkSheet['!cols'][index] == null) {
                    XLSXWorkSheet['!cols'][index] = {};
                }

                Object.assign(XLSXWorkSheet['!cols'][index], {width: getMaxLengthOfColumn(column) * widthMultiplier});
            });
        }

        secondsToHms(input) {
            input = Number(input);
            const hours = Math.floor(input / 3600);
            const minutes = Math.floor(input % 3600 / 60);
            const seconds = Math.floor(input % 3600 % 60);
            return [hours, T('h'), minutes, T('m'), seconds, T('s')].join(' ');
        }

        generateRandomId() {
            return (Math.random() + 1).toString(36).substring(2, 15);
        }

        disableKoOptionsCaption(option, item) {
            ko.applyBindingsToNode(option, {disable: !item}, item);
        }

        /**
         * Конвертирует строку в формате "xxx.xxx.xxx.xxx:yyy"
         * в объект {ip: 'xxx.xxx.xxx.xxx', port: 'yyy'}.
         * @param ipPort строка в формате "xxx.xxx.xxx.xxx:yyy"
         * @returns {{ip: string, port: string}}
         */
        ipPortToObj(ipPort) {
            const parts = ipPort.split(':');
            return {ip: parts[0], port: parts[1]};
        }

        getNavigationLink(path, urlParams) {
            let urlParamsString = Object.toQueryString(urlParams);
            urlParamsString = urlParamsString === '' ? '' : ('/?' + urlParamsString);
            path = '#' + path.replace(/^#*/, '').replace(/\/*$/, '');
            return path + urlParamsString;
        }

        removeFromArray(array, item) {
            const index = array.indexOf(item);
            if (index !== -1) {
                array.splice(index, 1);
            }
        }

        getUserFullName(user) {
            const {name, patronymic, surname, username} = user;
            const fullName = [name, patronymic, surname].join(' ').trim();
            return fullName.length === 0 ? username : fullName + ` (${username})`;
        }

        copyToClipboard(input) {
            const tempInput = document.createElement("input");
            tempInput.setAttribute("value", input);
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand("copy");
            document.body.removeChild(tempInput);
        }

    }

    return new Utils();

});
