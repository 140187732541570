define([
    'core/utils'
], function (Utils) {
    'use strict';

    const accessTokenKey = 'access_token';
    const refreshTokenKey = 'refresh_token';
    const wsAddressKey = 'wsAddress';
    const pushEnabledKey = 'pushEnabled';
    const firebaseTokenKey = 'firebaseToken';
    const firebaseTokenRegFirebaseKey = 'firebaseTokenRegFirebase';
    const firebaseTokenRegApiKey = 'firebaseTokenRegApi';

    return {
        getAccessToken() {
            return localStorage.getItem(accessTokenKey);
        },
        setAccessToken(token) {
            localStorage.setItem(accessTokenKey, token);
        },
        removeAccessToken() {
            localStorage.removeItem(accessTokenKey);
        },

        getRefreshToken() {
            return localStorage.getItem(refreshTokenKey);
        },
        setRefreshToken(token) {
            localStorage.setItem(refreshTokenKey, token);
        },
        removeRefreshToken() {
            localStorage.removeItem(refreshTokenKey);
        },

        getWsAddress() {
            return localStorage.getItem(wsAddressKey);
        },

        isPushEnabled() {
            return localStorage.getItem(pushEnabledKey) === 'true';
        },

        getFirebaseToken() {
            return localStorage.getItem(firebaseTokenKey);
        },

        setFirebaseToken(token) {
            if (token) {
                localStorage.setItem(firebaseTokenKey, token);
            } else {
                localStorage.removeItem(firebaseTokenKey);
            }
        },

        isFirebaseTokenRegFirebase() {
            return localStorage.getItem(firebaseTokenRegFirebaseKey) === 'true';
        },

        setFirebaseTokenRegFirebase(boolValue) {
            return localStorage.setItem(firebaseTokenRegFirebaseKey, Utils.anyToString(boolValue));
        },

        isFirebaseTokenRegApi() {
            return localStorage.getItem(firebaseTokenRegApiKey) === 'true';
        },

        setFirebaseTokenRegApi(boolValue) {
            return localStorage.setItem(firebaseTokenRegApiKey, Utils.anyToString(boolValue));
        }
    };
});
