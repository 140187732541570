define([
    'knockout',
    'text!./template.html'
], (ko, template) => {

    class ViewModel {
        constructor(params) {
            this.containerClass = ko.unwrap(params.containerClass);
            this.buttonClass = ko.unwrap(params.buttonClass);
            this.options = params.options;
            this.optionsText = ko.unwrap(params.optionsText);
            this.optionsTranslate = params.optionsTranslate;
            this.optionsValue = ko.unwrap(params.optionsValue);
            this.value = params.value;

            this.selected = ko.computed(() => {
                const options = ko.unwrap(this.options);
                return options.find(item => {
                    if (this.optionsValue) {
                        return item[this.optionsValue]() === this.value();
                    }
                    return item === this.value();
                });
            });
        }

        setNewValue(option) {
            if (this.optionsValue) {
                this.value(option[this.optionsValue]);
            } else {
                this.value(option);
            }
        }

        getText(item) {
            switch (typeof this.optionsText) {
                case 'function':
                    return this.optionsText(item);
                case 'string':
                    return item[this.optionsText];
                default:
                    return item;
            }
        }
    }

    ko.components.register('select-dropdown', {viewModel: ViewModel, template});

});
