define([
    'knockout',
    'core/tel-common'
], function (ko, telCommon) {
    'use strict';

    ko.bindingHandlers.telText = {
        update: function (element, valueAccessor) {
            var value = ko.utils.unwrapObservable(valueAccessor());
            var countryByTel = telCommon.getCountryByTel(value);
            if (countryByTel) {
                var valWithoutDialCode = value.substring(countryByTel.dialCode.length);
                value = '+' + countryByTel.dialCode + countryByTel.format(valWithoutDialCode);
            }
            ko.bindingHandlers.text.update(element, function () {
                return value;
            });
        }
    };
});
