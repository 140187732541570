define(['knockout'], function (ko) {
    'use strict';

    ko.bindingHandlers.init = {
        init: function (element, valueAccessor) {
            var value = valueAccessor();
            if (typeof value !== 'function'){
                throw new Error('"init" binding accepts the Function!');
            }
            value();
        }
    };
});
