define(['vue'], function (Vue) {

    const DEFAULT_OPTIONS = Object.freeze({
        placement: 'top',
        trigger: 'hover'
    });

    Vue.directive('tooltip', {
        bind: (el, binding) => {
            /** скрытие стандартного popup с title */
            el.onmouseover = event => event.preventDefault();

            const options = Object.assign({}, DEFAULT_OPTIONS, binding.value);
            $(el).tooltip(options);
        },
        unbind: el => $(el).tooltip('dispose')
    });

});
