define([
    'vue',
    'core/config',
    'text!./template.html'
], function (Vue, config, template) {
    const meterImages = {
        ELECTRIC:   'iot-meter-power text-warning',
        HOTWATER:   'iot-meter-water text-danger',
        COLDWATER:  'iot-meter-water text-primary',
        GAS:        'iot-meter-gas text-warning',
        HEAT:       'iot-meter-warm text-warning'
    };

    Vue.component('v-widget-monitoring-meter', {
        template: template,
        props: {
            params: Object
        },
        data: function() {
            const fields = ['group', 'superGroup', 'value', 'personal', 'serial'];
            const data = {
                img: meterImages[this.params.group.groupId.toUpperCase()],
                valueTransformed: (this.params.value * this.params.group.multiplier).toFixed(3)
            };
            fields.forEach(field => data[field] = this.params[field]);
            return data;
        },
    });
});
