define([
    'vue',
    'core/config',
    'text!./template.html',
    'globaleventbus',
    'core/Events',
    'core/splice-history-actions',
], function (Vue,
             config,
             template,
             EventBus,
             Events,
             SPLICE_HISTORY_ACTIONS) {

    Vue.component('v-splice-history-modal', {
        template: template,
        props: {
            spliceHistoryModalCallback: Function,
            historyWithDuplicate: Object,
            selectedItemsForSpliceHistory: Object,
            address: String,
            allGroups: Object
        },

        data: function () {
            return {
                selectedItem: SPLICE_HISTORY_ACTIONS.START_NEW_HISTORY,
                objectHistoryWithDuplicate: this.historyWithDuplicate,
                selectedHistoryItems: {},
                selectedHistoryItemsArray: this.selectedItemsForSpliceHistory,
                SPLICE_HISTORY_ACTIONS
            };
        },
        created: function () {
            EventBus.dispatch(Events.types.NOTIFICATIONS_BETWEEN_PAGES.MODAL_VISIBILITY_CHANGE, 'target', true);
        },
        methods: {
            update: function (group, historyId, apartment) {
                this.selectedHistoryItemsArray[apartment] = {[group]: []};
                this.selectedHistoryItemsArray[apartment][group].push({historyId: historyId});
            },
            closeModal: function () {
                EventBus.dispatch(Events.types.NOTIFICATIONS_BETWEEN_PAGES.MODAL_VISIBILITY_CHANGE, 'target', false);
                this.$emit('close');
            },
            submit: function () {
                this.spliceHistoryModalCallback(this.selectedItem);
                this.closeModal();
            }
        },
        computed: {
            isSpliceAvailable: function () {
                return this.selectedItem !== SPLICE_HISTORY_ACTIONS.SPLICE_HISTORY ||
                    Object.keys(this.selectedHistoryItems).length === Object.keys(this.historyWithDuplicate).length
            }
        }
    });
});
