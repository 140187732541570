define([
    'knockout',
    'knockoutmapping',
    'text!./template.html'
], function (ko, komapping, template) {
    function ViewModel(params) {
        this.editMode = ko.observable(false);
        this.value = params.value;
        this.newValue = komapping.fromJS(komapping.toJS(params.value));
        this.submitHandler = params.submitHandler;
        this.maxlength = params.maxlength;
        this.errorMessage = ko.observable('');
    }

    ViewModel.prototype.switchMode = function () {
        this.errorMessage('');
        if (this.editMode()) {
            this.newValue(komapping.toJS(this.value));
        }
        this.editMode(!this.editMode());
    };

    ViewModel.prototype.submit = function () {
        let oldValue = this.value();
        this.value(komapping.toJS(this.newValue));
        this.submitHandler()
            .next(this.switchMode.bind(this))
            .error(function (e) {
                this.errorMessage(e.message || 'Error');
                this.value(oldValue);
            }.bind(this));
    };

    ko.components.register('editable-text', {
        viewModel: ViewModel,
        template: template
    });
});
