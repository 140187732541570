define([
    'knockout',
    'text!./template.html'
], function (ko, template) {
    'use strict';

    class ViewModel {
        constructor(params) {
             this.channelTitle = params.channelTitle;
        }
    }

    ko.components.register('translate-channel-title', {viewModel: ViewModel, template: template});
});
