define([
    'moment',
    'knockout',
    'knockoutmapping',
    'chart',
    'core/config',
    'core/store',
    'lodash.debounce',
    'text!./template.html',
    'core/chart/zero-compensation'
], function (moment,
             ko,
             komapping,
             _,
             config,
             store,
             debounce,
             template,
             zeroCompensation) {

    const DETAILS = {
        SEC: 1,
        MIN: 2,
        HOUR: 3,
        DAY: 4
    };

    const DETAIL_FULL = {...DETAILS};
    const DETAIL_PART = {
        HOUR: DETAILS.HOUR,
        DAY: DETAILS.DAY
    };
    const DEFAULT_TIME_FORMAT = 'DD.MM.YYYY';

    function timeDisplayFormats(daysCount, valueDetail) {
        if (daysCount !== 0 && valueDetail === DETAILS.DAY)
            return DEFAULT_TIME_FORMAT;

        let timeDisplayFormats = '';
        if (daysCount !== 0)
            timeDisplayFormats = DEFAULT_TIME_FORMAT + ' ';
        if (valueDetail === DETAILS.SEC)
            return timeDisplayFormats + 'HH:mm:ss';
        else
            return timeDisplayFormats + 'HH:mm';
    };

    var ViewModel = {
        createViewModel: function (params, componentInfo) {
            function ViewModel(params) {
                this.myChart;
                this.errorChart = ko.observable(false);
                this.emptyData = ko.observable(false);

                // вид графика
                this.typeChartText = ko.observable('lineChart');

                // временной промежуток
                const zeroTime = {hour: 0, minute: 0, second: 0, millisecond: 0};
                const lastTime = {hour: 23, minute: 59, second: 59, millisecond: 59};
                this.DATE_TIME_FORMAT = DEFAULT_TIME_FORMAT;
                this.startInterval = ko.unwrap(params.startInterval) || 'month';
                this.reportMomentFrom = ko.observable(moment().subtract(1, this.startInterval + 's').set(zeroTime));
                this.reportMomentTo = ko.observable(moment().set(lastTime));

                // агрегация
                this.aggregates = params.aggregates;
                this.aggregatesDefined = ko.observable(!!this.aggregates);
                let defaultAggregate = 'last';
                if (this.aggregatesDefined()) {
                    const aggregates = ko.isObservable(this.aggregates) ? this.aggregates() : this.aggregates;
                    if (aggregates.length > 0) {
                        defaultAggregate = aggregates[0];
                    }
                }
                this.aggregate = ko.observable(defaultAggregate);

                // детализация
                this.DETAIL = ko.observable({
                    HOUR: 3,
                    DAY: 4
                });
                this.reportDetail = ko.observable({title: 'DAY', value: this.DETAIL().DAY});
                this.changeDetailByDates = function () {
                    const daysCount = this.reportMomentTo().diff(this.reportMomentFrom(), 'days');
                    if (daysCount > 10) {
                        this.DETAIL(DETAIL_PART);
                    } else {
                        this.DETAIL(DETAIL_FULL);
                    }
                    if (daysCount === 0)
                        this.reportDetail({title: 'MIN', value: DETAILS.MIN});
                    else if (daysCount >= 1 && daysCount <= 10)
                        this.reportDetail({title: 'HOUR', value: DETAILS.HOUR});
                    else
                        this.reportDetail({title: 'DAY', value: DETAILS.DAY});
                }.bind(this);
                this.changeDetailByDates();

                // архив
                this.needArchiveData = ko.observable(params.needArchiveData);

                // логарифмическая шкала
                this.needLogarithmicScale = params.needLogarithmicScale;
                this.isLogarithmicScale = ko.observable(false);

                // другие параметры
                this.title = params.title;
                this.titleIcon = ko.unwrap(params.titleIcon);
                this.propertyId = ko.unwrap(params.propertyId);
                this.allGroups = params.allGroups;
                this.multiplier = (ko.isObservable(params.multiplier) ? params.multiplier() : params.multiplier) || 0.001;
                this.beginAtZero = ko.unwrap(params.beginAtZero) || false;
                this.needStatistics = ko.unwrap(params.needStatistics);
                this.statistics = ko.observableArray([]);
                this.yAxisLabel = ko.unwrap(params.yAxisLabel);
                this.currentValue = ko.observable(ko.unwrap(params.currentValue));

                params.valueFrom = this.reportMomentFrom().format('X');
                params.valueTo = this.reportMomentTo().format('X');
                params.valueDetail = this.reportDetail().value;
                params.mode = this.aggregate();

                const getMethod = () => {
                    if (this.needArchiveData()) {
                        return 'getDeviceArchiveHistory';
                    } else if (params.isDeviceDataById) {
                        return 'getDeviceHistory';
                    } else {
                        return 'getMeterHistoryWithGroups'
                    }
                };

                const method = getMethod();

                config[method](komapping.toJS(params))
                    .next(dataChart => {
                        this.errorChart(false);
                        this.emptyData(false);

                        if (dataChart.length === 0) {
                            this.emptyData(true);
                        }

                        if (params.isDeviceDataById) {
                            dataChart = [
                                {data: dataChart}
                            ];
                        }

                        let typeChart = 'line';
                        if (params.mode === 'delta' || params.isHistoryWithGroups) {
                            typeChart = 'bar';
                            this.typeChartText('barChart');
                        }

                        const colorsChart = ['61, 170, 226', '228, 76, 86', '105, 184, 115', '255, 143, 66'];

                        let countChart = 1;
                        let label = ko.isObservable(params.xAxisLabel) ? params.xAxisLabel() : params.xAxisLabel;
                        if (Array.isArray(params.xAxisLabel)) {
                            countChart = params.xAxisLabel.length;
                            label = '';
                        }

                        let datasetsChart = [];
                        for (let i = 0; i < countChart; i++) {
                            let dataset = {
                                label: label || params.xAxisLabel[i],
                                backgroundColor: "rgba(" + colorsChart[i] + ",0.2)",
                                borderColor: "rgba(" + colorsChart[i] + ",1)",
                                hoverBackgroundColor: "rgba(" + colorsChart[i] + ",0.4)",
                                hoverBorderColor: "rgba(" + colorsChart[i] + ",1)",
                                borderWidth: 2,
                                fill: true,
                                pointRadius: 0,
                                lineTension: 0,
                                data: []
                            };
                            datasetsChart.push(dataset);
                        }

                        dataChart.forEach((data, index) => {
                            this.statistics.removeAll();
                            let stat = null;

                            for (let key in data.data) {
                                const xValue = params.valueDetail === this.DETAIL().DAY ?
                                    moment(key, 'X').set(zeroTime).format('X') :
                                    key;
                                const yValue = data.data[key] * this.multiplier;
                                const ty = {x: xValue * 1000, y: yValue};
                                datasetsChart[index].data.push(ty);

                                if (this.needStatistics) {
                                    if (stat == null) {
                                        stat = {min: yValue, max: yValue, avg: yValue}
                                    } else {
                                        if (yValue < stat.min) stat.min = yValue;
                                        if (yValue > stat.max) stat.max = yValue;
                                        stat.avg = stat.avg + yValue;
                                    }
                                }
                            }
                            if (stat != null) {
                                stat.avg = stat.avg / Object.keys(data.data).length;
                            }
                            this.statistics.push(stat);

                            if (data.group && this.allGroups) {
                                datasetsChart[index].label = this.allGroups[data.group].title;
                            }
                        });

                        var optionsChart = {
                            legend: {
                                display: true,
                                position: 'bottom',
                            },
                            tooltips: {
                                mode: 'index',
                                intersect: false
                            },
                            scales: {
                                xAxes: [{
                                    type: 'time',
                                    time: {
                                        unit: 'hour',
                                        displayFormats: {
                                            'hour': this.DATE_TIME_FORMAT
                                        }
                                    },
                                    offset: true,
                                    ticks: {
                                        source: params.valueDetail === this.DETAIL().DAY ? 'data' : 'auto',
                                        autoSkip: true,
                                        maxTicksLimit: 30,
                                        minRotation: 30
                                    },
                                    maxBarThickness: 25,
                                }],
                                yAxes: [{
                                    scaleLabel: {
                                        display: true,
                                        labelString: this.yAxisLabel,
                                    },
                                    ticks: {
                                        beginAtZero: this.beginAtZero
                                    }
                                }]
                            }
                        };

                        var ctx = componentInfo.element.querySelector('canvas').getContext('2d');

                        this.myChart = new Chart(ctx, {
                            type: typeChart,
                            data: {
                                datasets: datasetsChart
                            },
                            options: optionsChart,
                            plugins: [zeroCompensation]
                        });
                    }).error(e => {
                        this.errorChart(true);
                        console.error(e);
                    });

                this.changeType = function (newType) {
                    this.typeChartText(newType + 'Chart');
                    this.myChart.config.type = newType; // The new chart type
                    this.myChart.update();
                }.bind(this);

                const load = function () {
                    params.valueFrom = this.reportMomentFrom().format('X');
                    params.valueTo = this.reportMomentTo().set(lastTime).format('X');
                    params.mode = this.aggregate();
                    params.valueDetail = this.reportDetail().value;
                    const method = getMethod();

                    config[method](komapping.toJS(params))
                        .next(dataChart => {
                            this.errorChart(false);
                            this.emptyData(false);
                            if (dataChart.length === 0) {
                                this.myChart.config.data.datasets.forEach(dataset => dataset.data = []);
                                this.emptyData(true);
                            }

                            if (params.isDeviceDataById) {
                                dataChart = [
                                    {data: dataChart}
                                ];
                            }

                            if (params.mode === 'delta' || params.isHistoryWithGroups) {
                                this.myChart.config.type = 'bar';
                                this.changeType('bar');
                            }

                            dataChart.forEach((data, index) => {
                                this.statistics.removeAll();
                                let stat = null;

                                this.myChart.config.data.datasets[index].data = [];
                                for (let key in data.data) {
                                    if (ko.isObservable(params.multiplier)) {
                                        this.multiplier = params.multiplier();
                                    }
                                    const xValue = params.valueDetail === this.DETAIL().DAY ?
                                        moment(key, 'X').set(zeroTime).format('X') :
                                        key;
                                    const yValue = data.data[key] * this.multiplier;
                                    const ty = {x: xValue * 1000, y: yValue};
                                    this.myChart.config.data.datasets[index].data.push(ty);

                                    if (this.needStatistics) {
                                        if (stat == null) {
                                            stat = {min: yValue, max: yValue, avg: yValue}
                                        } else {
                                            if (yValue < stat.min) stat.min = yValue;
                                            if (yValue > stat.max) stat.max = yValue;
                                            stat.avg = stat.avg + yValue;
                                        }
                                    }
                                }
                                if (stat != null) {
                                    stat.avg = stat.avg / Object.keys(data.data).length;
                                }
                                this.statistics.push(stat);

                                if (data.group && this.allGroups) {
                                    this.myChart.config.data.datasets[index].label = this.allGroups[data.group].title;
                                }
                            });

                            let ticksSource = 'data';
                            if (params.valueDetail != this.DETAIL().DAY) {
                                ticksSource = 'auto';
                            }
                            if (this.isLogarithmicScale()) {
                                this.myChart.options.scales.yAxes[0].type = 'logarithmic';
                                this.myChart.options.scales.yAxes[0].ticks = {
                                    callback: (value, index, values) => {
                                        if (value === 1000000) return "1M";
                                        if (value === 100000) return "100K";
                                        if (value === 10000) return "10K";
                                        if (value === 1000) return "1K";
                                        if (value === 100) return "100";
                                        if (value === 10) return "10";
                                        if (value === 1) return "1";
                                        if (value === 0) return "0";
                                        return null;
                                    }
                                };
                            } else {
                                this.myChart.options.scales.yAxes[0].type = 'linear';
                                this.myChart.options.scales.yAxes[0].ticks = {
                                    callback: (value, index, values) => {
                                        return value;
                                    }
                                };
                                this.myChart.options.scales.yAxes[0].ticks.beginAtZero = this.beginAtZero;
                            }
                            const daysCount = this.reportMomentTo().diff(this.reportMomentFrom(), 'days');
                            this.myChart.options.scales.xAxes[0].time.displayFormats.hour = timeDisplayFormats(daysCount, params.valueDetail);
                            this.myChart.options.scales.xAxes[0].ticks.source = ticksSource;

                            if (ko.isObservable(params.xAxisLabel)) {
                                this.myChart.config.data.datasets[0].label = params.xAxisLabel();
                            }
                            this.myChart.options.scales.yAxes[0].scaleLabel.labelString = this.yAxisLabel;

                            this.myChart.update();
                        }).error(e => {
                            this.errorChart(true);
                            console.error(e);
                        });
                }.bind(this);

                this.reportMomentFrom.subscribe(this.changeDetailByDates);
                this.reportMomentTo.subscribe(this.changeDetailByDates);

                const debouncedLoad = debounce(load, 500);
                this.reportMomentFrom.subscribe(debouncedLoad);
                this.reportMomentTo.subscribe(debouncedLoad);
                this.aggregate.subscribe(debouncedLoad);
                this.reportDetail.subscribe(debouncedLoad);
                this.isLogarithmicScale.subscribe(debouncedLoad);
                this.needArchiveData.subscribe(debouncedLoad);

                this.showChart = ko.observable(true);
                this.toggleCharts = () => this.showChart(!this.showChart());
            }

            return new ViewModel(params);
        }
    };

    ko.components.register('iot-chart', {
        viewModel: ViewModel,
        template: template
    });
});
