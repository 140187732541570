import Vue from 'vue';
import template from 'text!./template.html';
import config from 'core/config';
import Utils from 'core/utils';

Vue.component('v-license-alert', {
    template,
    data() {
        return {
            noActiveLicense: false,
            licenseExpiresSoon: false
        };
    },
    created() {
        config.getLicenses().next(licenses => {
            const current = Utils.getCurrentTimestamp();
            this.noActiveLicense = !licenses.some(license => license.expirationDate > current);
            const period = 1209600; // 14 дней
            this.licenseExpiresSoon = licenses.every(license => (license.expirationDate - current) < period);
        });
    }
});
