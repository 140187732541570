/**
 * IndeterminateValue attribute
 *
 * Snipped from: https://jsfiddle.net/deannorth/crqwac12
 */

define(['knockout'], function (ko) {
    'use strict';

    ko.bindingHandlers.indeterminateValue = {
        update: function (element, valueAccessor) {
            element.indeterminate = ko.utils.unwrapObservable(valueAccessor());
        }
    };
});
