define([
    'knockout',
    'text!./template.html'
], (ko, template) => {

    /**
     * Массив атрибутов, которые передаются компоненту от родителя, и должны быть переданы шаблону
     * @type {string[]}
     */
    const MARKUP_PARAMS = ['textInput', 'enable', 'resattr'];
    const TYPES = Object.freeze({PASSWORD: 'password', TEXT: 'text'});

    const ViewModel = {
        createViewModel(params, componentInfo) {
            /**
             * Вставка templateNodes (содержимого тега <password-toggle-visibility-input>) в шаблон
             */
            componentInfo.templateNodes.forEach(node => componentInfo.element.firstChild.prepend(node));

            params.input = componentInfo.element.querySelector('input');
            if (params.input == null) {
                params.input = document.createElement('input');
                params.input.className = 'form-control';
                componentInfo.element.firstChild.prepend(params.input);
            }
            params.input.type = TYPES.PASSWORD;

            class ViewModel {
                constructor(params) {
                    MARKUP_PARAMS.forEach(attr => this[attr] = params[attr]);
                    this.input = params.input;
                    this.type = ko.observable(TYPES.PASSWORD);

                    this.iconClass = ko.computed(() => {
                        return 'fa-eye' + (this.type() === TYPES.TEXT ? '-slash' : '');
                    });
                }

                toggle() {
                    const newType = this.type() === TYPES.PASSWORD ? TYPES.TEXT : TYPES.PASSWORD;
                    this.type(newType);
                    this.input.type = newType;
                }
            }

            return new ViewModel(params);
        }
    };

    ko.components.register('password-toggle-visibility-input', {
        viewModel: ViewModel,
        template: template
    });
});
