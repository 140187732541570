define([
    'knockout',
    'knockoutmapping',
    'core/tel-common',
    'cleave.js',
    'text!./template.html'
], function (ko, komapping, telCommon, Cleave, template) {
    'use strict';

    var ViewModel = {
        createViewModel: function (params, componentInfo) {
            params.element = componentInfo.element;

            function ViewModel(params) {
                /**
                 * Объект HTML-элемента компонента.
                 * @type {Element}
                 */
                var input = params.element.querySelector('input.form-control');

                /**
                 * Экземпляр Cleave.
                 * @type {Object}
                 */
                var cleaveInstance = null;

                /**
                 * Функция установки значение в поле ввода телефона.
                 * @param newValue устанавливаемое значение
                 */
                var setValue = function (newValue) {
                    if (cleaveInstance) {
                        cleaveInstance.setRawValue(newValue);
                    }
                };

                /**
                 * Функция, возвращающая значение в поле ввода телефона.
                 * @returns {(string|null)} значение в поле ввода телефона
                 */
                var getValue = function () {
                    return cleaveInstance ? cleaveInstance.getRawValue() : null;
                };

                /**
                 * Известен ли формат телефона. Отвечает за то, будет ли отображено поле ввода телефона.
                 * Если формат неизвестен, то вместо поля ввода телефона
                 * отображается простое поле со значением "как есть".
                 * Наблюдаемый knockout-объект. Передается шаблону компонента.
                 * @type {boolean}
                 */
                this.knownFormat = ko.observable(false);

                /**
                 * Функция обновления значения модели.
                 */
                var updateObservable = function () {
                    var value = getValue();
                    var knownFormat = this.knownFormat();
                    if (knownFormat) {
                        if (value) {
                            params.observable(this.currentCountry.dialCode() + value);
                        } else {
                            params.observable(null);
                        }
                    } else {
                        params.observable(value);
                    }
                }.bind(this);

                /**
                 * Cleave конфигураия по умолчанию.
                 * @type {Object}
                 */
                var defaultCleaveConf = {
                    numericOnly: true
                };

                /**
                 * Обновляем модель при потере фокуса поля ввода телефона.
                 */
                input.addEventListener('blur', updateObservable);

                /**
                 * Функция формирования конфигурации Cleave по маске.
                 * Маске "999 999-9999" будет соответствовать
                 * объект со полями delimiters = [' ', '-'] и blocks = [3, 3, 4].
                 * @param mask маска
                 * @returns {{blocks: number[], delimiters: string[]}} конфигурация Cleave
                 */
                var cleaveConfByMask = function (mask) {
                    const delimiters = mask.match(/[^9]+/g);
                    const blocks = mask.match(/9+/g).map(item => item.length);
                    if (mask.charAt(0) !== '9') blocks.unshift(0);
                    if (mask.slice(-1) !== '9') blocks.push(0);
                    return {delimiters, blocks};
                };

                /**
                 * Установка маски в поле.
                 * @type {Function}
                 */
                var setMask = function (mask) {
                    if (cleaveInstance != null) {
                        cleaveInstance.destroy();
                    }
                    cleaveInstance = new Cleave(input, Object.assign({}, defaultCleaveConf, cleaveConfByMask(mask)));
                };

                /**
                 * Вспомогателное поле ввода.
                 * @type {Element}
                 */
                var helper = params.element.querySelector('input.tel-input-helper');

                /**
                 * Страна по умолчанию.
                 * @type {Object}
                 */
                var defaultCountry = telCommon.allCountries[0];

                /**
                 * Идентификатор, который присваивается вспомогательному полю ввода.
                 * Принимается как параметр компонента. Передается шаблону компонента.
                 * @type {string}
                 */
                this.inputId = params.inputId;

                /**
                 * Значение поля ввода телефона.
                 * Наблюдаемый knockout-объект. Передается шаблону компонента.
                 * @type {string}
                 */
                this.value = params.observable;

                /**
                 * Передача массива стран шаблону компонента.
                 * @type {Array}
                 */
                this.countries = telCommon.allCountries;

                /**
                 * Текущаяя страна. Отвечает за текущий код страны и плейсхолдер в поле ввода телефона.
                 * Наблюдаемый knockout-объект. Передается шаблону компонента.
                 * @type {Object}
                 */
                this.currentCountry = komapping.fromJS(defaultCountry);

                /**
                 * Далее идет получение наблюдаемого knockout-объекта из параметров компонента.
                 * После этого определяется страна телефона,
                 * устанавливается значение knownFormat, currentCountry, value,
                 * задается маска поля ввода телефона.
                 */
                var initialValue = params.observable();
                var countryByTel = initialValue ? telCommon.getCountryByTel(initialValue) : defaultCountry;
                if (countryByTel) {
                    this.knownFormat(true);
                    komapping.fromJS(countryByTel, {}, this.currentCountry);
                    setMask(this.currentCountry.mask());
                    if (initialValue) {
                        setValue(initialValue.substring(this.currentCountry.dialCode().length));
                    }
                } else {
                    this.knownFormat(false);
                    setValue(initialValue);
                }

                /**
                 * Обработка клика элемента выпадающего списка кодов телефонов.
                 * Обновляется currentCountry, value, задается маска поля ввода телефона.
                 * Передается шаблону компонента.
                 */
                this.countryClick = function (country) {
                    komapping.fromJS(country, {}, this.currentCountry);
                    setMask(country.mask);
                    updateObservable();
                }.bind(this);

                /**
                 * Обработка клика простого поля со значением телефона "как есть".
                 * Обновляется knownFormat, и в поле ввода телефона устанавливается фокус.
                 * Передается шаблону компонента.
                 */
                this.simpleInputClick = function () {
                    setValue('');
                    this.knownFormat(true);
                    input.focus();
                }.bind(this);

                /**
                 * Обработка клика вспомогательного поля.
                 * Вспомогательное поле скрыто с помощью CSS.
                 * Так как этому полю присвоен идентификатор inputId,
                 * то обработка клика происходит при клике на внешний (относительно компонента)
                 * label с атрибутом for равным inputId.
                 * При клике label ставится фокус на поле ввода телефона или
                 * эмулируется клик простого поля со значением телефона "как есть".
                 */
                helper.onclick = function () {
                    if (this.knownFormat()) {
                        input.focus();
                    } else {
                        this.simpleInputClick();
                    }
                }.bind(this);

                /**
                 * Обработка изменения телефона извне.
                 * Устанавливается значение knownFormat, currentCountry, value,
                 * задается маска поля ввода телефона.
                 */
                params.observable.subscribe(function (value) {
                    var countryByTel = value ? telCommon.getCountryByTel(value) : defaultCountry;
                    if (countryByTel) {
                        this.knownFormat(true);
                        komapping.fromJS(countryByTel, {}, this.currentCountry);
                        setMask(this.currentCountry.mask());
                        if (value) {
                            setValue(value.substring(this.currentCountry.dialCode().length));
                        } else {
                            setValue('');
                        }
                    } else {
                        this.knownFormat(false);
                        setValue(value);
                    }
                }.bind(this));
            }

            return new ViewModel(params);
        }
    };

    ko.components.register('tel-input', {
        viewModel: ViewModel,
        template: template
    });
});
