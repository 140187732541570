define(['knockout'], function (ko) {
    'use strict';

    // https://stackoverflow.com/a/9310752
    var escapeRegExp = function (text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    };

    ko.bindingHandlers.textInputWithAllowedChars = {
        init: function (element, valueAccessor) {
            var value = valueAccessor();
            var observable = value.observable;
            var allowedChars = value.allowedChars;
            var escapedAllowedChars = escapeRegExp(allowedChars);
            var regexp = new RegExp('[^' + escapedAllowedChars + ']', 'g');
            element.oninput = function () {
                observable(element.value.replace(regexp, ''));
                element.value = observable();
            };
        }
    };
});
