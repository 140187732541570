// See https://github.com/tfsjohan/Knockout-Localization-Binding for more details.

define([
	'knockout',
	'momentwithlocales',
], function (ko, momentwithlocales) {
	var locale = '';
	var resources = null;

	function getLocalizedText(binding) {
		if (binding === '') {
			return '';
		}

		if (typeof resources === "undefined") {
			throw "ko.localationbinding.getLocalizedText: resources object is not defined";
		}

		if (typeof locale === "undefined") {
			throw "ko.localationbinding.getLocalizedText: locale object is not defined";
		}

		// Accept both restext: 'mytext' and restext: { key: 'mytext' }
		if (Object.prototype.toString.call(binding) === '[object String]') {
			binding = { key: binding };
		}

		var key = binding.key;
		if (typeof key === 'function') {
			key = ko.utils.unwrapObservable(key());
		}
		var item = resources[key] || key;

		// Get text for locale or return an input string if text is not presented.
		var text = item[locale()] || key;

        if (typeof text === 'function') {
            text = text(binding.params);
        } else if (binding.params) {
            // Handle placeholders, where the localized text can be 'Hello #firstName!'.
            // For parameterized text the binding will look like restext: { key: 'hello', params: { firstName: firstNameObservable } }
			for (var replaceKey in binding.params) {
				var replacement = binding.params[replaceKey];
				if (typeof replacement === "function") {
					replacement = ko.utils.unwrapObservable(replacement());
				}
				text = text.replace("#" + replaceKey, replacement);
			}
		}

		return text;
	};

	function updateMoment(moment) {
		moment.locale(locale());
	}

	/* Переназначение переводов */
    function update(newResources) {
        Object.assign(resources, newResources);
    }

	return {
		init: function (_locale, _resources) {
			locale = _locale;
			resources = _resources;

			ko.bindingHandlers.restext = {
				update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
					var text = getLocalizedText(ko.utils.unwrapObservable(valueAccessor()));
					ko.bindingHandlers.text.update(
						element,
						function () { return text; },
						allBindingsAccessor,
						viewModel,
						context);
				}
			};

			ko.bindingHandlers.reshtml = {
				update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
					var text = getLocalizedText(ko.utils.unwrapObservable(valueAccessor()));
					ko.bindingHandlers.html.update(
						element,
						function () { return text; },
						allBindingsAccessor,
						viewModel,
						context);
				}
			};

			ko.bindingHandlers.reshref = {
				update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
					var text = getLocalizedText(ko.utils.unwrapObservable(valueAccessor()));
					ko.bindingHandlers.attr.update(
						element,
						function () { return { href: text }; },
						allBindingsAccessor,
						viewModel,
						context);
				}
			};

			ko.bindingHandlers.ressrc = {
				update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
					var text = getLocalizedText(ko.utils.unwrapObservable(valueAccessor()));
					ko.bindingHandlers.attr.update(
						element,
						function () { return { src: text }; },
						allBindingsAccessor,
						viewModel,
						context);
				}
			};

			ko.bindingHandlers.resattr = {
				update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {
					var json = ko.utils.unwrapObservable(valueAccessor());
					for (var attr in json) {
						var text = getLocalizedText(json[attr]);
						ko.bindingHandlers.attr.update(
							element,
							function () { var x = {}; x[attr] = text; return x; },
							allBindingsAccessor,
							viewModel,
							context);
					}
				}
			};

			//localize Moment fields in the expression
			ko.bindingHandlers.resmoment = {
				update: function(element, valueAccessor, allBindings, viewModel, bindingContext) {
					for (field in viewModel) {
						if (momentwithlocales.isMoment(viewModel[field]) &&
								valueAccessor.toString().match(field+"\\.")) {
							updateMoment(viewModel[field]);
						}
					}
					ko.utils.setTextContent(element, valueAccessor());
				}
			};

			ko.bindingHandlers.restext.getText = getLocalizedText;
		},
		getLocalizedText: getLocalizedText,
		update: update
	};
});