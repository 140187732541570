require('../components/action-command-editor/view-model');
require('../components/action-text-raw/view-model');
require('../components/color-picker/view-model');
require('../components/code-model-property/view-model');
require('../components/cron-editor/view-model');
require('../components/day-of-week-input/view-model');
require('../components/device-command-sender/view-model');
require('../components/device-location-editor/view-model');
require('../components/editable-text/view-model');
require('../components/hms-input/view-model');
require('../components/iot-chart/view-model');
require('../components/par01-control-list/view-model');
require('../components/pagination/view-model');
require('../components/select-dropdown/view-model');
require('../components/time-of-day-input/view-model');
require('../components/widget-device/view-model');
require('../components/widget-script/view-model');
require('../components/tel-input/view-model');
require('../components/password-toggle-visibility-input/view-model');
require('../components/ethernet-com-property/view-model');
require('../components/slider/view-model');
require('../components/splice-history-modal/view-model');
require('../components/qr-code/view-model');
require('../components/translate-if-object/view-model');
require('../components/translate-channel-title/view-model');
