define(['core/utils'], function (Utils) {
    'use strict';

    function Navigation(params) {
        this.layout = params.layout;
    }

    Navigation.prototype.reload = function (path, urlParams) {
        window.location.reload();
    };

    Navigation.prototype.to = function (path, urlParams) {
        if (Utils.isNgLink(path)) {
            window.location.href = path;
            return;
        }
        let fullPath = this.getLink(path, urlParams);
        if (fullPath !== window.location.hash) {
            location.hash = fullPath;
        }
        else {
            this.layout.to(path, urlParams);
        }
    };

    Navigation.prototype.getLink = function (path, urlParams) {
        return Utils.getNavigationLink(path, urlParams);
    };

    return Navigation;
});
