define([
    'knockout',
    'text!./template.html'
], (ko, template) => {

    class ViewModel {
        constructor(params) {
            this.property = params.property;
        }
    }

    ko.components.register('ethernet-com-property', {viewModel: ViewModel, template: template});
});
