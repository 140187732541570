define([
    'vue',
    'core/config',
    'globaleventbus',
    'core/Events',
    'text!./template.html',
    'core/add-device-feedback',
], function (Vue,
             config,
             EventBus,
             Events,
             template,
             FeedbackMode) {

    const TITLES_MODAL_FEEDBACK = FeedbackMode.TITLES_MODAL_FEEDBACK;

    Vue.component('v-add-device-feedback', {
        template: template,
        props: {
            feedbackModal: Object
        },

        data: function () {
            EventBus.dispatch(Events.types.NOTIFICATIONS_BETWEEN_PAGES.MODAL_VISIBILITY_CHANGE, 'target', true);
            return {
                mode: this.feedbackModal.feedbackMode,
                nodeId: this.feedbackModal.nodeId,
                title: TITLES_MODAL_FEEDBACK[this.feedbackModal.feedbackMode],
                FeedbackMode
            };
        },
        methods: {
            closeModal: function () {
                this.$emit('close');
            }
        }
    });
});
