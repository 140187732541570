// https://stackoverflow.com/a/16876013
define(['knockout'], function (ko) {
    'use strict';

    ko.bindingHandlers.tooltip = {
        init: function (element, valueAccessor) {
            const local = ko.utils.unwrapObservable(valueAccessor()),
                options = {};

            ko.utils.extend(options, ko.bindingHandlers.tooltip.options);
            ko.utils.extend(options, local);

            $(element).tooltip(options);

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
                $(element).tooltip('dispose');
            });
        },
        options: {
            placement: 'top',
            trigger: 'hover'
        }
    };
});
