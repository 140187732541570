// https://github.com/chartjs/Chart.js/issues/3915#issuecomment-367321378
define(function () {
    return {
        afterDatasetsUpdate: function (chartInstance) {
            chartInstance.config.data.datasets.forEach(data => {
                const meta = data._meta;
                const metaData = meta[Object.keys(meta)[0]];
                const bars = metaData.data;
                bars.forEach(bar => {
                    const model = bar._model;
                    if (model.base === model.y) {
                        model.y = model.base - 1;
                    }
                })
            });
        }
    }
});
