import ko from 'knockout';

import Pages from '../core/pages';
import Sidenav from './sidenav/init';
import Header from './header/init';
import PushWrapper from './push-wrapper/init';
import template from 'text!./template.html';

class Layout {

    constructor(params) {
        this.kocontainer = params.kocontainer;
        this.$container = document.createElement('div');
        this.alive();
    }

    alive() {
        const body = {
            $header: ko.observable(),
            $menu: ko.observable(),
            $pages: ko.observable(),
            $pushWrapper: ko.observable()
        };

        const dom = ko.utils.parseHtmlFragment(template);
        dom.forEach(e => this.$container.appendChild(e));

        try {
            ko.applyBindings(body, this.$container);
        } catch (e) {
            console.error(e);
            throw(e);
        }

        const divNew = document.createElement('div');
        divNew.setAttribute('style', 'width: 100%');

        const headerParams = {$container: divNew};

        Header.init(headerParams);

        const menuParams = {$container: document.createElement('div')};

        const pagesParams = {
            $container: document.createElement('div'),
            menu: Sidenav.init(menuParams)
        };

        this.pages = Pages.init(pagesParams);

        const pushWrapperParams = {$container: document.createElement('div')};
        PushWrapper.init(pushWrapperParams);

        body.$header().appendChild(headerParams.$container);
        body.$menu().appendChild(menuParams.$container);
        body.$pages().appendChild(pagesParams.$container);
        body.$pushWrapper().appendChild(pushWrapperParams.$container);

        this.kocontainer().appendChild(this.$container);
    }

    to(path, param) {
        this.pages.to(path, param);
    }

}

export default {
    init(params) {
        return new Layout(params);
    }
};
