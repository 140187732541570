define([
    'vue',
    'text!./template.html',
    './button-status'
], function (Vue, template, ButtonStatus) {
    Vue.component('v-stepper', {
        template,
        props: {
            config: Array
        },
        data: function () {
            return {ButtonStatus};
        }
    });
});
