define(['vue'], function (Vue) {
    Vue.directive('translate-attr', function (el, binding) {
        var localeChangesSubscriber = function () {
            Object.keys(binding.value).map(function (objectKey) {
                el.setAttribute(objectKey, T(binding.value[objectKey]));
            });
        };

        localeChangesSubscriber();
        window.globalState.locale.subscribe(localeChangesSubscriber);
    });
});
