define([
    'vue',
    'momentwithlocales',
    'text!./template.html'
], function (Vue, moment, template) {

    Vue.component('v-date-range-picker', {
        template: template,
        props: {
            from: String,
            to: String,
            format: String,
            fromPickerId: String,
            toPickerId: String,
            fromPickerLabel: String,
            toPickerLabel: String
        },
        data: function () {
            return {
                localFrom: this.from,
                localTo: this.to
            };
        },
        computed: {
            localRange() {
                return {from: this.localFrom, to: this.localTo};
            }
        },
        watch: {
            localRange(newLocalRange) {
                const momentFrom = moment(newLocalRange.from, this.format);
                const momentTo = moment(newLocalRange.to, this.format);
                const rangeValid = momentFrom.isBefore(momentTo);
                this.$emit('update:from', rangeValid ? newLocalRange.from : null);
                this.$emit('update:to', rangeValid ? newLocalRange.to : null);

                /** Позволяет увидеть, что диапазон неверный сразу же после выбора дат "до" и "после" */
                if (momentFrom.isValid() && momentTo.isValid()) {
                    setTimeout(() => this.$refs.observer.validate());
                }
            }
        }
    });

});
