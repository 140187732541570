define([
    'vue',
    'text!./template.html'
], function (Vue, template) {
    Vue.component('v-card', {
        template,
        props: {
            loading: Boolean
        }
    });
});
