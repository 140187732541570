define([
    './event-type-group',
    'deep-freeze'
], (EventTypeGroup, deepFreeze) => {

    class EventType {
        constructor(group, name) {
            this.group = group;
            this.name = name;
        }
    }

    return deepFreeze({
        ALARM_ADD: new EventType(EventTypeGroup.ALARM, 'ALARM_ADD'),
        ALARM_CHANGE: new EventType(EventTypeGroup.ALARM, 'ALARM_CHANGE'),
        ALARM_CIRCUIT_STATUS: new EventType(EventTypeGroup.ALARM, 'ALARM_CIRCUIT_STATUS'),
        ALARM_DATA_LIMIT: new EventType(EventTypeGroup.ALARM, 'ALARM_DATA_LIMIT'),
        ALARM_DATA_LIMIT_PER_TIME: new EventType(EventTypeGroup.ALARM, 'ALARM_DATA_LIMIT_PER_TIME'),
        ALARM_MUTE_ADD: new EventType(EventTypeGroup.ALARM, 'ALARM_MUTE_ADD'),
        ALARM_MUTE_CHANGE: new EventType(EventTypeGroup.ALARM, 'ALARM_MUTE_CHANGE'),
        ALARM_MUTE_REMOVE: new EventType(EventTypeGroup.ALARM, 'ALARM_MUTE_REMOVE'),
        CLEAR_ARCHIVE_HISTORY: new EventType(EventTypeGroup.NO_SEND, 'CLEAR_ARCHIVE_HISTORY'),
        CONTROLLER_ADD: new EventType(EventTypeGroup.CONTROLLER, 'CONTROLLER_ADD'),
        CONTROLLER_DATA: new EventType(EventTypeGroup.NO_SEND, 'CONTROLLER_DATA'),
        CONTROLLER_REMOVE: new EventType(EventTypeGroup.CONTROLLER, 'CONTROLLER_REMOVE'),
        CONTROLLER_REPLACE: new EventType(EventTypeGroup.CONTROLLER, 'CONTROLLER_REPLACE'),
        CONTROLLER_STATUS: new EventType(EventTypeGroup.CONTROLLER, 'CONTROLLER_STATUS'),
        CONTROLLER_IP_ADDRESS_CHANGE: new EventType(EventTypeGroup.CONTROLLER, 'CONTROLLER_IP_ADDRESS_CHANGE'),
        DATA_STATUS_CHANGE: new EventType(EventTypeGroup.DEVICE, 'DATA_STATUS_CHANGE'),
        DATA_STATUS_CHANGE_FOR_CORE: new EventType(EventTypeGroup.DEVICE, 'DATA_STATUS_CHANGE_FOR_CORE'),
        DEVICE_ASSOCIATION: new EventType(EventTypeGroup.DEVICE, 'DEVICE_ASSOCIATION'),
        DEVICE_ADD: new EventType(EventTypeGroup.DEVICE, 'DEVICE_ADD'),
        DEVICE_ADD_CORE: new EventType(EventTypeGroup.DEVICE, 'DEVICE_ADD_CORE'),
        DEVICE_ADD_NEED_DSK: new EventType(EventTypeGroup.DEVICE, 'DEVICE_ADD_NEED_DSK'),
        DEVICE_ATTEMPT_FAIL: new EventType(EventTypeGroup.DEVICE, 'DEVICE_ATTEMPT_FAIL'),
        DEVICE_CHANGE: new EventType(EventTypeGroup.DEVICE, 'DEVICE_CHANGE'),
        DEVICE_CHANGE_FOR_CORE: new EventType(EventTypeGroup.NO_SEND, 'DEVICE_CHANGE_FOR_CORE'),
        DEVICE_GET_ALL_BY_TYPE: new EventType(EventTypeGroup.DEVICE, 'DEVICE_GET_ALL_BY_TYPE'),
        DEVICE_LINK_GROUP: new EventType(EventTypeGroup.DEVICE, 'DEVICE_LINK_GROUP'),
        DEVICE_LOCATION_CHANGE: new EventType(EventTypeGroup.DEVICE, 'DEVICE_LOCATION_CHANGE'),
        DEVICE_RESULT_ALL_BY_TYPE: new EventType(EventTypeGroup.DEVICE, 'DEVICE_RESULT_ALL_BY_TYPE'),
        DEVICE_REMOVE: new EventType(EventTypeGroup.DEVICE, 'DEVICE_REMOVE'),
        DEVICE_STATUS_CHANGE: new EventType(EventTypeGroup.DEVICE, 'DEVICE_STATUS_CHANGE'),
        DEVICE_UNLINK_GROUP: new EventType(EventTypeGroup.DEVICE, 'DEVICE_UNLINK_GROUP'),
        DEVICE_ZWAVE_LOW_SECURITY: new EventType(EventTypeGroup.DEVICE, 'DEVICE_ZWAVE_LOW_SECURITY'),
        ELECTRIC_DEVICES_CHANGE: new EventType(EventTypeGroup.DEVICE, 'ELECTRIC_DEVICES_CHANGE'),
        ELECTRIC_DEVICE_CHANGE: new EventType(EventTypeGroup.DEVICE, 'ELECTRIC_DEVICE_CHANGE'),
        ENUM_ADD: new EventType(EventTypeGroup.NO_SEND, 'ENUM_ADD'),
        ENUM_EDIT: new EventType(EventTypeGroup.NO_SEND, 'ENUM_EDIT'),
        ENUM_REMOVE: new EventType(EventTypeGroup.NO_SEND, 'ENUM_REMOVE'),
        HOUSE_ADDRESS_CHANGE: new EventType(EventTypeGroup.HOUSE, 'HOUSE_ADDRESS_CHANGE'),
        HOUSE_CREATE: new EventType(EventTypeGroup.HOUSE, 'HOUSE_CREATE'),
        HOUSE_REMOVE: new EventType(EventTypeGroup.HOUSE, 'HOUSE_REMOVE'),
        HOUSE_REMOVE_REFLECT: new EventType(EventTypeGroup.HOUSE, 'HOUSE_REMOVE_REFLECT'),
        PROP_CHANGE: new EventType(EventTypeGroup.DEVICE, 'PROP_CHANGE'),
        REPORT_ADD: new EventType(EventTypeGroup.REPORT, 'REPORT_ADD'),
        REPORT_FULL_UPDATE: new EventType(EventTypeGroup.REPORT, 'REPORT_FULL_UPDATE'),
        REPORT_HISTORY_UPDATE: new EventType(EventTypeGroup.REPORT, 'REPORT_HISTORY_UPDATE'),
        REPORT_REMOVE: new EventType(EventTypeGroup.REPORT, 'REPORT_REMOVE'),
        REPORT_SETTINGS_TEMPLATE_UPDATE: new EventType(EventTypeGroup.REPORT, 'REPORT_SETTINGS_TEMPLATE_UPDATE'),
        REPORT_UPDATE: new EventType(EventTypeGroup.REPORT, 'REPORT_UPDATE'),
        RULE_REMOVE: new EventType(EventTypeGroup.RULE, 'RULE_REMOVE'),
        SCHEDULER_UPDATE: new EventType(EventTypeGroup.SCRIPT, 'SCHEDULER_UPDATE'),
        SCRIPT_REMOVE: new EventType(EventTypeGroup.SCRIPT, 'SCRIPT_REMOVE'),
        SCRIPT_START_EXECUTE: new EventType(EventTypeGroup.SCRIPT, 'SCRIPT_START_EXECUTE'),
        SCRIPT_CANCEL_EXECUTE: new EventType(EventTypeGroup.SCRIPT, 'SCRIPT_CANCEL_EXECUTE'),
        SCRIPT_FINISH_EXECUTE: new EventType(EventTypeGroup.SCRIPT, 'SCRIPT_FINISH_EXECUTE'),
        REBIND_DEVICE_EVENT: new EventType(EventTypeGroup.NO_SEND, 'REBIND_DEVICE_EVENT'),
        SEND_TO_CONTROLLER: new EventType(EventTypeGroup.NO_SEND, 'SEND_TO_CONTROLLER'),
        SESSION_CLOSE: new EventType(EventTypeGroup.NO_SEND, 'SESSION_CLOSE'),
        UPDATE_HISTORY: new EventType(EventTypeGroup.NO_SEND, 'UPDATE_HISTORY'),
        USER_REMOVE: new EventType(EventTypeGroup.NO_SEND, 'USER_REMOVE'),
        USER_ADD_TO_HOUSE: new EventType(EventTypeGroup.NO_SEND, 'USER_ADD_TO_HOUSE'),
        VARIABLE_ADD: new EventType(EventTypeGroup.HOUSE, 'VARIABLE_ADD'),
        VARIABLE_CHANGE: new EventType(EventTypeGroup.HOUSE, 'VARIABLE_CHANGE'),
        VARIABLE_EDIT: new EventType(EventTypeGroup.HOUSE, 'VARIABLE_EDIT'),
        VARIABLE_REMOVE: new EventType(EventTypeGroup.HOUSE, 'VARIABLE_REMOVE'),
        ZWAY_BACKUP_FINISH: new EventType(EventTypeGroup.NO_SEND, 'ZWAY_BACKUP_FINISH'),
        ZWAY_BACKUP_PROCESS: new EventType(EventTypeGroup.NO_SEND, 'ZWAY_BACKUP_PROCESS'),
        ZWAY_BACKUP_START: new EventType(EventTypeGroup.NO_SEND, 'ZWAY_BACKUP_START'),
        ZWAY_SEND_MESSAGE: new EventType(EventTypeGroup.NO_SEND, 'ZWAY_SEND_MESSAGE'),
        ZWAY_BACKUP_PROCESS_UPDATE: new EventType(EventTypeGroup.NO_SEND, 'ZWAY_BACKUP_PROCESS_UPDATE'),
        ANALYTIC_STATUS_CHANGE: new EventType(EventTypeGroup.ANALYTIC, 'ANALYTIC_STATUS_CHANGE'),
        LICENSE_INFO_UPDATE: new EventType(EventTypeGroup.LICENSE, 'LICENSE_INFO_UPDATE'),
        LICENSE_INFO_CREATE: new EventType(EventTypeGroup.LICENSE, 'LICENSE_INFO_CREATE'),
        LICENSE_INFO_REJECTED: new EventType(EventTypeGroup.LICENSE, 'LICENSE_INFO_REJECTED'),
        ARCHIVE_UPDATE_HISTORY: new EventType(EventTypeGroup.NO_SEND, 'ARCHIVE_UPDATE_HISTORY'),
        ARCHIVE_NEEDED_DEVICE: new EventType(EventTypeGroup.NO_SEND, 'ARCHIVE_NEEDED_DEVICE'),
        ARCHIVE_AVAILABLE: new EventType(EventTypeGroup.NO_SEND, 'ARCHIVE_AVAILABLE'),
        FLUSSONIC_NEED_AUTHENTICATION: new EventType(EventTypeGroup.NO_SEND, 'FLUSSONIC_NEED_AUTHENTICATION')
    });

});
