import deepFreeze from 'deep-freeze';

import {PageId} from './page-id';
import Utils from './utils';

export const menuItemsMap = deepFreeze({
    [PageId.Dashboard]: {
        link: 'dashboard',
        title: 'Dashboard',
        icon: 'house'
    },
    [PageId.Houses]: {
        link: '/ng/houses',
        title: 'HouseManagement',
        icon: 'houses'
    },
    [PageId.CurrentMeasures]: {
        link: '/ng/current-measures',
        title: 'CurrentMeasures',
        icon: 'house'
    },
    [PageId.Scripts]: {
        link: 'scripts',
        title: 'Scripts',
        icon: 'play'
    },
    [PageId.Devices]: {
        link: 'devices',
        title: 'Devices',
        icon: 'lightbulb'
    },
    [PageId.Meters]: {
        link: 'meters',
        title: 'Meters',
        icon: 'meters'
    },
    [PageId.Events]: {
        link: '/ng/events',
        title: 'Events',
        icon: 'calendar'
    },
    [PageId.Settings]: {
        link: '/ng/settings',
        title: 'Settings',
        icon: 'gear'
    },
    [PageId.Calculation]: {
        link: '/ng/calculation',
        title: 'Calculation',
        icon: 'reports'
    },
    [PageId.Reports]: {
        link: 'new-reports',
        title: 'Reports',
        icon: 'reports'
    },
    [PageId.EngineerDevices]: {
        link: 'new-devices',
        title: 'Devices',
        icon: 'lightbulb'
    },
    [PageId.Alarms]: {
        link: '/ng/alarms',
        title: 'Alarms',
        icon: 'alarm'
    },
    [PageId.Users]: {
        link: 'user-list',
        title: 'Abonents',
        icon: 'abonents'
    },
    [PageId.AdminEvents]: {
        link: '/ng/admin-events',
        title: 'Log',
        icon: 'history'
    },
    [PageId.Controllers]: {
        link: 'controllers',
        title: 'Controllers',
        icon: 'controllers'
    },
    [PageId.ScriptPatterns]: {
        link: 'patterns',
        title: 'ScriptPatterns',
        icon: 'shabl'
    },
    [PageId.Licenses]: {
        link: 'licenses',
        title: 'Licenses',
        icon: 'license'
    },
    [PageId.Firmwares]: {
        link: '/ko/firmwares',
        title: 'Firmwares',
        icon: 'gear'
    },
    [PageId.Organizations]: {
        link: '/ng/organizations',
        title: 'Organizations',
        icon: 'abonents'
    },
    [PageId.Cameras]: {
        link: '/ng/cameras',
        title: 'Cameras',
        icon: 'videocamera'
    },
    [PageId.Panels]: {
        link: '/ng/panels',
        title: 'Panels',
        icon: 'security'
    },
    [PageId.GuardObjects]: {
        link: '/ng/guard-objects',
        title: 'GuardObjects',
        icon: 'houses'
    },
    [PageId.GuardEvents]: {
        link: '/ng/guard-events',
        title: 'Log',
        icon: 'history'
    },
    [PageId.Roles]: {
        link: '/ng/roles',
        title: 'Roles',
        icon: 'abonents'
    },
    [PageId.UserStatistics]: {
        link: '/ng/user-statistics',
        title: 'Statistics',
        icon: 'chart-line'
    },
    [PageId.Plans]: {
        link: '/ng/plans',
        title: 'Plans',
        icon: 'plan'
    }
});

export const knockoutPagesIds = Object
    .keys(menuItemsMap)
    .filter(pageId => !Utils.isNgLink(menuItemsMap[pageId].link));

export function isKnockoutPageId(pageId) {
    return knockoutPagesIds.includes(pageId);
}
