define([
    'vue',
    'text!./template.html'
], function (Vue, template) {
    Vue.component('v-modal', {
        template: template,
        props: {
            loading: Boolean
        },
        computed: {
            hasFooter() {
                return this.$slots.footer;
            }
        }
    });
});
