define(function () {
    return {
        /**
         * Returns the current scroll position.
         */
        getScrollPosition() {
            return [window.scrollX, window.scrollY];
        },

        /**
         * Sets the scroll position.
         */
        scrollToPosition(position) {
            if (position) {
                window.scrollTo(position[0], position[1]);
            }
        }
    };
});
