define([
    'knockout',
    'text!./template.html'
], function (ko, template) {
    function ViewModel(params) {
        this.value = params.value;
        this.days = ko.observableArray();

        var i, val = this.value();
        for (i = 0; i < 7; i++) {
            this.days.push(ko.observable(false));
        }
        for (i = 0; i < val.length; i++) {
            this.days()[parseInt(val[i]) - 1](true);
        }

        this.days().forEach(function (day) {
            day.subscribe(function () {
                var result = '';
                this.days().forEach(function (day, index) {
                    if (day()) {
                        result += index + 1;
                    }
                    this.value(result);
                }, this);
            }.bind(this));
        }, this);

        this.buttonClickHandler = function (index) {
            this.days()[index](!this.days()[index]());
        };
    }

    ko.components.register('day-of-week-input', {
        viewModel: ViewModel,
        template: template
    });
});
