define([
    'rx-stomp',
    'sockjs-client',
    'core/local-storage-service',
    'core/config',
    'core/utils',
    'rxjs',
    'rxjs/operators'
], (rxStomp, SockJS, localStorageService, config, Utils, rxjs, rxjsOperators) => {

    const RxStompState = Object.freeze({
        CONNECTING: 0,
        OPEN: 1,
        CLOSING: 2,
        CLOSED: 3
    });

    const reconnectDelay = 2000;

    const getDestination = eventType => `/user${eventType.group}/${Utils.snakeToCamel(eventType.name)}`;

    class EventService {

        constructor() {

            this.rxStomp = new rxStomp.RxStomp();

            this.config = {
                webSocketFactory: () => new SockJS(localStorageService.getWsAddress()),

                // Wait in milliseconds before attempting auto reconnect
                // Set to 0 to disable
                // Typical value 500 (500 milli seconds)
                reconnectDelay,

                // How often to heartbeat?
                // Interval in milliseconds, set to 0 to disable
                heartbeatIncoming: 0, // Typical value 0 - disabled
                heartbeatOutgoing: 20000 // Typical value 20000 - every 20 seconds
            };

            this.closeEventStatus$ = new rxjs.Subject();

            /**
             * Обработка ошибки подключения с невалидным токеном.
             */
            this.rxStomp.stompErrors$
                .pipe(rxjsOperators.filter(frame => frame.headers.message.includes('error="invalid_token"')))
                .subscribe(() => this.reconnectWithNewToken());

            /**
             * При каждом открытии соединения создается новый экземпляр WebSocket.
             * Поэтому приходится каждый раз навешивать нативный обработчик onclose,
             * в котором в Subject closeEventStatus$ отправляется статус закрытия сокета.
             */
            this.rxStomp.connectionState$
                .pipe(rxjsOperators.filter(state => state === RxStompState.OPEN))
                .subscribe(() => {
                    this.rxStomp.stompClient.webSocket.onclose = event => this.closeEventStatus$.next(event.code);
                });

            /**
             * Обработка закрытия сокета.
             */
            this.closeEventStatus$.subscribe(code => {
                if (code === 1011) {
                    this.reconnectWithNewToken();
                } else {
                    setTimeout(() => this.reconnect(), reconnectDelay);
                }
            });

            this.online$ = this.rxStomp.connectionState$.pipe(
                rxjsOperators.map(state => state === RxStompState.OPEN) // делим на два класса - true и false
            );

        }

        connect() {
            const accessToken = localStorageService.getAccessToken();
            this.config.connectHeaders = {accessToken};
            this.rxStomp.configure(this.config);
            this.rxStomp.activate();
        }

        disconnect() {
            this.rxStomp.deactivate();
        }

        reconnect() {
            this.disconnect();
            this.connect();
        }

        reconnectWithNewToken() {
            this.disconnect();
            config.renewToken().next(success => {
                if (success) {
                    this.connect();
                } else {
                    localStorageService.removeAccessToken();
                    localStorageService.removeRefreshToken();
                    window.location = 'signin/signin.html';
                }
            });
        }

        on(eventType) {
            return this.rxStomp.watch(getDestination(eventType)).pipe(
                rxjsOperators.map(message => JSON.parse(message.body))
            );
        }

        subscribe(eventType, callback, takeUntilNotifier) {
            let event$ = this.on(eventType);
            if (takeUntilNotifier) {
                event$ = event$.pipe(rxjsOperators.takeUntil(takeUntilNotifier));
            }
            return event$.subscribe(callback);
        }

    }

    return new EventService();

});
