define([
    'knockout',
    'text!./template.html'
], function (ko, template) {
    var COLON = ':';

    var insertColon = function (value) {
        return value.slice(0, 2) + COLON + value.slice(2);
    };

    var removeColons = function (value) {
        return value.split(COLON).join('');
    };

    function ViewModel(params) {
        this.value = params.value;
        this.inputWidth = '80px';
        this.TILDE = '~';

        this.timeStart = ko.pureComputed({
            read: function () {
                return insertColon(this.value().split(this.TILDE)[0]);
            },
            write: function (newValue) {
                this.value(removeColons(newValue) + this.TILDE + removeColons(this.timeEnd()));
            },
            owner: this
        });

        this.timeEnd = ko.pureComputed({
            read: function () {
                return insertColon(this.value().split(this.TILDE)[1]);
            },
            write: function (newValue) {
                this.value(removeColons(this.timeStart()) + this.TILDE + removeColons(newValue));
            },
            owner: this
        });
    }

    ko.components.register('time-of-day-input', {
        viewModel: ViewModel,
        template: template
    });
});
