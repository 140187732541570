define([
    'knockout',
    'knockoutmapping',
    'text!./template.html'
], function (ko, komapping, template) {
    var ViewModel = {
        createViewModel: function (params, componentInfo) {
            function ViewModel(params) {
                var TYPES = Object.freeze({RGB: 'RGB', HEX: 'HEX'});
                var DEFAULT_TYPE = TYPES.RGB;

                var self = this;

                self.type = params.type || DEFAULT_TYPE;

                if (self.type === TYPES.RGB && ko.isObservable(params.value)) {
                    self.value = params.value();
                } else {
                    self.value = params.value;
                }

                if (!(self.type in TYPES)) {
                    var errorMessage = 'Неправильный тип {type}! Поддерживаемые типы: {types}.'
                        .substitute({type: self.type, types: Object.keys(TYPES).join(', ')});
                    throw new Error(errorMessage);
                }

                var rgbObjToStr = function (rgbObj) {
                    return 'rgb({r}, {g}, {b})'.substitute(rgbObj);
                };

                self.stringValue = ko.computed(function () {
                    return self.type === TYPES.RGB ? rgbObjToStr(komapping.toJS(self.value)) : self.value();
                });

                componentInfo.element.classList.add('clickable');

                $(componentInfo.element).spectrum({
                    beforeShow: function () {
                        $(this).spectrum('set', self.stringValue());
                    },
                    change: function (color) {
                        var newColor;
                        if (self.type === TYPES.RGB) {
                            newColor = color.toRgb();
                            self.value.r(newColor.r.toString());
                            self.value.g(newColor.g.toString());
                            self.value.b(newColor.b.toString());
                        } else {
                            newColor = color.toHexString();
                            self.value(newColor);
                        }
                    },
                    showInitial: true,
                    showInput: true,
                    preferredFormat: self.type.toLowerCase()
                });
            }

            return new ViewModel(params);
        }
    };

    ko.components.register('color-picker', {
        viewModel: ViewModel,
        template: template
    });
});
