define([
    'knockout',
    'knockoutmapping',
    'text!./template.html'
], function (ko, komapping, template) {
    'use strict';

    class ViewModel {
        constructor(params) {
            this.value = ko.observable(komapping.toJS(params.value));
            if (typeof (params.value) === 'function') {
                this.valueSubscribe = params.value.subscribe(newValue => this.value(komapping.toJS(newValue)));
            }
        }

        dispose() {
            if (this.valueSubscribe) {
                this.valueSubscribe.dispose();
            }
        }
    }

    ko.components.register('translate-if-object', {viewModel: ViewModel, template: template});
});
