define(['knockout'], function (ko) {
    'use strict';

    ko.bindingHandlers.stringChecked = {
        init: function (element, valueAccessor) {
            var value = valueAccessor();
            var valueUnwrapped = ko.unwrap(value);
            element.checked = valueUnwrapped === 'true';
            element.onclick = function () {
                value(element.checked.toString());
            };

            value.subscribe(newValue => element.checked = newValue === 'true');
        }
    };
});
