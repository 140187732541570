define(function () {
    'use strict';

    /**
     * Массив поддерживаемых телефонных кодов с названиями стран и маской ввода
     * @type {Array} массив поддерживаемых телефонных кодов
     */
    var allCountries = [
        [
            'Россия, Казахстан',
            '7',
            ' (999) 999-99-99'
        ],
        [
            'Украина',
            '380',
            ' (99) 999-99-99'
        ],
        [
            'Белоруссия',
            '375',
            ' (99) 999-99-99'
        ],
        [
            'Иран',
            '98',
            ' (999) 999-9999'
        ],
        [
            'Чехия',
            '420',
            ' (999) 999-999'
        ],
        [
            'США',
            '1',
            ' (999) 999-9999'
        ],
        [
            'Азербайджан',
            '994',
            '-99-999-99-99'
        ],
        [
            'Армения',
            '374',
            '-99-999-999'
        ],
        [
            'Киргизия',
            '996',
            ' (999) 999-999'
        ],
        [
            'Молдавия',
            '373',
            '-9999-9999'
        ],
        [
            'Таджикистан',
            '992',
            '-99-999-9999'
        ],
        [
            'Узбекистан',
            '998',
            '-99-999-9999'
        ],
        [
            'Туркменистан',
            '993',
            '-9-999-9999'
        ]
    ];

    /**
     * Функция, принимающая маску ввода телефона, и, возвращающая другую функцию,
     * которая во время вызова в свою очередь выполняет форматирование телефонного номера в соответсвии с маской
     * @param mask маска ввода телефона
     * @returns {function} Функция форматирования телефона
     */
    var getFormatFunctionByMask = function (mask) {
        var ninesArr = mask.match(/9+/g);
        var regexpStem = ninesArr.map(function (nines) {
            return '(\\d{' + nines.length + '})';
        }).join('');
        var regexp = new RegExp('^' + regexpStem + '.*');

        var i = 0;
        var newStr = mask.replace(/9+/g, function () {
            return '$' + (++i);
        });

        return function (value) {
            return value.replace(regexp, newStr);
        }
    };

    /**
     * Функция, принимающая телефонный код и маску ввода телефона, и, возвращающая другую функцию,
     * которая во время вызова в свою очередь выполняет проверку телефонного номера на соответствие коду и маске
     * @param dialCode телефонный код
     * @param mask маска ввода телефона
     * @returns {function} Функция проверки телефонного номера на соответствие коду и маске
     */
    var getTestFunction = function (dialCode, mask) {
        return function (value) {
            return value.startsWith(dialCode) &&
                (removeNonNumericChars(mask).length + dialCode.length) === removeNonNumericChars(value).length;
        }
    };

    /**
     * Преобразование дочерних массивов allCountries
     * в объекты вида {name: string, dialCode: string, mask: string, format: function}
     */
    for (var i = 0; i < allCountries.length; i++) {
        var c = allCountries[i];
        allCountries[i] = {
            name: c[0],
            dialCode: c[1],
            mask: c[2],
            format: getFormatFunctionByMask(c[2]),
            test: getTestFunction(c[1], c[2])
        };
    }

    /**
     * Возвращает объект с подходящей страной по номеру телефона
     * @param tel номер телефона
     * @returns {(Object|undefined)} Объект страны или undefined в случае отсутсвия подходящей страны
     */
    var getCountryByTel = function (tel) {
        if (tel == null) {
            return;
        }
        for (var i = 0; i < allCountries.length; i++) {
            var c = allCountries[i];
            if (c.test(tel)) {
                return allCountries[i];
            }
        }
    };

    /**
     * Удаляет нечисловые символы из строки
     * @param str входная строка
     * @returns {string} Строка str без нечисловых символов
     */
    var removeNonNumericChars = function (str) {
        return str.replace(/\D/g, '');
    };

    return {
        allCountries: allCountries,
        getCountryByTel: getCountryByTel,
        removeNonNumericChars: removeNonNumericChars
    };
});
