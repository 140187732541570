export const PageId = Object.freeze({
    Dashboard: 'view.dashboard',
    Houses: 'view.houses',
    CurrentMeasures: 'view.currentMeasures',
    Scripts: 'view.scripts',
    Devices: 'view.devices',
    Meters: 'view.meters',
    Events: 'view.events',
    Settings: 'view.settings',
    Calculation: 'view.calculation',
    Reports: 'view.reports',
    EngineerDevices: 'view.engineerDevices',
    Alarms: 'view.alarms',
    Users: 'view.users',
    UserStatistics: 'view.userStatistics',
    AdminEvents: 'view.adminEvents',
    Controllers: 'view.controllers',
    ScriptPatterns: 'view.scriptPatterns',
    Licenses: 'view.licenses',
    Firmwares: 'view.firmwares',
    Organizations: 'view.organizations',
    Cameras: 'view.cameras',
    Panels: 'view.panels',
    GuardObjects: 'view.guardObjects',
    GuardEvents: 'view.guardEvents',
    Roles: 'view.roles',
    Plans: 'view.plans'
});

export function isPageId(value) {
    return Object.values(PageId).includes(value);
}
