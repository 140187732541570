import {PageId} from './page-id';
import {Permission} from './permission';
import User from './user';

/**
 * Здесь описаны правила, по которым определяется доступность той или иной страницы.
 * requiredPermissions - для доступа требуется один из указанных Permissions.
 * requiredPageIds - для доступа требуется один из указанных PageIds.
 * Если указаны оба поля, то для доступа достаточно чего-то одного.
 * null - страница доступна всем.
 */
const routes = {
    'bulk-add-devices': {
        requiredPageIds: [PageId.Devices, PageId.EngineerDevices],
        requiredPermissions: [Permission.admin]
    },
    'controllers': {
        requiredPageIds: [PageId.Controllers]
    },
    'dashboard': {
        requiredPageIds: [PageId.Dashboard],
        requiredPermissions: [Permission.admin]
    },
    'devices': {
        requiredPageIds: [PageId.Devices],
        requiredPermissions: [Permission.admin]
    },
    'devices/device': {
        requiredPageIds: [PageId.Devices, PageId.EngineerDevices],
        requiredPermissions: [Permission.admin, Permission.needDevicePage]
    },
    'events': {
        requiredPageIds: [PageId.Events, PageId.AdminEvents]
    },
    'help': null,
    'house-management/house/variables': {
        requiredPageIds: [PageId.Houses],
        requiredPermissions: [Permission.admin, Permission.needHouseVariablesPage]
    },
    'licenses': {
        requiredPageIds: [PageId.Licenses]
    },
    'meters': {
        requiredPageIds: [PageId.Meters],
        requiredPermissions: [Permission.admin]
    },
    'meters/meter-complex': {
        requiredPageIds: [PageId.Meters],
        requiredPermissions: [Permission.admin]
    },
    'new-devices': {
        requiredPageIds: [PageId.EngineerDevices]
    },
    'new-reports': {
        requiredPageIds: [PageId.Reports],
        requiredPermissions: [Permission.admin]
    },
    'not-found': null,
    'patterns': {
        requiredPageIds: [PageId.ScriptPatterns],
        requiredPermissions: [Permission.admin]
    },
    'patterns/pattern': {
        requiredPageIds: [PageId.ScriptPatterns],
        requiredPermissions: [Permission.admin]
    },
    'scripts': {
        requiredPageIds: [PageId.Scripts],
        requiredPermissions: [Permission.admin]
    },
    'scripts/script': {
        requiredPageIds: [PageId.Scripts],
        requiredPermissions: [Permission.admin]
    },
    'scripts/script-edit': {
        requiredPageIds: [PageId.Scripts],
        requiredPermissions: [Permission.admin]
    },
    'user-list': {
        requiredPageIds: [PageId.Users]
    },
    'user-list/user': {
        requiredPageIds: [PageId.Users]
    }
};

class RoutesGuard {

    /**
     * Метод определяет доступность страницы.
     * Логика такая же, как в методе AuthGuard.canActivate() в IoT-web-mc.
     * @param path - путь страницы
     * @returns boolean - доступность страницы
     */
    isAllowedPath(path) {
        const route = routes[path];

        if (route == null) {
            return true;
        }

        const {requiredPageIds, requiredPermissions} = route;

        if (requiredPageIds == null && requiredPermissions == null) {
            return true;
        } else if (requiredPageIds == null) {
            return requiredPermissions == null || User.hasAnyPermission(requiredPermissions);
        } else if (requiredPermissions == null) {
            return User.hasAnyPage(requiredPageIds);
        } else {
            const hasPageId = User.hasAnyPage(requiredPageIds);
            const hasPermission = User.hasAnyPermission(requiredPermissions);
            return hasPageId || hasPermission;
        }
    }

}

export default new RoutesGuard();
