define(['vue'], Vue => {
    const timeoutDelay = 50;
    Vue.directive('bootstrap-select', {
        bind: function (el) {
            setTimeout(function () {
                $(el).selectpicker('refresh');
            }, timeoutDelay);
        },
        update: function (el) {
            setTimeout(function () {
                $(el).selectpicker('refresh');
            }, timeoutDelay);
        },
        inserted: function (el) {
            $(el).selectpicker({noneSelectedText: T('NoneSelected')});
        },
        unbind: function (el) {
            $(el).selectpicker('destroy');
        }
    });
});
