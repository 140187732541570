define([
    'vue',
    'vee-validate',
    'vee-validate/dist/locale/ru'
], function (Vue, VeeValidate, VeeValidateLocaleRu) {

    /**
     * Конфигурация VeeValidate.
     * Английский словарь доступен по умолчанию,
     * поэтому в конфигурации описываем только русский.
     */
    Vue.use(VeeValidate, {
        locale: window.globalState.locale(),
        dictionary: {
            'ru': VeeValidateLocaleRu
        }
    });

    /**
     * Изменение локали VeeValidate происходит по таймауту,
     * потому что VeeValidate использует атрибут data-vv-as
     * для формирования текста ошибки, а если значение этого
     * атрибута задается с помощью директивы v-translate-attr,
     * то при переключении языка значение data-vv-as не успевает измениться,
     * и VeeValidate использует старое знаение, из-за чего
     * все сообщение может быть на одном языке, а название поля на другом.
     */
    window.globalState.locale.subscribe(newLocale => {
        setTimeout(() => VeeValidate.Validator.localize(newLocale), 50);
    });

    /** Регистрация компонентов */
    Vue.component('ValidationProvider', VeeValidate.ValidationProvider);
    Vue.component('ValidationObserver', VeeValidate.ValidationObserver);

});
