define([
    'vue',
    'core/config',
    'text!./template.html',
    'core/utils',
    'core/websocket/event-service',
    'core/websocket/event-type',
], function (Vue,
             config,
             template,
             Utils,
             EventService,
             EventType) {
    function EnumValueModel() {
        this.id = Utils.generateRandomId();
        this.title = '';
        this.system = false;
        this.isNew = true;
    }

    Vue.component('v-house-variable-editor-extended', {
        template: template,
        props: {variable: Object, houseId: String},
        data: function () {
            return {
                queryInProgress: false,
                editingVariable: Object.clone(this.variable),
                originalJson: JSON.stringify(this.variable),
                availableTypes: ['ENUMERATION', 'TEXT', 'INTEGER', 'FLOAT'],
                eventSubscriptions: []
            }
        },
        computed: {
            changed: function () {
                return this.originalJson !== JSON.stringify(this.editingVariable);
            }
        },
        created: function () {

            const onVariableChange = data => {
                this.editingVariable.value = data.value;
                this.originalJson = JSON.stringify(this.editingVariable);
            };

            const onVariableEdit = data => {
                if (this.variable.id === data.variableId) {
                    config.getHouseVariable({
                        houseId: this.houseId,
                        variableId: this.editingVariable.id
                    }).next(variable => {
                        this.editingVariable = variable;
                        this.originalJson = JSON.stringify(this.editingVariable);
                    });
                }
            };

            this.eventSubscriptions.push(
                EventService.subscribe(EventType.VARIABLE_CHANGE, this.onVariableChange),
                EventService.subscribe(EventType.VARIABLE_EDIT, this.onVariableEdit)
            );

        },
        methods: {
            save: function () {
                var self = this;
                if (self.editingVariable.isNew) {
                    config.addHouseVariable({
                        houseId: self.houseId,
                        variable: self.editingVariable
                    }).next(function () {
                        self.editingVariable.isNew = false;
                        self.originalJson = JSON.stringify(self.editingVariable);
                    }).error(function (e) {
                        console.error(e);
                        alert(T('AddHouseVariableError'));
                    });
                } else {
                    config.updateHouseVariable({
                        houseId: self.houseId,
                        variableId: self.editingVariable.id,
                        newValue: self.editingVariable
                    }).next(function (variable) {
                        self.editingVariable = variable;
                        self.originalJson = JSON.stringify(self.editingVariable);
                    }).error(function (e) {
                        console.error(e);
                        alert(T('UpdateHouseVariableError'));
                    });
                }
            },
            cancel: function () {
                this.editingVariable = JSON.parse(this.originalJson);
            },
            changeType: function (type) {
                this.editingVariable.value = null;
                this.editingVariable.type = type;
            },
            remove: function () {
                if (this.editingVariable.isNew) {
                    this.$emit('remove-house-variable', this.variable.id);
                } else if (confirm(T('ConfirmRemoveHouseVariable'))) {
                    config.removeHouseVariable({
                        houseId: this.houseId,
                        variableId: this.variable.id
                    }).next(function () {
                        this.$emit('remove-house-variable', this.variable.id);
                    }.bind(this)).error(function (e) {
                        console.error(e);
                        alert(T('RemoveHouseVariableError'));
                    });
                }
            },
            addEnumValue: function () {
                this.editingVariable.enumValues.push(new EnumValueModel());
                if (this.editingVariable.enumValues.length === 1) {
                    this.editingVariable.value = this.editingVariable.enumValues[0].id;
                }
            },
            removeEnumValue: function (enumValueId) {
                this.editingVariable.enumValues.some(function (enumValue, index) {
                    if (enumValue.id === enumValueId) {
                        this.editingVariable.enumValues.splice(index, 1);
                        return true;
                    }
                    return false;
                }, this);
            }
        },
        destroyed() {
            this.eventSubscriptions.forEach(sub => sub.unsubscribe());
        }
    });
});
