define(['core/local-storage-service', 'jsdeferred'], function (localStorageService) {
    'use strict';

    var root = localStorage.getItem('apiAddress');

    let renewDeferredOnce = null;

    var renewToken = function () {
        if (renewDeferredOnce != null) {
            return renewDeferredOnce;
        } else {
            renewDeferredOnce = Deferred();
        }

        var USERNAME = 'web-client';
        var PASSWORD = 'password';
        $.ajax({
            type: 'POST',
            url: root + '/oauth/token',
            headers: {'Authorization': 'Basic ' + btoa(USERNAME + ':' + PASSWORD)},
            data: {grant_type: 'refresh_token', refresh_token: localStorageService.getRefreshToken()},
            success: function (data) {
                localStorageService.setAccessToken(data.access_token);
                localStorageService.setRefreshToken(data.refresh_token);
                console.debug('Access token обновлен');
                renewDeferredOnce.call(true);
                renewDeferredOnce = null;
            },
            error: function () {
                renewDeferredOnce.call(false);
            }
        });
        return renewDeferredOnce;
    };

    function redirectToLoginPage() {
        const returnUrl = window.location.pathname + window.location.hash;
        window.location = `signin/signin.html?returnUrl=${encodeURIComponent(returnUrl)}`;
    }

    function ajax(method, path, data, needSuccessArgs, binary, isFormData) {
        if (localStorageService.getAccessToken() === null) {
            redirectToLoginPage();
        }

        var d = Deferred();
        const ajaxConfig = {
            method: method,
            contentType: 'application/json',
            url: root + path,
            data: method === 'GET' || isFormData ? data : JSON.stringify(data),
            headers: {
                'Authorization': 'Bearer ' + localStorageService.getAccessToken(),
            },
            success: function (data, textStatus, request) {
                const args = needSuccessArgs ? {data, textStatus, request} : data;
                d.call(args);
            },
            error: function (jqXHR, textStatus) {
                if (jqXHR.status == 401) {
                    var thisAjaxCall = this;
                    renewDeferredOnce = renewToken().next(function (success) {
                        if (success) {
                            thisAjaxCall.headers.Authorization = 'Bearer ' + localStorageService.getAccessToken();
                            $.ajax(thisAjaxCall);
                        } else {
                            localStorageService.removeAccessToken();
                            localStorageService.removeRefreshToken();
                            redirectToLoginPage();
                        }
                    });
                } else {
                    d.fail(jqXHR, textStatus);
                }
            }
        };
        if (binary) {
            ajaxConfig.dataType = 'binary';
        }
        if (isFormData) {
            ajaxConfig.processData = false;
            ajaxConfig.contentType = false;
        }
        $.ajax(ajaxConfig);
        return d;
    };

    function get(path, data, needSuccessArgs, binary) {
        return ajax('GET', path, data, needSuccessArgs, binary);
    };
    function post(path, data, needSuccessArgs, binary, isFormData) {
        return ajax('POST', path, data, needSuccessArgs, binary, isFormData);
    };
    function del(path, needSuccessArgs, binary, isFormData) {
        return ajax('DELETE', path, null, needSuccessArgs, binary, isFormData);
    };
    function put(path, data, needSuccessArgs, binary, isFormData) {
        return ajax('PUT', path, data, needSuccessArgs, binary, isFormData);
    };
    function patch(path, data, needSuccessArgs, binary, isFormData) {
        return ajax('PATCH', path, data, needSuccessArgs, binary, isFormData);
    }

    return {
        renewToken: renewToken,

        get: get,

        getVersion: function () {
            return get('/version');
        },

        getHouses: function () {
            return get('/houses');
        },
        getHouse: function (params) {
            params = params || {};
            var houseId = params.houseId;
            return get('/houses/{houseId}'.substitute({houseId: houseId}));
        },
        getHouseCurrentStates: function (params) {
            params = params || {};
            var houseId = params.houseId;
            const filter = {
                apartment: params.apartment,
                deviceType: params.deviceType,
                controllerId: params.controllerId
            };
            return get('/houses/{houseId}/currentStates'.substitute({houseId: houseId}), filter);
        },
        addHouse: function (params) {
            return post('/houses', params);
        },
        removeHouse: function (params) {
            params = params || {};
            return del('/houses/{houseId}'.substitute({houseId: params.id}));
        },
        updateHouse: function (params) {
            params = params || {};
            return post('/houses/{houseId}'.substitute({houseId: params.houseId}), {title: params.title, address: params.address, apartment: params.apartment});
        },
        getUsersAccessOfHouse: function (params) {
            params = params || {};
            var houseId = params.houseId;
            return get('/houses/{houseId}/access'.substitute({houseId: houseId}));
        },

        /**
         * Получить список доступных домов и уровень доступа к ним для пользователя
         */
        getUserAccess(params) {
            params = params || {};
            const userId = params.userId;
            return get(`/users/${userId}/access`);
        },

        /** Получить уровень доступа пользователя к конкретному дому */
        getUserAccessByHouse: function (params) {
            params = params || {};
            return get('/users/{userId}/access/{houseId}'.substitute({userId: params.userId, houseId: params.houseId}));
        },

        /** Создать или изменить уровень доступа пользователя к дому */
        setUserAccessToHouse: function (params) {
            const {username, data} = params;
            return post(`/users/${username}/access`, data);
        },

        deleteUserAccess: function(params) {
            params = params || {};
            return post('/users/{userId}/access/delete'.substitute({userId: params.userId}), {houseIds: params.houseIds});
        },
        addHouseVariable: function (params) {
            params = params || {};
            var houseId = params.houseId, variable = params.variable;
            return post('/houses/{houseId}/vars'.substitute({houseId: houseId}), variable);
        },
        updateHouseVariable: function (params) {
            params = params || {};
            var houseId = params.houseId, variableId = params.variableId, newValue = params.newValue;
            return post('/houses/{houseId}/vars/{variableId}'.substitute({
                houseId: houseId,
                variableId: variableId
            }), newValue);
        },
        getHouseVariables: function (params) {
            params = params || {};
            var houseId = params.houseId;
            return get('/houses/{houseId}/vars'.substitute({houseId: houseId}));
        },
        getHouseVariable: function (params) {
            params = params || {};
            var houseId = params.houseId;
            var variableId = params.variableId;
            return get('/houses/{houseId}/vars/{variableId}'.substitute({houseId: houseId, variableId: variableId}));
        },
        switchMode: function (params) {
            params = params || {};
            var houseId = params.houseId, newModeId = params.newModeId;
            return post('/houses/{houseId}/vars/{variableId}'.substitute({houseId: houseId, variableId: 'houseMode'}), {value: newModeId});
        },
        addMode: function (params) {
            params = params || {};
            var houseId = params.houseId, title = params.title;
            return post('/houses/{houseId}/vars/{variableId}/values'.substitute({houseId: houseId, variableId: 'houseMode'}), {title: title});
        },
        renameMode: function (params) {
            params = params || {};
            var houseId = params.houseId, modeId = params.modeId, title = params.title;
            return post('/houses/{houseId}/vars/{variableId}/values/{valueId}'.substitute({houseId: houseId, variableId: 'houseMode', valueId: modeId}), {title: title});
        },
        removeMode: function (params) {
            params = params || {};
            var houseId = params.houseId, valueId = params.valueId;
            return del('/houses/{houseId}/vars/{variableId}/values/{valueId}'.substitute({houseId: houseId, variableId: 'houseMode', valueId: valueId}));
        },
        removeHouseVariable: function (params) {
            params = params || {};
            var houseId = params.houseId, variableId = params.variableId;
            return del('/houses/{houseId}/vars/{variableId}'.substitute({houseId: houseId, variableId: variableId}));
        },
        getDashboard: function (params) {
            params = params || {};
            var houseId = params.houseId;
            return get('/houses/{houseId}/dashboard'.substitute({houseId: houseId}));
        },
        setDashboard: function(params) {
            params = params || {};
            var houseId = params.houseId, dashboard = params.dashboard;
            return post('/houses/{houseId}/dashboard'.substitute({houseId: houseId}), dashboard);
        },

        getAllAddresses() {
            return get('/houseAddress');
        },

        getControllers: function (params) {
            params = params || {};
            var parameters = {
                offset: encodeURIComponent(params.offset === undefined ? '0' : params.offset.toString()),
                limit: encodeURIComponent(params.limit === undefined ? '' : params.limit.toString()),
                mac: encodeURIComponent((params.address || '').trim()), // МАС или IP-адрес
                login: encodeURIComponent((params.login || '').trim()), // логин хозяина дома, к которому привязан контроллер
                title: encodeURIComponent((params.title || '').trim()), // название контроллера
                houseTitle: encodeURIComponent((params.houseTitle || '').trim()), // название дома, к которому привязан контроллер
                status: params.status // статус контроллера
            };
            return get('/ctl?offset={offset}&limit={limit}&mac={mac}&login={login}&title={title}&houseTitle={houseTitle}&status={status}'.substitute(parameters));
        },

        getController: function(params) {
            params = params || {};
            var controllerId = params.controllerId;
            return get('/ctl/{controllerId}/'.substitute({controllerId: controllerId}));
        },

        getDevicesCurrentValue: function (params) {
            params = params || {};
            const houseId = params.houseId;
            const filter = {
                groups: params.groups.length > 0 ? params.groups.map(encodeURIComponent).join() : undefined,
                apartment: params.apartment,
                personal: params.personal,
                serial: params.serial,
                individual: params.individual
            };
            return get(`/houses/${houseId}/currentValues`, filter);
        },

        getPatterns: function (params) {
            params = params || {};
            var parameters = {
                offset: encodeURIComponent(params.offset === undefined ? '0' : params.offset.toString()),
                limit: encodeURIComponent(params.limit === undefined ? '' : params.limit.toString())
            };
            return get('/patterns?offset={offset}&limit={limit}'.substitute(parameters));
        },

        getPattern: function (params) {
            params = params || {};
            var id = params.id;
            return get('/patterns/{id}'.substitute({id: id}));
        },

        deletePattern: function (params) {
            params = params || {};
            var id = params.id;
            return del('/patterns/{id}'.substitute({id: id}));
        },

        updatePattern: function (params) {
            params = params || {};
            var pattern = params.pattern;
            var id = pattern.id;
            delete pattern.id;
            return post('/patterns/{id}'.substitute({id: id}), pattern);
        },

        savePattern: function (params) {
            params = params || {};
            var pattern = params.pattern;
            return post('/patterns', pattern);
        },

        getSuitablePatterns: function (params) {
            params = params || {};
            var controllerId = params.controllerId;
            var nodeId = params.nodeId;
            return get('/patterns/suitable?controllerId={controllerId}&nodeId={nodeId}'.substitute({
                controllerId: controllerId,
                nodeId: nodeId
            }));
        },

        createScriptsByPatternIds: function (params) {
            params = params || {};
            var controllerId = params.controllerId;
            var nodeId = params.nodeId;
            var patternIds = params.patternIds;
            return post('/patterns/create-scripts', {
                controllerId: controllerId,
                nodeId: nodeId,
                patternIds: patternIds
            });
        },

        getScripts: function (params) {
            params = params || {};
            var houseId = params.houseId;
            return get('/houses/{houseId}/scripts/'.substitute({houseId: houseId}));
        },
        getScript: function(params) {
            params = params || {};
            var houseId = params.houseId, scriptId = params.scriptId;
            return get('/houses/{houseId}/scripts/{scriptId}'.substitute({houseId: houseId, scriptId: scriptId}));
        },
        setScript: function(params) {
            params = params || {};
            var houseId = params.houseId, scriptId = params.scriptId, script = params.script;
            return post('/houses/{houseId}/scripts/{scriptId}'.substitute({houseId: houseId, scriptId: scriptId}), script);
        },
        removeScript: function (params) {
            params = params || {};
            var houseId = params.houseId, scriptId = params.scriptId;
            return del('/houses/{houseId}/scripts/{scriptId}'.substitute({houseId: houseId, scriptId: scriptId}));
        },

        setTriggeringRule: function(params) {
            params = params || {};
            var houseId = params.houseId, scriptId = params.scriptId, ruleId = params.ruleId, triggeringRule = params.triggeringRule;
            var url = ruleId ?
                '/houses/{houseId}/rules/{ruleId}'.substitute({houseId: houseId, ruleId: ruleId}) :
                '/houses/{houseId}/scripts/{scriptId}/rules'.substitute({houseId: houseId, scriptId: scriptId});
            return post(url, triggeringRule);
        },
        removeTriggeringRule: function (params) {
            params = params || {};
            var houseId = params.houseId, ruleId = params.ruleId;
            return del('/houses/{houseId}/rules/{ruleId}'.substitute({houseId: houseId, ruleId: ruleId}));
        },

        getDevice: function (params) {
            params = params || {};
            var controllerId = params.controllerId;
            var nodeId = params.nodeId;
            return get('/ctl/{controllerId}/devices/{nodeId}'.substitute({controllerId: controllerId, nodeId: nodeId}));
        },

        getDevices: function (params) {
            params = params || {};
            var houseId = params.houseId;
            return get('/houses/{houseId}/devices'.substitute({houseId: houseId}));
        },

        getDeviceCommands(params) {
            const {controllerId, nodeId} = params;
            return get(`/ctl/${controllerId}/devices/${nodeId}/commands`);
        },

        runDeviceCommand(params) {
            const {controllerId, nodeId, commandId} = params;
            return post(`/ctl/${controllerId}/devices/${nodeId}/commands/${commandId}/run`)
        },

        setDeviceSettings: function (params) {
            params = params || {};
            const controllerId = params.controllerId,
                nodeId = params.nodeId,
                title = params.title,
                deviceLocation = params.deviceLocation,
                disabled = params.disabled,
                muted = params.muted,
                networkAddress = params.networkAddress,
                password = params.password,
                serial = params.serial,
                cron = params.cron;
            return post('/ctl/{controllerId}/devices/{nodeId}'.substitute({
                controllerId: controllerId,
                nodeId: nodeId
            }), {
                title: title,
                deviceLocation: deviceLocation,
                disabled: disabled,
                muted: muted,
                networkAddress: networkAddress,
                password: password,
                serial: serial,
                cron
            });
        },

        setDeviceProperty: function (params) {
            params = params || {};
            var controllerId = params.controllerId, nodeId = params.nodeId, propertyId = params.propertyId, value = params.value;
            return post('/ctl/{controllerId}/devices/{nodeId}/props/{propertyId}'.substitute({controllerId: controllerId, nodeId: nodeId, propertyId: propertyId}), {value: value});
        },

        validateDeviceProperty: function (params) {
            const {controllerId, nodeId, propertyId, value} = params;
            return post(`/ctl/${controllerId}/devices/${nodeId}/props/${propertyId}/validate`, {value});
        },

        shiftHistory: function (params) {
            return post('/history/shift', params);
        },

        getDeviceProperty: function (params) {
            params = params || {};
            var controllerId = params.controllerId, nodeId = params.nodeId, propertyId = params.propertyId, value = params.value;
            return get('/ctl/{controllerId}/devices/{nodeId}/props/{propertyId}'.substitute({controllerId: controllerId, nodeId: nodeId, propertyId: propertyId}));
        },

        addNewDevice: function (params) {
            params = params || {};
            var controllerId = params.controllerId;
            const body = {
                deviceType: params.deviceType
            };
            if (params.passwordDevice != null) {
                body.password = params.passwordDevice;
            }
            if (params.addressNetwork != null) {
                body.networkDevice = params.addressNetwork;
            }
            if (params.dskPart != null) {
                body.dskPart = params.dskPart;
            }
            if (params.qrCode != null) {
                body.qrCode = params.qrCode;
            }
            return post('/ctl/{controllerId}/devices/add'.substitute({controllerId: controllerId}), body);
        },

        addNewDeviceCancel: function (params) {
            params = params || {};
            var controllerId = params.controllerId;
            return post('/ctl/{controllerId}/devices/cancel'.substitute({controllerId: controllerId}));
        },

        removeDevice: function (params) {
            params = params || {};
            var controllerId = params.controllerId, nodeId = params.nodeId;
            return del('/ctl/{controllerId}/devices/{nodeId}/remove'.substitute({controllerId: controllerId, nodeId: nodeId}));
        },

        removeDeviceCancel: function (params) {
            params = params || {};
            var controllerId = params.controllerId, nodeId = params.nodeId;
            return post('/ctl/{controllerId}/devices/{nodeId}/cancel'.substitute({controllerId: controllerId, nodeId: nodeId}));
        },

        removeDeadDevice: function (params) {
            params = params || {};
            var controllerId = params.controllerId, nodeId = params.nodeId;
            return del('/ctl/{controllerId}/devices/{nodeId}/removedead'.substitute({controllerId: controllerId, nodeId: nodeId}));
        },

        getDeviceHistory: function (params) {
            params = params || {};
            var controllerId = params.controllerId, nodeId = params.nodeId, propertyId = params.propertyId, valueFrom = params.valueFrom, valueTo = params.valueTo, valueDetail = params.valueDetail, mode = params.mode;
            return get('/ctl/{controllerId}/devices/{nodeId}/props/{propertyId}/history'.substitute({controllerId: controllerId, nodeId: nodeId, propertyId: propertyId}), {from: valueFrom, to: valueTo, detail: valueDetail, mode: mode});
        },

        getDeviceArchiveHistory: function (params) {
            const {controllerId, nodeId, propertyId, valueFrom, valueTo, mode} = params;
            return get(`/ctl/${controllerId}/devices/${nodeId}/props/${propertyId}/archivehistory`, {
                from: valueFrom,
                to: valueTo,
                mode: mode
            });
        },

        getDeviceOldHistory: function (params) {
            const {controllerId, nodeId, propertyId, config} = params;
            return get(`/ctl/${controllerId}/devices/${nodeId}/props/${propertyId}/oldHistory`, config);
        },

        changeDeviceHistory: function (params) {
            return post(`/history/combine`, params);
        },

        getMetersOldHistory(params) {
            const {houseId, data} = params;
            return post(`/meters/oldHistories/?houseId=${houseId}`, data);
        },

        changeMetersHistory(params) {
            const {controllerId, nodeId, data} = params;
            return post(`/ctl/${controllerId}/devices/${nodeId}/history`, data);
        },

        spliceMetersHistory(params) {
            const {controllerId, data} = params;
            return post(`/ctl/${controllerId}/stick/history`, data);
        },

        addNewController: function (params) {
            params = params || {};
            return post('/ctl', params);
        },

        rebindController(params) {
            const {controllerId, targetHouseId} = params;
            return put(`/ctl/${controllerId}/rebind?targetHouseId=${targetHouseId}`);
        },

        removeController: function (params) {
            params = params || {};
            var controllerId = params.controllerId;
            return del('/ctl/{controllerId}/remove'.substitute({controllerId: controllerId}));
        },

        backupController(params) {
            return post(`/ctl/${params.controllerId}/web/zway/backup/initiate`);
        },

        getControllerBackups(params) {
            return get(`/ctl/${params.controllerId}/web/zway/backup`);
        },

        recoveryControllerBackup(params) {
            return post(`/ctl/${params.controllerId}/web/zway/backup`, {
                backupId: params.backupId
            });
        },

        transferControllerBackup(params) {
            return post(`/ctl/${params.controllerId}/web/zway/backup`, {
                backupId: params.backupId,
                targetControllerId: params.targetControllerId
            });
        },

        rebootController(params) {
            return post(`/ctl/${params.controllerId}/reboot`);
        },

        resetZWayController(params) {
            return post(`/ctl/${params.controllerId}/zway/reset`);
        },

        unlinkController: function (params) {
            params = params || {};
            var controllerId = params.controllerId;
            return post('/ctl/{controllerId}/unlink'.substitute({controllerId: controllerId}));
        },

        /**
         * Запрос редактирования данных контроллера.
         *
         * @param params Параметры запроса.
         * @param params.controllerId Идентификатор контроллера.
         * @param params.title Новое название контроллера.
         * @param params.ipAddress Новый ip-адрес контроллера.
         */
        updateController(params) {
            const {controllerId, ...data} = params;
            return post(`/ctl/${controllerId}`, data);
        },

        getUser: function () {
            return get('/user');
        },
        getUserById: function(params) {
            params = params || {};
            var id = params.id;
            return get('/users/{id}'.substitute({id: id}));
        },
        getUsers: function (params) {
            params = params || {};
            var parameters = {
                offset: params.offset === undefined ? '0' : params.offset.toString(),
                limit: params.limit === undefined ? '' : params.limit.toString(),
                name: (params.name || '').trim(),
                login: (params.login || '').trim(),
                phone: (params.phone || '').trim(),
                roleId: params.roleId
            };
            return get('/users?' + $.param(parameters));
        },
        createUser: function (params) {
            params = params || {};
            var user = params.user;
            return post('/users', user);
        },
        updateUser: function (params) {
            params = params || {};
            var user = params.user;
            return post('/users/{userId}'.substitute({userId: user.id}), user);
        },
        removeUser: function (params) {
            params = params || {};
            var user = params.user;
            return del('/users/{userId}'.substitute({userId: user.id}));
        },
        getNotificationSettings: function () {
            return get('/notifications');
        },
        updateNotificationSettings: function (params) {
            params = params || {};
            var settings = params.settings;
            return post('/notifications', settings);
        },
        runScript: function (params) {
            params = params || {};
            var houseId = params.houseId, scriptId = params.scriptId;
            return post('/houses/{houseId}/scripts/{scriptId}/run'.substitute({houseId: houseId, scriptId: scriptId}));
        },
        cancelScript: function (params) {
            params = params || {};
            var houseId = params.houseId, scriptId = params.scriptId;
            return post('/houses/{houseId}/scripts/{scriptId}/cancel'.substitute({houseId: houseId, scriptId: scriptId}));
        },
        getGroups: function (params) {
            params = params || {};
            var houseId = params.houseId;
            return get('/houses/{houseId}/groups'.substitute({houseId: houseId}));
        },
        updateGroup: function (params) {
            params = params || {};
            var groupId = params.groupId;
            var title = params.title;
            var parentId = params.parentId;
            var houseId = params.houseId;
            return post('/houses/{houseId}/groups/{groupId}'.substitute({
                houseId: houseId,
                groupId: groupId
            }), {
                title: title,
                parentId: parentId
            });
        },
        createGroup: function (params) {
            params = params || {};
            var title = params.title;
            var parentId = params.parentId;
            var houseId = params.houseId;
            return post('/houses/{houseId}/groups'.substitute({houseId: houseId}), {title: title, parentId: parentId});
        },
        deleteGroup: function (params) {
            params = params || {};
            var groupId = params.groupId;
            var houseId = params.houseId;
            return del('/houses/{houseId}/groups/{groupId}'.substitute({groupId: groupId, houseId: houseId}));
        },
        getDevicePropertyHistory: function (params) {
            params = params || {};
            var controllerId = params.controllerId;
            var nodeId = params.nodeId;
            var propertyId = params.propertyId;
            var queryParams = {
                from: params.from,
                to: params.to,
                detail: params.detail,
                mode: params.mode,
                delta: params.delta
            };
            for (var key in queryParams) {
                if (queryParams[key] === undefined) {
                    delete queryParams[key];
                }
            }
            return get(('/ctl/{controllerId}/devices/{nodeId}/props/{propertyId}/history?').substitute({
                controllerId: controllerId,
                nodeId: nodeId,
                propertyId: propertyId
            }) + $.param(queryParams));
        },
        getReport: function (params) {
            params = params || {};
            var houseId = params.houseId;
            var queryParams = {from: params.from, to: params.to, individual: params.individual};
            const groups = params.groups;
            let path = '/houses/{houseId}/report?'.substitute({houseId: houseId}) + $.param(queryParams);
            if (groups && groups.length > 0) {
                path += '&groups=' + groups.map(encodeURIComponent).join();
            }
            return get(path);
        },

        getAddresses(params) {
            params = params || {};
            let queryParams = {userId: params.userId};
            return get('/addresses', queryParams);
        },

        getReportByAddress: function (params) {
            params = params || {};
            let parameters = {
                address: params.address === null ? '' : params.address.address,
                apartment: params.address === null ? '' : params.address.apartment,
            };
            return post('/meters/report?' + $.param({from: params.from, to: params.to}), parameters);
        },

        addAddress: function (params) {
            params = params || {};
            let queryParams = {userId: params.userId};
            queryParams.address = params.address;
            queryParams.apartment = params.apartment;
            return post('/addresses', queryParams);
        },

        deleteAddress: function (params) {
            params = params || {};
            let parameters = {
                userId: params.userId
            };
            return del('/addresses/{addressId}?'.substitute({addressId: params.addressId}) + $.param(parameters));
        },

        /**
         * Запрос счетчиков по адресу.
         *
         * @param params {object} - параметры запроса.
         * @param params.address {string} - адрес.
         * @param params.apartment {string} - квартира.
         * @param params.group {string} - группа, используется для запроса одного счетчика.
         * @param params.serial {string} - серийный номер, используется для запроса одного счетчика.
         * @param params.personal {string} - лицевой счет, используется для запроса одного счетчика.
         * @param params.allMeasures {boolean} - true - "показать все".
         */
        getAddressMeters: function (params) {
            const {allMeasures, ...parameters} = params;
            return get('/meters/currentValue', {...parameters, short: !allMeasures});
        },

        getMeterHistory: function (params) {
            params = params || {};
            const queryParams = {
                address: params.address,
                apartment: params.apartment,
                from: params.valueFrom,
                to: params.valueTo,
                group: params.group,
                serial: params.serial,
                personal: params.personal,
                detail: params.valueDetail
            };
            return get(('/meters/historyByAddress?') + $.param(queryParams));
        },

        getMeterHistoryWithGroups: function (params) {
            params = params || {};
            const queryParams = {
                address: params.address,
                apartment: params.apartment,
                from: params.valueFrom,
                to: params.valueTo,
                detail: params.valueDetail
            };
            const body = {
                groups: params.groups,
                serial: params.serial,
                personal: params.personal
            };
            return post('/meters/historyByAddressWithGroups?' + $.param(queryParams), body);
        },

        getDeviceConfig(params) {
            params = params || {};
            const controllerId = params.controllerId;
            const deviceId = params.deviceId;
            return get(`/ctl/${controllerId}/devices/${deviceId}/inputs`);
        },

        setDeviceConfig(params) {
            params = params || {};
            const controllerId = params.controllerId;
            const deviceId = params.deviceId;
            const config = params.config;
            return post(`/ctl/${controllerId}/devices/${deviceId}/inputs`, config);
        },

        setDeviceChannels(params) {
            params = params || {};
            const controllerId = params.controllerId;
            const nodeId = params.nodeId;
            const config = params.config;
            return post(`/ctl/${controllerId}/devices/${nodeId}`, config);
        },

        getInputKinds() {
            return get('/meters/inputkinds');
        },

        getAccountingGroups() {
            return get('/meters/accountinggroups');
        },

        getAccountingElements() {
            return get('/meters/accountingelements');
        },

        getMetersGroups() {
            return get('/meters/groups');
        },

        getAllKinds() {
            return get('/meters/allkinds');
        },

        interviewDevice(params) {
            params = params || {};
            const controllerId = params.controllerId;
            const nodeId = params.nodeId;
            return post(`/ctl/${controllerId}/devices/${nodeId}/electricRequest`);
        },

        resetAnalyticStatus(params) {
            const {controllerId, nodeId, data} = params;
            return post(`/ctl/${controllerId}/devices/${nodeId}/reset`, data);
        },

        setDeviceDataStatusChangedNormal(params) {
            params = params || {};
            const controllerId = params.controllerId;
            const nodeId = params.nodeId;
            const propertyId = params.propertyId;
            return post(`/ctl/${controllerId}/devices/${nodeId}/props/${propertyId}/data-checked`);
        },

        getUserReports() {
            return get('/report/types/user');
        },

        getReportTypes() {
            return get('/report/types');
        },

        updateUserReports(params) {
            return post('/report/types/user', params);
        },

        getReportSettings(reportTemplateId) {
            return get(`/report/settings?reportTemplateId=${reportTemplateId}`);
        },

        setReportSettings(params) {
            params = params || {};
            return post(`/report/settings`, params);
        },

        generateReport(params) {
            return post(`/report`, params);
        },

        createReport(params) {
            return post('/report/create', params);
        },

        getNewReport() {
            return get('/report');
        },

        getReportHistory(params) {
            return get('/report/history', params);
        },

        getReportJobs(params) {
            return get('/report/jobs', params);
        },

        deleteReportJob(jobId) {
            return del(`/report/jobs/delete?id=${jobId}`);
        },

        pauseReportJob(jobId) {
            return post(`/report/jobs/pause?id=${jobId}`);
        },

        resumeReportJob(jobId) {
            return post(`/report/jobs/resume?id=${jobId}`);
        },

        downloadFile(downloadUrl, needSuccessArgs) {
            return get(`/files/download/${downloadUrl}`, null, needSuccessArgs, true);
        },

        getTemplate(id) {
            return get(`/report/settings/${id}`);
        },

        deleteReportSettingsTemplate(id) {
            return del(`/report/settings/${id}`);
        },

        deleteTemplate(id) {
            return del(`/report/${id}`);
        },

        getLicenses() {
            return get('/mjollnir/licenses');
        },

        addLicense(license) {
            const formData = new FormData();
            formData.append('file', license);
            return put('/mjollnir/license', formData, false, false, true);
        },

        resendLicense(id) {
            return patch(`/mjollnir/licenses/${id}`);
        },

        getPseudoDevice(params) {
            return post(`/ctl/${params.controllerId}/pseudoDevice/${params.deviceType}`);
        },

        getDeviceConfSharedPatterns(deviceType) {
            return get(`/template/get/shared?deviceType=${deviceType}`);
        },

        doBulkAddDevices(params) {
            return post(`/ctl/${params.controllerId}/addGroupOfDevice`, params.data);
        },

        getOrganizations() {
            return get('/guard/organizations');
        },

        getHousesCurrentStates() {
            return get('/currentStates');
        },

        getRoles() {
            return get('/security/roles');
        },

        getRole(id) {
            return get(`/security/roles/${id}`);
        },

        getHouseCameras(params) {
            return get(`/video/cameras/houses/${params.houseId}`);
        },

        addFileReport(file) {
            const formData = new FormData();
            formData.append('file', file);
            return post('/report/binary/', formData, false, false, true);
        },

        deleteFileReport(id) {
            return del(`/report/binary/${id}`);
        },

        deleteAccessToken() {
            const accessToken = localStorageService.getAccessToken();
            const requestBody = {
                token: accessToken,
                token_type_hint: 'access_token'
            };
            return post('/oauth/token/revoke', requestBody);
        },

        addFirebaseToken(firebaseToken) {
            return post('/users/firebase/add', {firebaseToken});
        },

        deleteFirebaseToken(firebaseToken) {
            return del(`/users/firebase/${firebaseToken}`);
        },

        addVideoPlatform(params) {
            const {userId, platformType} = params;
            return post(`/video/users/${userId}?platformType=${platformType}`);
        },

        deleteVideoPlatform(params) {
            const {userId, platformType} = params;
            return del(`/video/users/${userId}?platformType=${platformType}`);
        },

        enabledVideoPlatform(params) {
            const {userId, platformType, enabled} = params;
            return put(`/video/users/${userId}?platformType=${platformType}`, {enabled});
        },

        getFlussonicPlatformSettings(userId) {
            return get(`/flussonic/users/${userId}`);
        },

        searchFlussonicUser(login) {
            return get('/flussonic/users/find', {login});
        },

        syncFlussonicUser(params) {
            const {userId, data} = params;
            return post(`/flussonic/users/synchronize?userId=${userId}`, data);
        }
    };
});
