import {menuItemsMap} from './menu-items-map';
import {Permission} from './permission';

class User {

    instance;

    setUser(user) {
        this.instance = user;
    }

    get pageIdArray() {
        return this.instance.pagesIds;
    }

    get permissions() {
        return this.instance.permissions;
    }

    get username() {
        return this.instance.username;
    }

    get fullName() {
        if (!this.hasPermission(Permission.needPersonalInformation)) {
            return this.username;
        }
        const {name, patronymic, surname, username} = this.instance;
        const fullName = [name, patronymic, surname].join(' ').trim();
        return fullName.length === 0 ? username : fullName;
    }

    get defaultPageLink() {
        const pageId = this.instance.roleSettings.defaultPage;
        return menuItemsMap[pageId].link;
    }

    get cameraPlatforms() {
        return this.instance.platforms || [];
    }

    hasPermission(permission) {
        return this.permissions.includes(permission);
    }

    hasAnyPermission(permissions) {
        return permissions.some(permission => this.permissions.includes(permission));
    }

    hasPage(pageId) {
        return this.pageIdArray.includes(pageId);
    }

    hasAnyPage(pageIds) {
        return pageIds.some(pageId => this.pageIdArray.includes(pageId));
    }

}

export default new User();
