define([
    'knockout',
    'knockoutmapping',
    'core/config',
    'core/scripts-lib',
    'text!./template.html'
], function (ko, komapping, config, ScriptsLib, template) {
    function ViewModel(params) {
        this.script = komapping.fromJS(params.script);
        this.canEdit = ko.observable(params.canEdit || false);
        this.needCloseButton = ko.observable(params.needCloseButton || false);
        this.closeButtonHandler = params.closeButtonHandler;
        this.SCRIPT_STATUSES = ScriptsLib.SCRIPT_STATUSES;
        this.isLoading = ko.observable(false);

        this.runScript = () => {
            this.isLoading(true);
            config.runScript({houseId: this.script.houseId(), scriptId: this.script.id()})
                .next(() => {
                    if (this.script.isFinished()) {
                        this.script.isFinished(false);
                    } else {
                        this.script.status(this.SCRIPT_STATUSES.RUNNING);
                    }
                })
                .error(e => {
                    alert(T('RunScriptError'));
                    console.error(e);
                })
                .next(this.isLoading(false));
        };

        this.stopScript = () => {
            this.isLoading(true);
            config.cancelScript({houseId: this.script.houseId(), scriptId: this.script.id()})
                .next(() => this.script.status(this.SCRIPT_STATUSES.READY))
                .error(e => {
                    alert(T('CancelScriptError'));
                    console.error(e);
                })
                .next(this.isLoading(false));
        };
    }

    ko.components.register('widget-script', {
        viewModel: ViewModel,
        template: template
    });
});
