define([
    'knockout',
    'text!./template.html'
], (ko, template) => {

    /**
     * Принимаемый формат cron: "0 0/15 * ? * * *"
     * minutesSegmentIndex: индекс сегмента с описанием минут ("0/15")
     * minutesSegmentEveryPartIndex: = индекс подсегмента с периодом ("15")
     */
    class Cron {
        delimiter = ' ';
        minutesSegmentDelimiter = '/';
        minutesSegmentIndex = 1;
        minutesSegmentEveryPartIndex = 1;
        segments;

        get period() {
            return parseInt(this.getMinutesSegmentParts()[this.minutesSegmentEveryPartIndex]);
        }

        set period(value) {
            const minutesSegmentParts = this.getMinutesSegmentParts();
            minutesSegmentParts[this.minutesSegmentEveryPartIndex] = value;
            this.segments[this.minutesSegmentIndex] = minutesSegmentParts.join(this.minutesSegmentDelimiter);
        }

        constructor(cron) {
            cron = cron || '0 0/15 * ? * * *';
            this.segments = cron.split(this.delimiter);
        }

        getMinutesSegmentParts() {
            return this.segments[this.minutesSegmentIndex].split(this.minutesSegmentDelimiter);
        }

        toString() {
            return this.segments.join(this.delimiter);
        }
    }

    class ViewModel {
        constructor(params) {
            this.value = params.value;

            const value = this.value();
            const cron = new Cron(value);
            this.offOption = 'off';
            this.options = [this.offOption, 3, 4, 5, 6, 10, 12, 15, 20, 30];
            this.selectValue = ko.observable(value ? cron.period : this.offOption);
            this.selectValueSub = this.selectValue.subscribe(val => {
                if (val === this.offOption) {
                    this.value('');
                } else {
                    cron.period = val;
                    this.value(cron.toString());
                }
            });
        }

        getOptionRestext(option) {
            return option === this.offOption
                ? 'InterviewPeriodOff'
                : {key: 'InterviewPeriodX', params: {period: option}};
        }

        dispose() {
            this.selectValueSub.dispose();
        }
    }

    ko.components.register('cron-editor', {viewModel: ViewModel, template});

});
