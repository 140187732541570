define(['knockout'], ko => {
    'use strict';

    // https://stackoverflow.com/a/22001770
    ko.bindingHandlers.bootstrapSelect = {
        init: function (element, valueAccessor, allBindingsAccessor) {
            const $element = $(element);
            const bootstrapSelectOptions = ko.utils.unwrapObservable(valueAccessor());
            $element.addClass('selectpicker').selectpicker(bootstrapSelectOptions);

            const doRefresh = () => $element.selectpicker('refresh');

            const bootstrapSelectControl = allBindingsAccessor.get('bootstrapSelectControl');
            if (bootstrapSelectControl) {
                bootstrapSelectControl.refresh = doRefresh;
            }

            const subscriptions = [];

            const addSubscription = bindingKey => {
                const targetObs = allBindingsAccessor.get(bindingKey);
                if (targetObs && ko.isObservable(targetObs)) {
                    subscriptions.push(targetObs.subscribe(doRefresh));
                }
            };

            addSubscription('options');
            addSubscription('value'); // Single
            addSubscription('selectedOptions'); // Multiple

            ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
                while (subscriptions.length) {
                    subscriptions.pop().dispose();
                }
            });
        },
        update: function (element) {
            setTimeout(() => {
                $(element).selectpicker('refresh');
            }, 50);
        }
    };
});
