import Vue from 'vue';
import komapping from 'knockoutmapping';

import config from '../../../core/config';
import EventService from '../../../core/websocket/event-service'
import EventType from '../../../core/websocket/event-type'
import template from 'text!./template.html';

export default {
    template,
    data() {
        return {
            userId: window.globalState.login.id(),
            userAccess: komapping.toJS(window.globalState.login.access),
            houses: [],
            house: null,
            isPopoverVisible: false,
            eventSubscriptions: []
        };
    },
    computed: {
        shovingInHeaderVars() {
            return this.house.variables.filter(variable => variable.showInHeader === true);
        },
        canChangeMode() {
            if (this.house == null) {
                return false;
            }
            if (!this.userAccess.hasOwnProperty(this.house.id)) {
                return false;
            }
            const level = this.userAccess[this.house.id].level;
            return level === 'OWNER' || level === 'EDITOR';
        }
    },
    created() {
        config.getHouses()
            .next(houses => {
                this.houses = houses;
                this.house = this.houses.length > 0 ? this.houses[0] : null;
            })
            .error(e => console.error(e));

        this.addEventListeners();
    },
    destroyed() {
        this.removeEventListeners();
    },
    methods: {
        togglePopover(event) {
            this.isPopoverVisible = !this.isPopoverVisible;
            event.stopPropagation();
        },
        hidePopover() {
            this.isPopoverVisible = false;
        },
        onToHouseVariablesManagementPageClick() {
            Navigation.to('house-management/house/variables', {id: this.house.id});
            this.isPopoverVisible = false;
        },
        addEventListeners() {
            this.eventSubscriptions.push(
                EventService.subscribe(EventType.VARIABLE_CHANGE, this.onVariableChange),
                EventService.subscribe(EventType.VARIABLE_ADD, this.onVariableAdd),
                EventService.subscribe(EventType.VARIABLE_REMOVE, this.onVariableRemove),
                EventService.subscribe(EventType.VARIABLE_EDIT, this.onVariableEdit),
                EventService.subscribe(EventType.HOUSE_REMOVE, this.removeHouseByEvent),
                EventService.subscribe(EventType.HOUSE_CREATE, this.addHouseByEvent)
            );
        },
        removeEventListeners() {
            this.eventSubscriptions.forEach(sub => sub.unsubscribe());
        },
        onVariableChange(data) {
            this.houses.some(house => {
                if (house.id === data.houseId) {
                    house.variables.some(variable => {
                        if (variable.id === data.variableId) {
                            variable.value = data.value;
                            return true;
                        }
                        return false;
                    });
                    return true;
                }
                return false;
            });
        },
        onVariableAdd(data) {
            config.getHouseVariable({houseId: data.houseId, variableId: data.varId})
                .next(variable => {
                    this.houses.some(house => {
                        if (house.id === data.houseId) {
                            house.variables.push(variable);
                            return true;
                        }
                        return false;
                    });
                })
                .error(e => console.error(e));
        },
        onVariableRemove(data) {
            this.houses.some(house => {
                if (house.id === data.houseId) {
                    house.variables.splice(house.variables.findIndex(variable => variable.id === data.varId), 1);
                    return true;
                }
                return false;
            });
        },
        onVariableEdit(data) {
            this.houses.some(house => {
                if (house.id === data.houseId) {
                    house.variables.some((variable, index) => {
                        if (variable.id === data.variableId) {
                            config.getHouseVariable({
                                houseId: data.houseId,
                                variableId: data.variableId
                            })
                                .next(curVariable => Vue.set(house.variables, index, curVariable))
                                .error(e => console.error(e));
                            return true;
                        }
                        return false;
                    });
                    return true;
                }
                return false;
            });
        },
        removeHouseByEvent(data) {
            if (this.house.id === data.houseId) {
                this.houses.some(house => {
                    if (house.id !== this.house.id) {
                        this.house = house;
                        return true;
                    }
                    return false;
                });
            }
            this.houses.splice(this.houses.findIndex(house => house.id === data.houseId), 1);
        },
        addHouseByEvent(data) {
            this.houses.push(data.houseInfo);
            if (!this.house) {
                this.house = this.houses[0];
            }
            if (this.userId === data.houseInfo.ownerId) {
                config.getUserAccessByHouse({
                    userId: this.userId,
                    houseId: data.houseInfo.id
                })
                    .next(access => Vue.set(this.userAccess, data.houseInfo.id, access))
                    .error(e => console.error(e));
            }
        }
    }
};
