define([
    'knockout',
    'core/devices-lib',
    'text!./template.html'
], (ko, DevicesLib, template) => {

    const Mode = Object.freeze({
        Custom: 'Custom',
        Preset: 'Preset'
    });

    const CUSTOM_CODE_MODEL_VALUE = DevicesLib.CUSTOM_CODE_MODEL_VALUE;

    class ViewModel {
        constructor(params) {
            this.enable = params.enable;
            this.value = params.value;

            this.previousValue = this.value();
            this.mode = ko.observable(this.value() === CUSTOM_CODE_MODEL_VALUE ? Mode.Custom : Mode.Preset);

            this.Mode = Mode;

            this.modeSub = this.mode.subscribe(newMode => {
                if (newMode === Mode.Custom) {
                    this.previousValue = this.value();
                    this.value(CUSTOM_CODE_MODEL_VALUE);
                } else {
                    this.value(this.previousValue);
                }
            });
        }

        dispose() {
            this.modeSub.dispose();
        }
    }

    ko.components.register('code-model-property', {viewModel: ViewModel, template});

});
