define([
    'vue',
    'text!./template.html'
], function (Vue, template) {

    Vue.component('v-multi-input', {
        template: template,
        props: {
            value: Array,
            id: String,
            label: String
        },
        data: function () {
            return {
                items: this.value,
                newItem: ''
            };
        },
        watch: {
            items(newInputValue) {
                this.$emit('input', newInputValue);
            }
        },
        methods: {
            onSubmitButtonClick() {
                if (!this.newItem) {
                    return;
                }
                this.items.push(this.newItem);
                this.newItem = '';
                this.$refs.newItem.focus();
            },
            deleteItem(index) {
                this.items.splice(index, 1);
            }
        }
    });

});
