define([
    'knockout',
    'knockoutmapping',
    'core/routes-guard',
    'core/viewport-scroller',
    'rxjs'
], function (ko, komapping, RoutesGuard, ViewportScroller, rxjs) {
    'use strict';

    RoutesGuard = RoutesGuard.default;

    // ---------------------------------------------------

    function Hole(params) {
        this.visible = ko.observable(true);
        this.isLoaded = false;
        this.path = params.path;
        this.module = {enter: function() {}, exit: function() {}};
        this.$page = ko.observable();
    }

    Hole.prototype.load = function() {
        this.isLoaded = true;
        var d = new Deferred();
        var successRequire = function (m) {
            d.call(m);
        };
        var errorRequire = function (e) {
            d.fail(e);
        };
        if (!RoutesGuard.isAllowedPath(this.path)) {
            this.path = 'not-found';
        }

        require(['pages/'+this.path+'/init'], successRequire, errorRequire);
        return d;
    };

    // ---------------------------------------------------

    var template = '\
        <!-- ko foreach: pages -->\
        <div data-bind="element: $page, visible: visible()"></div>\
        <!-- /ko -->';

    function Pages(params) {
        this.$container = params.$container;
        this.menu = params.menu || {selectMenu: function() {}};
        this.pages = ko.observableArray();
    }

    Pages.prototype.alive = function () {
        var dom = ko.utils.parseHtmlFragment(template);
        dom.forEach(function(e){
            this.$container.appendChild(e);
        }, this);
        try {
            ko.applyBindings({
                pages: this.pages,
            }, this.$container);
        }
        catch(e) {
            console.error(e);
            throw(e);
        }
    };

    Pages.prototype.addNewPage = function (path, urlParams) {
        var hole = new Hole({path: path});
        this.pages.push(hole);
        return hole.load().
            next(function(module) {
                var moduleParams = {
                    $container: document.createElement('div'),
                };
                hole.$page().appendChild(moduleParams.$container);
                hole.module = module.init(moduleParams);
                hole.module.destroy$ = new rxjs.Subject();
                hole.module._scrollPosition = null;
                hole.module.restoreScrollPosition = () => {
                    ViewportScroller.scrollToPosition(hole.module._scrollPosition);
                };
                hole.module.enter(urlParams);
            }.bind(this)).
            error(function(e) {
                console.error(e);
                var text = [
                    e.name + ': ' + e.message,
                    'line: ' + e.lineNumber,
                    'column: ' + e.columnNumber,
                    'file: ' + e.fileName
                ].join('\n');
                var pre = $('<pre>').text(text);
                hole.$page().appendChild(pre[0]);
            }.bind(this)).
            next(function() {
                this.menu.selectMenu(path);
            }.bind(this));
    };

    Pages.prototype.to = function (path, urlParams) {
        path = (path || '').replace(/\/$/, '').replace(/^#/, '');
        var pages = this.pages();

        pages.forEach(function(hole) {
            if(hole.visible()) {
                hole.module._scrollPosition = ViewportScroller.getScrollPosition();
                hole.module.destroy$.next(null);
                hole.module.destroy$.complete();
                hole.module.exit && hole.module.exit();
                hole.visible(false);
            }
        }.bind(this));

        var found = pages.some(function(hole) {
            if(hole.path == path) {
                if(hole.isLoaded) {
                    hole.visible(true);
                    hole.module.destroy$ = new rxjs.Subject();
                    hole.module.enter(urlParams);
                }
                this.menu.selectMenu(path);
                return true;
            }
        }.bind(this));
        if(!found)
            return this.addNewPage(path, urlParams);

        return new Deferred();
    };

    return {
        init: function (params) {
            var pages = new Pages(params);
            pages.alive();
            return pages;
        }
    };
});
