import template from 'text!./template.html';

export default {
    template,
    props: {
        push: Object
    },
    data() {
        const {web_title, web_body} = this.push.data;
        return {
            title: web_title,
            body: web_body,
            time: this.push.time,
            expanded: false,
            textTruncated: false
        }
    },
    computed: {
        originalImage() {
            return this.push.data.web_image;
        },
        image() {
            return this.originalImage ? this.originalImage : 'img/notification.png';
        },
        showImage() {
            return !this.expanded || this.originalImage != null;
        },
        showExpandButton() {
            return this.textTruncated || this.originalImage != null;
        }
    },
    methods: {
        toggleExpanded() {
            this.expanded = !this.expanded;
        },
        isOverflowing(el) {
            return el.clientWidth < el.scrollWidth;
        }
    },
    mounted() {
        this.textTruncated = this.isOverflowing(this.$refs.pushTitle) || this.isOverflowing(this.$refs.pushBody);
    }
}
