define([
    'knockout',
    'text!./template.html'
], function (ko, template) {
    function ViewModel(params) {
        this.inputSeconds = params.seconds;
        var COMPUTED_EXTEND_VALUE = {notify: 'always'};
        this.hours = ko.pureComputed({
            read: function () {
                return Math.floor(this.inputSeconds() / 3600);
            },
            write: function (newValue) {
                var current = this.hours();
                var valueToWrite = Math.floor(Number(newValue));
                if (valueToWrite < 0) {
                    valueToWrite = 0;
                }
                if (current !== valueToWrite) {
                    this.inputSeconds(valueToWrite * 3600 + this.minutes() * 60 + this.seconds());
                } else {
                    if (current.toString() !== newValue) {
                        this.inputSeconds.notifySubscribers(valueToWrite);
                    }
                }
            },
            owner: this
        }).extend(COMPUTED_EXTEND_VALUE);
        this.minutes = ko.pureComputed({
            read: function () {
                return Math.floor(this.inputSeconds() % 3600 / 60);
            },
            write: function (newValue) {
                var current = this.minutes();
                var valueToWrite = Math.floor(Number(newValue));
                if (valueToWrite < 0 || valueToWrite > 59) {
                    valueToWrite = 0;
                }
                if (current !== valueToWrite) {
                    this.inputSeconds(this.hours() * 3600 + valueToWrite * 60 + this.seconds());
                } else {
                    if (current.toString() !== newValue) {
                        this.inputSeconds.notifySubscribers(valueToWrite);
                    }
                }
            },
            owner: this
        }).extend(COMPUTED_EXTEND_VALUE);
        this.seconds = ko.pureComputed({
            read: function () {
                return Math.floor(this.inputSeconds() % 3600 % 60);
            },
            write: function (newValue) {
                var current = this.seconds();
                var valueToWrite = Math.floor(Number(newValue));
                if (valueToWrite < 0 || valueToWrite > 59) {
                    valueToWrite = 0;
                }
                if (current !== valueToWrite) {
                    this.inputSeconds(this.hours() * 3600 + this.minutes() * 60 + valueToWrite);
                } else {
                    if (current.toString() !== newValue) {
                        this.inputSeconds.notifySubscribers(valueToWrite);
                    }
                }
            },
            owner: this
        }).extend(COMPUTED_EXTEND_VALUE);
        this.inputWidth = '70px';
    }

    ko.components.register('hms-input', {
        viewModel: ViewModel,
        template: template
    });
});
