define([
	'knockout',
], function (ko) {
	ko.bindingHandlers['className'] = {
		'update': function (element, valueAccessor) {
			var value = ko.utils.unwrapObservable(valueAccessor() || {});
			element.className += (' ' + value);
		}
	};

	ko.bindingHandlers['element'] = {
		'init': function (element, valueAccessor) {
			var value = valueAccessor();
			value(element);
		}
	};
});