define([], function () {
    'use strict';

    function pluralizeRu(n, titles) {
        const abs = Math.abs(Number(n));
        return n + ' ' + titles[(abs % 10 === 1 && abs % 100 !== 11) ? 0 : abs % 10 >= 2 && abs % 10 <= 4 && (abs % 100 < 10 || abs % 100 >= 20) ? 1 : 2];
    }

    function pluralizeEn(count, noun, suffix = 's') {
        const abs = Math.abs(Number(count));
        return `${count} ${noun}${abs !== 1 ? suffix : ''}`;
    }

    return {
        CurrentMeasures: {
            en: 'Current Measures',
            ru: 'Текущие показания',
        },
        Dashboard: {
            ru: 'Рабочий стол',
        },
        HouseManagement: {
            en: 'House Management',
            ru: 'Управление домами',
        },
        Modes: {
            ru: 'Режимы',
        },
        Scripts: {
            ru: 'Сценарии',
        },
        Devices: {
            ru: 'Устройства',
        },
        Rooms: {
            ru: 'Комнаты',
        },
        Events: {
            en: 'Events',
            ru: 'События',
        },

        logout: {
            ru: 'выход',
        },
        WhatsNew: {
            en: 'What\'s new',
            ru: 'Что нового?',
        },
        Help: {
            ru: 'Справка',
        },
        EmergencySituation: {
            en: 'Emergency situation',
            ru: 'Внештатная ситуация',
        },
        ItDoesNotWorkYet: {
            en: 'It does not work yet',
            ru: 'Пока не работает',
        },
        Copyright: {
            en: 'Eltex Enterprise LTD.',
            ru: 'ООО "Предприятие "Элтекс"',
        },
        Core: {
            en: 'Core',
            ru: 'Ядро',
        },
        Web: {
            en: 'Web',
            ru: 'Веб',
        },
        'There is no connection': {
            ru: 'Нет связи',
        },
        CantGetUserInfo: {
            en: 'No information about current user. Try to reload page.',
            ru: 'Не удалось получить информацию о текущем пользователе. Попробуйте перезагрузить страницу.',
        },

        CustomizeDisplay: {
            en: 'Display Settings',
            ru: 'Настроить отображение',
        },

        DashboardSettings: {
            en: 'Dashboard Settings',
            ru: 'Настройки рабочего стола',
        },
        backToDashboard: {
            en: 'back to Dashboard',
            ru: 'вернуться к рабочему столу',
        },

        Mode: {
            en: 'Mode',
            ru: 'Режим',
        },
        currentMode: {
            en: 'current mode',
            ru: 'текущий режим',
        },
        HouseSettings: {
            en: 'Settings of House',
            ru: 'Настройки дома',
        },
        HouseControllers: {
            en: 'Controllers of House',
            ru: 'Контроллеры дома',
        },
        AddingNewHouse: {
            en: 'Adding of New House',
            ru: 'Добавление нового дома',
        },
        AddNewMode: {
            en: 'Add New Mode',
            ru: 'Добавить режим',
        },
        SelectAddingDeviceMode: {
            en: 'Select adding device mode',
            ru: 'Выберите режим добавления устройства',
        },
        houseNotFound: {
            en: 'House with id="#id" is not found',
            ru: 'Дом с id="#id" не найден',
        },
        backToHouseManagement: {
            en: 'back to House Management',
            ru: 'вернуться к управлению домами',
        },
        CommonSettings: {
            en: 'Common Settings',
            ru: 'Общие настройки',
        },
        HouseName: {
            en: 'House Name',
            ru: 'Название дома',
        },
        HouseAddress: {
            en: 'House address',
            ru: 'Адрес дома',
        },
        enterHouseName: {
            en: 'please, enter the name of house',
            ru: 'введите название дома',
        },
        Address: {
            en: 'Address',
            ru: 'Адрес',
        },
        enterAddress: {
            en: 'please, enter the address of house',
            ru: 'введите адрес дома',
        },
        emptyAddress: {
            en: 'The input line is empty. To perform the operation, you must enter the address of the house.',
            ru: 'Строка ввода пуста. Для выполнения операции необходимо ввести адрес дома.',
        },
        Apartment: {
            en: 'Apartment',
            ru: 'Квартира',
        },
        enterApartment: {
            en: 'please, enter the apartment of house',
            ru: 'введите номер квартиры',
        },
        emptyApartment: {
            en: 'The input line is empty. To perform the operation, you must enter the apartment of the house.',
            ru: 'Строка ввода пуста. Для выполнения операции необходимо ввести номер квартиры.',
        },
        Users: {
            ru: 'Пользователи',
        },
        OWNER: {
            en: 'Owner',
            ru: 'Владелец',
        },
        WATCHER: {
            en: 'Watcher',
            ru: 'Наблюдатель',
        },
        EDITOR: {
            en: 'Owner',
            ru: 'Редактор',
        },
        ChangeOwner: {
            en: 'change owner',
            ru: 'сменить владельца',
        },
        enterOwner: {
            en: 'please, enter the name of house owner',
            ru: 'введите имя и фамилию владельца дома',
        },
        enterLogin: {
            en: 'enter login',
            ru: 'введите логин',
        },
        Controllers: {
            ru: 'Контроллеры',
        },
        SelectAll: {
            en: 'Select all',
            ru: "Выбрать все",
        },
        DeselectAll: {
            en: 'Deselect all',
            ru: "Снять выделение",
        },
        //devices
        SMOKE: {
            en: 'SMOKE',
            ru: 'датчик дыма',
        },
        GAS: {
            en: 'GAS',
            ru: 'датчик газа',
        },
        LEAK: {
            en: 'LEAK',
            ru: 'датчик протечки',
        },
        MOTION: {
            en: 'MOTION',
            ru: 'датчик движения',
        },
        GLASS_BREAKAGE: {
            en: 'GLASS_BREAKAGE',
            ru: 'датчик разбития стекла',
        },
        OPEN_CLOSE: {
            en: 'OPEN_CLOSE',
            ru: 'датчик открытия',
        },
        REMOTE_LEARNING: {
            en: 'Command id for learning mode',
            ru: 'Идентификатор команды для обучения',
        },
        LEARNING_STATUS: {
            en: 'Status of last learning',
            ru: 'Статус последнего обучения',
        },
        CODE_MODEL: {
            en: 'Model code',
            ru: 'Код модели',
        },
        TEMPERATURE_DIFF: {
            en: 'Temperature difference for sending a report',
            ru: 'Разница температур для отправки отчёта',
        },
        REPORT_INTERVAL: {
            en: 'Report sending interval',
            ru: 'Интервал отправки отчёта',
        },
        SMOKEs: {
            en: 'SMOKE',
            ru: 'датчики дыма',
        },
        GASs: {
            en: 'GAS',
            ru: 'датчики газа',
        },
        LEAKs: {
            en: 'LEAK',
            ru: 'датчики протечки',
        },
        MOTIONs: {
            en: 'MOTION',
            ru: 'датчики движения',
        },
        GLASS_BREAKAGEs: {
            en: 'GLASS_BREAKAGE',
            ru: 'датчики разбития стекла',
        },
        OPEN_CLOSEs: {
            en: 'OPEN_CLOSE',
            ru: 'датчики открытия',
        },
        UNKNOWN_TYPE: {
            en: 'UNKNOWN_TYPE',
            ru: 'неизвестное устройство',
        },
        ONLINE: {
            en: 'Online',
            ru: 'Включено',
        },
        OFFLINE: {
            en: 'no connection',
            ru: 'нет связи',
        },
        DISABLED: {
            en: 'disabled',
            ru: 'отключен',
        },
        UNAVAILABLE: {
            en: 'unavailable',
            ru: 'недоступно',
        },
        INITIALIZING: {
            en: 'initializing',
            ru: 'инициализация',
        },
        REQUEST_ERROR: {
            en: 'interview error',
            ru: 'ошибка опроса',
        },
        TAMPER: {
            en: 'tamper!',
            ru: 'вскрытие!',
        },
        EnterModelCode: {
            en: 'Enter model code',
            ru: 'Введите код модели',
        },
        ModelCodeCustom: {
            en: 'Train device manually',
            ru: 'Обучить устройство вручную',
        },
        ModelCodePreset: {
            en: 'Set model code',
            ru: 'Задать код модели',
        },
        DeviceSettings: {
            en: 'Settings of Device',
            ru: 'Настройки устройства',
        },
        DeviceLearning: {
            en: 'Learning',
            ru: 'Обучение',
        },
        RunDeviceCommandError: {
            en: 'Command sending error',
            ru: 'Ошибка отправки команды',
        },
        RunDeviceCommand: {
            en: 'Run command',
            ru: 'Выполнить команду',
        },
        RunDeviceCommandX: {
            en: 'Run command "#X"',
            ru: 'Выполнить команду "#X"',
        },
        ControllerSettings: {
            en: 'Settings of Controller',
            ru: 'Настройки контроллера',
        },
        AddingControllerInOfflineMode: {
            en: 'Adding a controller in offline mode',
            ru: 'Добавление контроллера в оффлайн режиме',
        },
        backToDevices: {
            en: 'back to Devices',
            ru: 'вернуться к устройствам',
        },
        deviceNotFound: {
            en: 'Device not found',
            ru: 'Устройство не найдено',
        },
        AddingNewDeviceInOfflineMode: {
            en: 'Adding of new device in offline mode',
            ru: 'Добавление нового устройства в оффлайн режиме',
        },
        AddingNewDeviceInOnlineMode: {
            en: 'Adding of new device in online mode',
            ru: 'Добавление нового устройства в онлайн режиме',
        },
        OfflineMode: {
            en: 'Offline mode',
            ru: 'Оффлайн режим',
        },
        OnlineMode: {
            en: 'Online mode',
            ru: 'Онлайн режим',
        },
        EnterQrCode: {
            en: 'Enter data from the QR-code on your device',
            ru: 'Введите данные с QR-кода на Вашем устройстве',
        },
        AddingNewDevice: {
            en: 'Adding of New Device',
            ru: 'Добавление нового устройства',
        },
        RemovingDevice: {
            en: 'Removing of Device',
            ru: 'Удаление устройства',
        },
        DeviceName: {
            en: 'Device Name',
            ru: 'Название устройства',
        },
        enterDeviceName: {
            en: 'please, enter the name of device',
            ru: 'введите название устройства',
        },
        Room: {
            ru: 'Комната',
        },
        DeviceID: {
            en: 'ID',
            ru: 'ID устройства',
        },
        Battery: {
            ru: 'Заряд',
        },
        Status: {
            ru: 'Состояние',
        },
        InterviewPeriod: {
            en: 'Interview period, min',
            ru: 'Период опроса, мин',
        },
        InterviewPeriodOff: {
            en: 'Off',
            ru: 'Выкл.',
        },
        InterviewPeriodX: {
            en: '#period',
            ru: '#period',
        },
        OPEN_CLOSEtrue: {
            en: 'open',
            ru: 'открыто',
        },
        OPEN_CLOSEfalse: {
            en: 'close',
            ru: 'закрыто',
        },
        LEAKtrue: {
            en: 'leak',
            ru: 'протечка',
        },
        LEAKfalse: {
            en: 'no leak',
            ru: 'нет протечки',
        },
        SMOKEtrue: {
            en: 'smoke',
            ru: 'задымление',
        },
        SMOKEfalse: {
            en: 'no smoke',
            ru: 'нет задымления',
        },
        GAStrue: {
            en: 'gas',
            ru: 'утечка газа',
        },
        GASfalse: {
            en: 'no gas',
            ru: 'нет утечки газа',
        },
        MOTIONtrue: {
            en: 'motion',
            ru: 'движение',
        },
        MOTIONfalse: {
            en: 'no motion',
            ru: 'нет движения',
        },
        GLASS_BREAKAGEtrue: {
            en: 'breaking',
            ru: 'фиксируется разбитие',
        },
        GLASS_BREAKAGEfalse: {
            en: 'online',
            ru: 'включено',
        },
        TAMPERtrue: {
            en: 'tamper',
            ru: 'вскрытие корпуса',
        },
        TAMPERfalse: {
            en: 'device closed',
            ru: 'закрытие корпуса',
        },
        EMERGENCYfalse: {
            en: 'no alarm',
            ru: 'нет тревоги',
        },
        EMERGENCYtrue: {
            en: 'alarm',
            ru: 'тревога',
        },
        SWITCH_MULTI_LEVEL_VALUE: {
            en: 'level: #level',
            ru: 'уровень: #level',
        },
        BATTERY: {
            en: 'Battery',
            ru: 'Заряд',
        },
        BATTERY_UNIT: {
            en: '%',
            ru: '%',
        },
        VOC: {
            en: 'Volatile organic compounds content in the air',
            ru: 'Содержание летучих органических веществ в воздухе',
        },
        VOC_UNIT: {
            en: 'ppb',
            ru: 'ppb',
        },
        VOC_LEVEL: {
            en: 'Volatile organic compounds level index in the air',
            ru: 'Индекс уровня летучих органических веществ в воздухе',
        },
        CO2: {
            en: 'Carbon dioxide content in the air',
            ru: 'Содержание углекислого газа в воздухе',
        },
        CO2_UNIT: {
            en: 'ppm',
            ru: 'ppm',
        },
        CO2_ALARM: {
            en: 'Significant presence of carbon dioxide in the air',
            ru: 'Значительное присутствие углекислого газа в воздухе',
        },
        CO2_ALARM_false: {
            en: 'No',
            ru: 'Нет',
        },
        CO2_ALARM_true: {
            en: 'Yes',
            ru: 'Есть',
        },
        VOC_LEVEL_0: {
            en: 'Not initialized',
            ru: 'Не проинициализирован',
        },
        VOC_LEVEL_1: {
            en: 'Clean',
            ru: 'Чистый',
        },
        VOC_LEVEL_2: {
            en: 'Slightly polluted',
            ru: 'Слегка загрязнен',
        },
        VOC_LEVEL_3: {
            en: 'Moderately polluted',
            ru: 'Умеренно загрязненный',
        },
        VOC_LEVEL_4: {
            en: 'Highly polluted',
            ru: 'Сильно загрязнен',
        },
        COOLING: {
            en: 'Temperature limit under',
            ru: 'Температура ниже порога',
        },
        HEAT: {
            en: 'Temperature limit over',
            ru: 'Превышение порога по температуре',
        },
        HEAT_0: {
            en: 'Temperature is normal',
            ru: 'Температура в норме',
        },
        HEAT_1: {
            en: 'Temperature above threshold',
            ru: 'Температура выше порога',
        },
        COOLING_0: {
            en: 'Temperature is normal',
            ru: 'Температура в норме',
        },
        COOLING_1: {
            en: 'Temperature below threshold',
            ru: 'Температура ниже порога',
        },
        CO2_ALARMfalse_CONDITION: {
            en: 'Carbon dioxide levels are normal',
            ru: 'Уровень углекислого газа в норме',
        },
        CO2_ALARMtrue_CONDITION: {
            en: 'Significant presence of carbon dioxide in the air',
            ru: 'Значительное присутствие углекислого газа в воздухе',
        },
        TEMPERATURE_CONDITION: {
            en: 'temperature value',
            ru: 'значение температуры',
        },
        LUMINOSITY_CONDITION: {
            en: 'luminosity value',
            ru: 'значение освещенности',
        },
        HUMIDITY_CONDITION: {
            en: 'humidity value',
            ru: 'значение влажности',
        },
        BATTERY_CONDITION: {
            en: 'battery value',
            ru: 'значение батареи',
        },
        LT: {
            en: 'smaller',
            ru: 'меньше',
        },
        GT: {
            en: 'bigger',
            ru: 'больше',
        },
        RecommendedRangeOfValues: {
            en: 'Recommended Range Of Values at #min to #max',
            ru: 'Рекоммендуемый диапазон значений от #min до #max',
        },
        UNKNOWN_TYPEvalue: {
            en: 'unknown mode',
            ru: 'неизвестный режим',
        },
        noAvailableController: {
            en: 'There is no available controller',
            ru: 'Нет доступного контроллера',
        },
        abortOperation: {
            en: 'Are you sure you want to abort the operation?',
            ru: 'Вы уверены, что хотите прервать операцию?',
        },
        chooseController: {
            en: 'Choose controller',
            ru: 'Выберите контроллер',
        },
        deviceAddMode: {
            en: 'For adding press button on device 3 times',
            ru: 'Для добавления нажмите кнопку на устройстве 3 раза',
        },
        deviceRemoveMode: {
            en: 'For removing press button on device 3 times',
            ru: 'Для удаления нажмите кнопку на устройстве 3 раза',
        },
        deviceRemoveDeadMode: {
            en: 'Wait...',
            ru: 'Подождите...',
        },
        deviceAddERROR: {
            en: 'Add device error!',
            ru: 'Ошибка добавления устройства!',
        },
        deviceAddERROR_TIMEOUT: {
            en: 'Timeout error! Device was not switched to the add mode.',
            ru: 'Превышено время ожидания! Устройство не было переведено в режим добавления.',
        },
        deviceDataReceivedErrorTimeout: {
            en: 'Timeout error while waiting for data from device!',
            ru: 'Превышено время ожидания данных от устройства!',
        },
        deviceRemoveERROR: {
            en: 'Remove device error!',
            ru: 'Ошибка удаления устройства!',
        },
        deviceRemoveERROR_TIMEOUT: {
            en: 'Timeout error! Device was not switched to the remove mode.',
            ru: 'Превышено время ожидания! Устройство не было переведено в режим удаления.',
        },
        deviceRemoveCtlOffline: {
            en: 'Remove device error! Controller is offline.',
            ru: 'Ошибка удаления устройства! Контроллер не доступен.',
        },
        CantDeviceRemove: {
            en: 'Controller is offline. Removing device is not available!',
            ru: 'Контроллер отключен. Удаление устройства недоступно!',
        },
        GetMetersGroupsError: {
            en: 'Meters groups load error',
            ru: 'Ошибка загрузки списка групп'
        },
        GetDeviceDataError: {
            en: 'Get device data error',
            ru: 'Ошибка получения данных устройства'
        },
        ToDevicesPage: {
            en: 'To devices page',
            ru: 'Перейти на страницу устройств',
        },
        '#': {
            ru: '№',
        },
        AddrCount: {
            en: 'Addr/Count',
            ru: 'Адрес/Счёт',
        },
        AptN: {
            en: 'Apt #N',
            ru: 'кв. #N',
        },
        Apt: {
            en: 'Apt',
            ru: 'кв.',
        },
        Impulse: {
            en: 'Imp.',
            ru: 'Имп.',
        },
        Circuit: {
            en: 'Circuit',
            ru: 'Шлейф',
        },
        METER_COMPLEX: {
            en: 'METER_COMPLEX',
            ru: 'cчетчик',
        },
        dataMETER_COMPLEX: {
            en: 'Data METER_COMPLEX',
            ru: 'Данные cчетчика',
        },
        METER_COMPLEXs: {
            en: 'METER_COMPLEX',
            ru: 'cчетчики',
        },
        Meters: {
            ru: 'Счетчики',
        },
        SOCKET: {
            en: 'Socket',
            ru: 'Розетка',
        },
        SOCKETs: {
            en: 'socket',
            ru: 'розетки',
        },
        ON_OFFtrue: {
            en: 'turned on',
            ru: 'включено',
        },
        ON_OFFfalse: {
            en: 'turned off',
            ru: 'выключено',
        },
        impulseNow: {
            en: 'Impulse',
            ru: 'Импульсы',
        },
        kwh: {
            en: 'kW⋅h',
            ru: 'кВт⋅ч',
        },
        m3: {
            en: 'm³',
            ru: 'м³',
        },
        unit_HotWaterVolIn: {
            en: '(hw)',
            ru: '(гв)',
        },
        unit_ColdWaterVolIn: {
            en: '(cw)',
            ru: '(хв)',
        },
        imp: {
            en: 'imp',
            ru: 'имп',
        },
        Measure: {
            en: 'Measure',
            ru: 'Показания',
        },
        currentMeasure: {
            en: 'Current Measure',
            ru: 'Текущее показание',
        },
        divisionValue: {
            en: 'Division Value',
            ru: 'Цена импульса',
        },
        enterDivisionValue: {
            en: 'please, enter the division value',
            ru: 'введите цену импульса',
        },
        SerialNumber: {
            en: 'Serial Number',
            ru: 'Серийный номер',
        },
        enterSerialNumber: {
            en: 'please, enter the serial number',
            ru: 'введите серийный номер',
        },
        Group: {
            en: 'Group',
            ru: 'Группа учета',
        },
        SubGroup: {
            en: 'Sub group',
            ru: 'Подгруппа',
        },
        WithoutGroup: {
            en: 'Without group',
            ru: 'Без группы',
        },
        type_UNKNOWN: {
            en: 'Group not selected',
            ru: 'Группа не выбрана',
        },
        type_HotWaterVolIn: {
            en: 'Hot water',
            ru: 'Горячая вода',
        },
        type_HotWaterVolInChannel: {
            en: 'Hot water, channel #number',
            ru: 'Горячая вода, канал #number',
        },
        type_HotWaterVolInChannelTitle: {
            en: 'Hot water, channel #channelTitle',
            ru: 'Горячая вода, канал #channelTitle',
        },
        type_ColdWaterVolIn: {
            en: 'Cold water',
            ru: 'Холодная вода',
        },
        type_ColdWaterVolInChannel: {
            en: 'Cold water, channel #number',
            ru: 'Холодная вода, канал #number',
        },
        type_ColdWaterVolInChannelTitle: {
            en: 'Cold water, channel #channelTitle',
            ru: 'Холодная вода, канал #channelTitle',
        },
        type_HeatEnergyQImpulse: {
            en: 'Heat energy',
            ru: 'Тепловая энергия',
        },
        type_HeatEnergyQImpulseChannel: {
            en: 'Heat energy, channel #number',
            ru: 'Тепловая энергия, канал #number',
        },
        type_HeatEnergyQImpulseChannelTitle: {
            en: 'Heat energy, channel #channelTitle',
            ru: 'Тепловая энергия, канал #channelTitle',
        },
        type_ElectricEnergy: {
            en: 'Electric energy',
            ru: 'Электроэнергия',
        },
        type_ElectricEnergyChannel: {
            en: 'Electric energy, channel #number',
            ru: 'Электроэнергия, канал #number',
        },
        type_ElectricEnergyChannelTitle: {
            en: 'Electric energy, channel #channelTitle',
            ru: 'Электроэнергия, канал #channelTitle',
        },
        HotWater: {
            en: 'Hot water',
            ru: 'Горячее водоснабжение',
        },
        ColdWater: {
            en: "Cold water",
            ru: "Холодное водоснабжение"
        },
        NoReports: {
            en: 'No Reports',
            ru: 'Отчёты отсутствуют',
        },
        Break: {
            ru: 'Прервать',
        },
        address: {
            en: 'Address',
            ru: 'Адрес',
        },
        personal: {
            en: 'Personal',
            ru: 'Лицевой счет',
        },
        per: {
            ru: 'л/с',
        },
        enterPersonal: {
            en: 'please, enter the personal data',
            ru: 'введите лицевой счет',
        },
        MeteringDevice: {
            en: 'Metering device',
            ru: 'Прибор учета',
        },
        Individual: {
            en: 'Individual',
            ru: 'Индивидуальный',
        },
        Common: {
            en: 'Common',
            ru: 'Общедомовой',
        },
        EnterData: {
            en: 'Enter data',
            ru: 'Введите данные',
        },
        ErrorIncorrectData: {
            en: 'Error! Incorrect data',
            ru: 'Ошибка! Некорректные данные',
        },
        GuardMode: {
            en: 'Guard mode',
            ru: 'Режим охраны',
        },
        KeepAliveEditingTooltip: {
            en: 'Editing is available when the guard mode is off',
            ru: 'Редактирование доступно, когда режим охраны выключен',
        },
        WakeupInterval: {
            en: 'Wakeup interval',
            ru: 'Частота пробуждения устройства',
        },
        KeepAlive: {
            en: 'Keep alive',
            ru: 'Период опроса',
        },
        GuardStatus: {
            en: 'Guard status',
            ru: 'Состояние охранного режима',
        },
        GUARD_OK: {
            en: 'Guard mode is on',
            ru: 'Охранный режим включен',
        },
        GUARD_OFF: {
            en: 'Guard mode is off',
            ru: 'Охранный режим выключен',
        },
        GUARD_INIT: {
            en: 'Guard mode is initialising',
            ru: 'Режим охраны инициализируется',
        },
        GUARD_FAIL: {
            en: 'Guard mode fail',
            ru: 'Сбой режима охраны',
        },
        DontNotify: {
            en: "Don't notify",
            ru: 'Без уведомлений',
        },
        IgnoreTheDevice: {
            en: 'Ignore the device',
            ru: 'Игнорировать устройство',
        },
        Controller: {
            ru: 'Контроллер',
        },
        InnerControllerTitle: {
            en: 'Inner controller',
            ru: 'Внутренний контроллер',
        },
        deviceType: {
            en: 'Device type',
            ru: 'Тип устройства',
        },
        ALL: {
            en: 'all',
            ru: 'все',
        },
        ANY_CONTROLLER: {
            en: 'any controller',
            ru: 'любой контроллер',
        },
        ANY_TYPE: {
            en: 'any device',
            ru: 'все устройства',
        },
        ANY_STATUS: {
            en: 'any status',
            ru: 'любое состояние',
        },
        valueTrue: {
            en: 'property value is true',
            ru: 'сработали',
        },
        valueFalse: {
            en: 'property value is false',
            ru: 'не сработали',
        },
        DISABLEDs: {
            en: 'disabled',
            ru: 'отключены',
        },
        Chart: {
            ru: 'График',
        },
        lineChart: {
            en: 'Line Chart',
            ru: 'Линейный график',
        },
        barChart: {
            en: 'Bar Chart',
            ru: 'Столбчатая диаграмма',
        },
        Min: {
            en: 'Minimum',
            ru: 'Минимум',
        },
        Max: {
            en: 'Maximum',
            ru: 'Максимум',
        },
        Avg: {
            en: 'Average',
            ru: 'Среднее',
        },
        CurrentValue: {
            en: 'Current value',
            ru: 'Текущее значение',
        },
        notDefined: {
            en: 'not defined',
            ru: 'не определено',
        },
        locationInfoMessage: {
            en: 'Make the groups in',
            ru: 'Задайте группы, перейдя в',
        },
        ElectricityRecorder: {
            en: 'Electricity Recorder',
            ru: 'Регистратор эл/энергии',
        },
        WaterRecorder: {
            en: 'Water Recorder',
            ru: 'Регистратор воды',
        },
        NoAccident: {
            en: 'No Accident',
            ru: 'Авария отсутствует',
        },
        Attention: {
            en: 'attention!',
            ru: 'внимание!',
        },
        AccidentComment: {
            en: 'The counter has not sent data for a long time. There may be a disconnect between Registrar - Counter. If there is no interruption of the connection, click "No Accident"',
            ru: 'Счетчик давно не присылал данные. Возможно, произошел разрыв соединения Регистратор - Счетчик. Если нарушения соединения нет, нажмите "Авария отсутствует"',
        },
        ChoosedGroup: {
            en: 'Select a group to display the readings correctly',
            ru: 'Выберите группу учета для корректного отображения показаний'
        },
        AccidentComment_TAMPER: {
            en: 'The device was opened. Make sure that the device case is closed, click "Remove warning".',
            ru: 'Устройство было вскрыто. Убедитесь, что корпус устройства закрыт, нажмите "Снять предупреждение".'
        },
        AccidentComment_GLASS_BREAKAGE: {
            en: 'The sensor was triggered. Make sure that the glass undamaged, click "Remove warning".',
            ru: 'Произошла сработка датчика. Убедитесь, что стекло целое, нажмите "Снять предупреждение".'
        },
        AccidentCommentWidget_TAMPER: {
            en: 'The device was opened. Make sure that the device case is closed and remove warning on device page.',
            ru: 'Устройство было вскрыто. Убедитесь, что корпус устройства закрыт, и снимите предупреждение на странице устройства.'
        },
        AccidentCommentWidget_GLASS_BREAKAGE: {
            en: 'The sensor was triggered. Make sure that the glass undamaged and remove warning on device page.',
            ru: 'Произошла сработка датчика. Убедитесь, что стекло целое, и снимите предупреждение на странице устройства.'
        },
        RemoveWarning: {
            en: 'Remove warning',
            ru: 'Снять предупреждение'
        },
        //controllers
        CONNECTED: {
            en: 'Connected',
            ru: 'Доступен',
        },
        DISCONNECTED: {
            en: 'Disconnected',
            ru: 'Недоступен',
        },
        VIRTUAL: {
            en: 'Virtual',
            ru: 'Виртуальный',
        },
        SYNC_IN_PROGRESS: {
            en: 'Synchronization',
            ru: 'Синхронизация',
        },
        NEW: {
            en: 'New',
            ru: 'Новый',
        },
        REMOVING: {
            en: 'Removing',
            ru: 'Удаление',
        },
        FAILED: {
            en: 'Failed',
            ru: 'Нерабочий',
        },
        BACKUP_IN_PROGRESS: {
            en: 'Backup in progress',
            ru: 'Резервное копирование',
        },
        AddingNewController: {
            en: 'Adding of New Controller',
            ru: 'Добавление нового контроллера',
        },
        BackupSettings: {
            en: 'Backup settings',
            ru: 'Резервное копирование настроек',
        },
        BackupSettingsCreation: {
            en: 'Creation',
            ru: 'Создание',
        },
        BackupSettingsRecovery: {
            en: 'Recovery',
            ru: 'Восстановление',
        },
        BackupSettingsTransfer: {
            en: 'Transfer',
            ru: 'Перенос',
        },
        ControllerBackupError: {
            en: 'Backing up settings failed',
            ru: 'Создать резервную копию не удалось',
        },
        ControllerBackupSuccess: {
            en: 'Controller backup initialized',
            ru: 'Создание резервной копии инициализировано',
        },
        NoControllerBackups: {
            en: 'No settings backups for this controller',
            ru: 'Нет резервных копий настроек для этого контроллера',
        },
        GetControllerBackupsError: {
            en: 'Error getting backup list',
            ru: 'Ошибка получения списка резервных копий',
        },
        RecoveryControllerBackupError: {
            en: 'Error restoring settings from backup',
            ru: 'Ошибка восстановления настроек из резервной копии',
        },
        RecoveryControllerBackupSuccess: {
            en: 'Request to restore settings from backup successfully sent',
            ru: 'Запрос на восстановление настроек из резервной копии успешно отправлен',
        },
        TransferControllerBackupError: {
            en: 'Error transferring settings backup',
            ru: 'Ошибка переноса резервной копии настроек',
        },
        TransferControllerBackupSuccess: {
            en: 'Request to transfer settings backup successfully sent',
            ru: 'Запрос на перенос резервной копии настроек успешно отправлен',
        },
        ControllerBackupConflict: {
            en: 'The controller is currently busy with another process. Please try again later.',
            ru: 'В данный момент контроллер занят другим процессом. Попробуйте позднее.',
        },
        SelectBackup: {
            en: 'Select backup',
            ru: 'Выберите резервную копию',
        },
        SelectControllerForBackupSettingsTransfer: {
            en: 'Select controller for backup settings transfer',
            ru: 'Выберите контроллер для переноса резервной копии настроек',
        },
        macController: {
            en: "Connect the Controller to the Network. Enter the Controller's MAC",
            ru: 'Подключите контроллер в сеть. Введите mac-адрес контроллера',
        },
        removeController: {
            en: 'Are you sure that you want to remove the controller?',
            ru: 'Вы уверены, что хотите удалить контроллер?',
        },
        removeDevice: {
            en: 'Are you sure that you want to remove the device?',
            ru: 'Вы уверены, что хотите удалить устройство?',
        },
        unlinkController: {
            en: 'Are you sure that you want to unlink the controller?',
            ru: 'Вы уверены, что хотите отвязать контроллер?',
        },
        controllerPostError404: {
            en: 'Controller with this MAC is not found!',
            ru: 'Контроллер с таким MAC-адресом не найден!',
        },
        controllerPostError409mac: {
            en: 'Controller with this MAC already exists!',
            ru: 'Контроллер с таким MAC-адресом уже добавлен!',
        },
        controllerPostError409uuid: {
            en: 'Controller with this uuid already exists! Please, reset controller to factory settings or contact technical support',
            ru: 'Контроллер с таким  идентификационным номером уже зарегистрирован в системе! Пожалуйста, сбросьте контроллер на заводские настройки или обратитесь в техподдержку',
        },
        controllerPostError409duplicate_ethernet: {
            en: 'Ethernet Controller with this IP already exists!',
            ru: 'Ethernet-контроллер с таким IP-адресом уже добавлен!',
        },
        CtlBoundIpError: {
            en: 'The IP field must be a valid ip address or FQDN.',
            ru: 'Поле IP должно быть действительным IP-адресом или полным доменным именем.',
        },
        Details: {
            ru: 'Подробности',
        },

        // scripts
        Script: {
            en: 'Script',
            ru: 'Сценарий',
        },
        enterScriptName: {
            en: 'enter script name',
            ru: 'введите название сценария',
        },
        ScriptName: {
            en: 'Script Name',
            ru: 'Название сценария',
        },
        ScriptSettings: {
            en: 'Script Settings',
            ru: 'Настройки сценария',
        },
        GoToEditig: {
            en: 'Go to editing',
            ru: 'Перейти к редактированию',
        },
        ViewScript: {
            en: 'View script',
            ru: 'Просмотр сценария',
        },
        CameraSupportError: {
            en: 'Camera with id=#id is no longer supported in scripts. Change the camera settings or select a different camera.',
            ru: 'Камера с id=#id больше не поддерживается в скриптах. Измените её параметры в настройках или выберите другую камеру.',
        },
        errorOpeningScript: {
            en: 'Error while opening script witch id=#id',
            ru: 'Ошибка открытия сценария с id=#id',
        },
        backToScripts: {
            en: 'back to scripts',
            ru: 'вернуться к списку сценариев',
        },
        AddingNewScript: {
            en: 'Adding of new script',
            ru: 'Добавление нового сценария',
        },
        ScriptsAddSuccess: {
            en: 'Scripts successfully added',
            ru: 'Сценарии успешно добавлены',
        },
        CurrentStatus: {
            en: 'Current Status',
            ru: 'Текущее состояние',
        },
        ScriptStatusREADY: {
            en: 'Ready',
            ru: 'Готов к запуску',
        },
        ScriptStatusRUNNING: {
            en: 'Running',
            ru: 'Запущен',
        },
        ScriptDevicesDescription: {
            en: 'If you want to run script without device triggering, then do not add devices, but only set the start time in the "When to run" block in the "Time is from" field.',
            ru: 'Если Вы хотите, чтобы сценарий запускался вне зависимости от датчиков, тогда не добавляйте условия по срабатыванию устройства, а только установите время запуска в блоке "Когда запускать" в поле "Время от".',
        },
        h: {
            en: 'h',
            ru: 'ч',
        },
        m: {
            en: 'm',
            ru: 'м',
        },
        s: {
            en: 's',
            ru: 'с',
        },
        Actions: {
            ru: 'Действия',
        },
        SET_HOUSE_VARIABLEScriptType: {
            en: 'House variable',
            ru: 'Переменная дома',
        },
        SET_DEVICE_PROPERTYScriptType: {
            en: 'Device',
            ru: 'Устройство',
        },
        Set: {
            en: 'Set',
            ru: 'Установить',
        },
        RECORD_VIDEOScriptType: {
            en: 'Camera',
            ru: 'Камера',
        },
        ProtectingFragmentOfCamera: {
            en: 'protecting fragment of camera recording',
            ru: 'защита фрагмента записи',
        },
        ProtectFragmentOfCamera: {
            en: 'Protect fragment of camera recording',
            ru: 'Защитить фрагмент записи камеры',
        },
        DELAYScriptType: {
            en: 'Set delay',
            ru: 'Задержка',
        },
        SEND_NOTIFICATIONScriptType: {
            en: 'Send notification',
            ru: 'Послать уведомление',
        },
        EMAILNotificationType: {
            en: 'by e-mail',
            ru: 'на e-mail',
        },
        SMSNotificationType: {
            en: 'by SMS to phone',
            ru: 'SMS на телефон',
        },
        Subject: {
            ru: 'Тема',
        },
        Message: {
            ru: 'Сообщение',
        },
        EnterMessage: {
            en: 'Enter message',
            ru: 'Введите свое сообщение',
        },
        Conditions: {
            ru: 'Условия',
        },
        noConditions: {
            en: 'There is no conditions',
            ru: 'Нет условий',
        },
        noActions: {
            en: 'There is no actions',
            ru: 'Нет действий',
        },
        DEVICE_PROPERTYRuleType: {
            en: 'If device',
            ru: 'Если устройство',
        },
        HOUSE_VARIABLERuleType: {
            en: 'If house mode',
            ru: 'Если режим дома',
        },
        TIMERuleType: {
            en: 'When to run',
            ru: 'Когда запускать',
        },
        returns: {
            ru: 'возвращает',
        },
        correspondTo: {
            en: 'correspond to',
            ru: 'соответствует',
        },
        notCorrespondTo: {
            en: 'not correspond to',
            ru: 'не соответствует',
        },
        nextStartDate: {
            en: 'Next start date',
            ru: 'День следующего запуска',
        },
        TIME_OF_DAYTypeOfTime: {
            en: 'Time',
            ru: 'Время запуска',
        },
        DAY_OF_WEEKTypeOfTime: {
            en: 'Days of week',
            ru: 'День запуска',
        },
        isFrom: {
            en: 'is from',
            ru: 'от',
        },
        fromTo: {
            en: 'from #from to #to',
            ru: 'с #from по #to',
        },
        to: {
            ru: 'до',
        },
        for: {
            en: 'for',
            ru: 'за',
        },
        '01': {
            en: 'January',
            ru: 'Январь',
        },
        '02': {
            en: 'February',
            ru: 'Февраль',
        },
        '03': {
            en: 'March',
            ru: 'Март',
        },
        '04': {
            en: 'April',
            ru: 'Апрель',
        },
        '05': {
            en: 'May',
            ru: 'Май',
        },
        '06': {
            en: 'June',
            ru: 'Июнь',
        },
        '07': {
            en: 'July',
            ru: 'Июль',
        },
        '08': {
            en: 'August',
            ru: 'Август',
        },
        '09': {
            en: 'September',
            ru: 'Сентябрь',
        },
        '10': {
            en: 'October',
            ru: 'Октябрь',
        },
        '11': {
            en: 'November',
            ru: 'Ноябрь',
        },
        '12': {
            en: 'December',
            ru: 'Декабрь',
        },
        weekDay0: {
            en: 'Mo',
            ru: 'пн',
        },
        weekDay1: {
            en: 'Tu',
            ru: 'вт',
        },
        weekDay2: {
            en: 'We',
            ru: 'ср',
        },
        weekDay3: {
            en: 'Th',
            ru: 'чт',
        },
        weekDay4: {
            en: 'Fr',
            ru: 'пт',
        },
        weekDay5: {
            en: 'Sa',
            ru: 'сб',
        },
        weekDay6: {
            en: 'Su',
            ru: 'вс',
        },
        daysIsNotSelected: {
            en: 'days is not selected',
            ru: 'дни не выбраны',
        },
        or: {
            en: 'or',
            ru: 'или',
        },
        and: {
            en: 'and',
            ru: 'и',
        },
        if: {
            en: 'if',
            ru: 'если',
        },
        then: {
            en: 'then',
            ru: 'тогда',
        },
        DoYouWantDeleteScript: {
            en: 'Do you really want to delete script "#name"?',
            ru: 'Вы на самом деле хотите удалить сценарий "#name"?',
        },
        Importance: {
            en: 'Importance',
            ru: 'Важность',
        },
        NORMAL: {
            en: 'normal',
            ru: 'нормальная',
        },
        OPERATOR: {
            en: 'operator',
            ru: 'операторская',
        },
        SYSTEM: {
            en: 'system',
            ru: 'системная',
        },
        CRITICAL: {
            en: 'critical',
            ru: 'критическая',
        },
        AddNewCondition: {
            en: 'Add New Condition',
            ru: 'Добавить условие',
        },
        SelectCondition: {
            en: 'Select condition',
            ru: 'Выберите условие',
        },
        AddNewRule: {
            en: 'Add new rule',
            ru: 'Добавить правило',
        },
        AddNewConditionDevice: {
            en: 'Add New Condition for Device',
            ru: 'Добавить условие по срабатыванию устройства',
        },
        AddNewConditionMode: {
            en: 'Add New Condition for Mode',
            ru: 'Добавить условие по смене режима',
        },
        AddNewConditionTime: {
            en: 'Add New Condition for Time',
            ru: 'Добавить условие по времени',
        },
        AddNewAction: {
            en: 'Add New Action',
            ru: 'Добавить действие',
        },

        //events
        NoEventsBecauseNoHouses: {
            en: 'You do not have any events, because there is no houses',
            ru: 'У Вас нет ни одного события, т.к. нет ни одного дома',
        },
        allEvents: {
            en: 'All events',
            ru: 'Все события',
        },
        ShowHouseEvents: {
            en: 'Show house events',
            ru: 'Показать события дома',
        },
        Today: {
            ru: 'Сегодня',
        },
        noEvents: {
            en: 'There is no events',
            ru: 'События отсутствуют',
        },
        Date: {
            en: 'Date',
            ru: 'Дата',
        },
        DateAndTime: {
            en: 'Date and time',
            ru: 'Дата и время',
        },
        Level: {
            ru: 'Уровень',
        },
        HouseEvents: {
            en: 'House Events',
            ru: 'События дома',
        },
        UserEvents: {
            en: 'User Actions',
            ru: 'Действия пользователя',
        },
        messageType: {
            en: 'Message type',
            ru: 'Тип сообщений',
        },
        messageALL: {
            en: 'all messages',
            ru: 'все сообщения',
        },
        messageUSER: {
            en: 'user',
            ru: 'пользовательские',
        },
        messageSYSTEM: {
            en: 'system',
            ru: 'системные',
        },
        messageCRITICAL: {
            en: 'critical',
            ru: 'критические',
        },
        categoryALL: {
            en: 'all category',
            ru: 'любая категория',
        },
        categoryDEVICE: {
            en: 'devices',
            ru: 'устройства',
        },
        categoryHOUSE: {
            en: 'house',
            ru: 'дом',
        },
        categoryCONTROLLER: {
            en: 'controllers',
            ru: 'контроллеры',
        },
        categoryRULE: {
            en: 'rules',
            ru: 'правила',
        },
        categorySCRIPT: {
            en: 'scripts',
            ru: 'сценарии',
        },
        categoryUSER: {
            en: 'user',
            ru: 'пользователь',
        },

        //current-measures
        ShouldBeANonNegativeNumber: {
            en: 'should be a non-negative number',
            ru: 'значение должно быть неотрицательным числом',
        },

        // buttons:
        Save: {
            ru: 'Сохранить',
        },
        SaveAndConfigure: {
            en: 'Save and configure',
            ru: 'Сохранить и настроить',
        },
        CreateHouse: {
            en: 'Create a New House',
            ru: 'Создать новый дом',
        },
        Apply: {
            ru: 'Применить',
        },
        ApplyFilter: {
            en: 'Apply filter',
            ru: 'Применить фильтр',
        },
        SortAlphabetically: {
            en: 'Sort alphabetically',
            ru: 'Сортировать по алфавиту',
        },
        SortByCreationDate: {
            en: 'Sort by creation date',
            ru: 'Сортировать по дате создания',
        },
        FindHouse: {
            en: 'Find house',
            ru: 'Найти дом',
        },
        Cancel: {
            ru: 'Отмена',
        },
        Run: {
            ru: 'Запустить',
        },
        Edit: {
            ru: 'Редактировать',
        },
        Refresh: {
            ru: 'Обновить',
        },
        Remove: {
            ru: 'Удалить',
        },
        RemoveController: {
            ru: 'Удалить контроллер',
            en: 'Remove controller',
        },
        ResetZWaveController: {
            en: 'Reset Z-Wave controller to factory settings',
            ru: 'Сбросить Z-Wave контроллер до заводских настроек',
        },
        ResetZWaveControllerPlanned: {
            en: 'Reset Z-Wave controller to factory settings planned',
            ru: 'Сброс Z-Wave контроллера до заводских настроек запланирован',
        },
        ResetZWaveControllerFailed: {
            en: 'Reset Z-Wave controller to factory settings failed',
            ru: 'Сбросить Z-Wave контроллер до заводских настроек не удалось',
        },
        RebootController: {
            en: 'Reboot controller',
            ru: 'Перезагрузить контроллер',
        },
        RebootControllerPlanned: {
            en: 'Reboot controller planned',
            ru: 'Перезагрузка контроллера запланирована',
        },
        RebootControllerFailed: {
            en: 'Reboot controller failed',
            ru: 'Перезагрузить контроллер не удалось',
        },
        RemoveDevice: {
            ru: 'Удалить устройство',
            en: 'Remove device',
        },
        RemoveScript: {
            ru: 'Удалить сценарий',
            en: 'Remove script',
        },
        RemoveUnavailableDevice: {
            ru: 'Удалить недоступное устройство',
            en: 'Remove unavailable device',
        },
        RemoveUnavailableDeviceError: {
            ru: 'Ошибка удаления устройства, попробуйте позже. Устройство ещё не перешло в состояние "Недоступно". Устройство считается недоступным, если оно находится без связи с контроллером 48 часов.',
            en: 'Error removing device, please try again later. Device is not yet "unavailable". Device is unavailable if it is not connected to controller for 48 hours.',
        },
        RemoveDeviceForcibly: {
            ru: 'Удалить устройство принудительно',
            en: 'Remove device forcibly',
        },
        RemoveDeviceForciblyError: {
            ru: 'Ошибка удаления устройства, попробуйте позже.',
            en: 'Error removing device, please try again later.',
        },
        WaitDeviceRemoving: {
            en: 'Wait, the device is removing.',
            ru: 'Подождите, идет удаление устройства.',
        },
        ConfirmRemoveDevice: {
            en: 'Do you really want to remove this device?',
            ru: 'Вы действительно хотите удалить это устройство?',
        },
        ConfirmAddDevice: {
            en: 'Add device',
            ru: 'Добавить устройства?',
        },
        Add: {
            en: 'Add',
            ru: 'Добавить',
        },
        Close: {
            ru: 'Закрыть',
        },
        Back: {
            ru: 'Назад',
        },
        Next: {
            ru: 'Далее',
        },

        // admin:
        AddANewAbonent: {
            en: 'Add a New Abonent',
            ru: 'Добавить нового абонента',
        },
        Log: {
            ru: 'Журнал',
        },
        Search: {
            en: 'Search',
            ru: 'Найти',
        },
        Login: {
            en: 'Login',
            ru: 'Логин',
        },
        LoginAbonent: {
            en: 'Login/Abonent',
            ru: 'Логин/Абонент',
        },
        LoginAccount: {
            en: 'Login/Account',
            ru: 'Логин/Аккаунт',
        },
        Password: {
            ru: 'Пароль',
        },
        passwordIsHidden: {
            en: 'password is hidden',
            ru: 'пароль скрыт',
        },
        Name: {
            ru: 'Имя',
        },
        Patronymic: {
            ru: 'Отчество',
        },
        Surname: {
            ru: 'Фамилия',
        },
        FullName: {
            en: 'Full name',
            ru: 'ФИО',
        },
        Phone: {
            ru: 'Телефон',
        },
        DoYouReallyWantToRemoveAbonentX: {
            en: 'Do you really want to remove abonent "#X"?',
            ru: 'Вы на самом деле хотите удалить абонента "#X"?',
        },
        ItsYou: {
            en: "it's you",
            ru: 'это вы',
        },
        UserType: {
            en: 'User type',
            ru: 'Тип пользователя',
        },
        Role: {
            en: 'User role',
            ru: 'Роль',
        },
        Abonent: {
            ru: 'Абонент',
        },
        Abonents: {
            ru: 'Абоненты',
        },
        UserIvideonTooltip: {
            en: 'The IVIDEON video platform is available for adding when the user has email or phone number saved.',
            ru: 'Видеоплатформа IVIDEON доступна для добавления, когда у пользователя сохранен email или телефон.',
        },
        QuickLoginToMobileApp: {
            en: 'Quick login to mobile app',
            ru: 'Быстрый вход в мобильное приложение',
        },
        GenerateQRCode: {
            en: 'Generate QRCode',
            ru: 'Генерация QR-Кода',
        },
        ServerAddress: {
            en: 'Server address',
            ru: 'Адрес сервера',
        },
        EnterServerAddress: {
            en: 'Enter server address',
            ru: 'Введите адрес сервера',
        },
        AccountPassword: {
            en: 'Account password',
            ru: 'Пароль от аккаунта',
        },
        Licenses: {
            en: 'Licenses',
            ru: 'Лицензии',
        },
        AddLicense: {
            en: 'Add license',
            ru: 'Добавить лицензию',
        },
        GetLicensesError: {
            en: 'Get licenses error',
            ru: 'Ошибка запроса лицензий',
        },
        AddLicenseError: {
            en: 'Add license error',
            ru: 'Ошибка добавления лицензии',
        },
        NoLicenses: {
            en: 'No Licenses',
            ru: 'Лицензий пока нет',
        },
        LicenseIsValid: {
            en: 'License is valid',
            ru: 'Лицензия действительна',
        },
        LICENSE_STATUS_VALID: {
            en: 'License is valid',
            ru: 'Лицензия действительна',
        },
        LICENSE_STATUS_INVALID: {
            en: 'License is invalid',
            ru: 'Лицензия недействительна',
        },
        LICENSE_STATUS_EXPIRED: {
            en: 'License is expired',
            ru: 'Истек срок действия лицензии',
        },
        LICENSE_STATUS_EXCEEDED: {
            en: 'License is exceeded',
            ru: 'Превышено количество устройств',
        },
        LICENSE_STATUS_IN_PROCESS: {
            en: 'License in progress',
            ru: 'Лицензия загружается',
        },
        LICENSE_TYPE_DEVICE: {
            en: 'Devices',
            ru: 'Устройства',
        },
        LICENSE_STATUS_REJECTED: {
            en: 'License is not verified',
            ru: 'Лицензия не верифицирована',
        },
        LICENSE_STATUS_: {
            en: 'License status is unknown',
            ru: 'Неизвестный статус лицензии',
        },
        Resend: {
            en: 'Resend',
            ru: 'Отправить повторно',
        },
        LICENSE_TYPE_: {
            en: 'unknown type',
            ru: 'неизвестный тип',
        },
        Administrator: {
            ru: 'Администратор',
        },
        ManagementCompany: {
            en: 'Management Company',
            ru: 'Управляющая компания',
        },
        Engineer: {
            en: 'Engineer',
            ru: 'Инженер',
        },
        SecurityEngineer: {
            en: 'Security engineer',
            ru: 'Инженер охраны',
        },
        user: {
            en: 'Abonent',
            ru: 'Абонент',
        },
        'management-company': {
            en: 'Management Company',
            ru: 'Управляющая компания',
        },
        admin: {
            en: 'Administrator',
            ru: 'Администратор',
        },
        engineer: {
            en: 'Engineer',
            ru: 'Инженер',
        },
        'security-watcher': {
            en: 'Security-watcher',
            ru: 'Пульт охраны',
        },
        'security-engineer': {
            en: 'Security engineer',
            ru: 'Инженер охраны',
        },
        Enabled: {
            ru: 'Включен',
        },
        Notifications: {
            ru: 'Оповещения',
        },
        Houses: {
            en: 'List of houses',
            ru: 'Список домов',
        },
        ALL_HOUSES: {
            en: 'All houses',
            ru: 'Все дома',
        },
        Operations: {
            ru: 'Операции',
        },
        RemoveUser: {
            en: 'Remove user',
            ru: 'Удалить пользователя',
        },
        YouShouldFillLogin: {
            en: 'You should fill login field',
            ru: 'Пожалуйста, заполните поле логина',
        },
        NoHouses: {
            en: 'No Houses',
            ru: 'Нет ни одного дома',
        },
        BackToAbonentList: {
            en: 'Back to abonent list',
            ru: 'Назад к списку абонентов',
        },
        AddNewHouse: {
            en: 'Add new house',
            ru: 'Добавить дом',
        },
        AddNewHouseError: {
            en: 'Add new house error',
            ru: 'Произошла ошибка при добавлении дома',
        },
        HouseNameEmptyError: {
            en: 'The house name can not be empty!',
            ru: 'Название дома не может быть пустым!',
        },
        AddNewDevice: {
            en: 'Add new device',
            ru: 'Добавить устройство',
        },
        AddNewController: {
            en: 'Add new controller',
            ru: 'Добавить контроллер',
        },
        AddNewScript: {
            en: 'Add new script',
            ru: 'Добавить сценарий',
        },
        AddAddress: {
            en: 'Add address',
            ru: 'Добавить адрес',
        },
        NoScriptsBecauseNoHouses: {
            en: 'You do not have any scripts, because there is no houses',
            ru: 'У Вас нет ни одного сценария, т.к. нет ни одного дома',
        },
        NoMeasuresBecauseNoHouses: {
            en: 'You do not have any measures, because there is no houses',
            ru: 'У Вас нет текущих показаний, т.к. нет ни одного дома',
        },
        YouDoNotHaveDevices: {
            en: 'You have not added any devices yet.',
            ru: 'Вы пока не добавили ни одного устройства.',
        },
        NoHouseVariables: {
            en: 'No house variables',
            ru: 'Нет переменных дома',
        },
        NoDevices: {
            en: 'No devices',
            ru: 'Нет устройств',
        },
        NoCameras: {
            en: 'No cameras',
            ru: 'Нет камер',
        },
        NoControllers: {
            en: 'No devices yet. To connect devices, add controller.',
            ru: 'Устройств пока нет. Для подключения устройств добавьте контроллер.',
        },
        NoScripts: {
            en: 'You do not have any scripts',
            ru: 'У Вас пока нет ни одного сценария',
        },
        NoDevicesBecauseNoHouses: {
            en: 'No devices yet. To connect devices, add house.',
            ru: 'Устройств пока нет. Для подключения устройств добавьте дом.',
        },
        NoScriptsBecauseNoControllers: {
            en: 'You do not have any script, because there is no controllers',
            ru: 'У Вас нет ни одного сценария, т.к. нет ни одного контроллера',
        },
        ConfirmRemoveHouse: {
            en: 'Do you really want to remove this house?',
            ru: 'Вы действительно хотите удалить этот дом?',
        },
        RemoveHouseError: {
            en: 'Remove house error',
            ru: 'Произошла ошибка при удалении дома',
        },
        MyHouse: {
            en: 'My house',
            ru: 'Мой дом',
        },
        House: {
            en: 'House',
            ru: 'Дом',
        },
        Object: {
            en: 'Object',
            ru: 'Объект'
        },
        Building: {
            en: 'Building',
            ru: 'Здание'
        },
        Shop: {
            en: 'Shop',
            ru: 'Магазин'
        },
        School: {
            en: 'School',
            ru: 'Школа'
        },
        BusinessCenter: {
            en: 'Business center',
            ru: 'Бизнес-центр'
        },
        GoToHouse: {
            en: 'Go to house',
            ru: 'Перейти к дому',
        },
        HouseError: {
            en: 'Error of getting House Info',
            ru: 'Ошибка получения данных дома',
        },
        ToControllersManagementPage: {
            en: 'To controllers management page',
            ru: 'Перейти на страницу управления контроллерами',
        },
        ToHouseVariablesManagementPage: {
            en: 'To variables editor',
            ru: 'Перейти в редактор переменных',
        },
        VariablesManagement: {
            en: 'Variables editor',
            ru: 'Редактор переменных',
        },
        HouseVariableName: {
            en: 'House variable name',
            ru: 'Название переменной',
        },
        DeviceCondition: {
            en: 'Device condition',
            ru: 'Состояние устройства',
        },
        DeviceModel: {
            en: 'Device model',
            ru: 'Модель устройства',
        },
        MacAddress: {
            en: 'MAC',
            ru: 'MAC-адрес',
        },
        HardwareVersion: {
            en: 'Hardware version',
            ru: 'Аппаратная версия',
        },
        SoftwareVersion: {
            en: 'Software version',
            ru: 'Версия ПО',
        },
        ControllerModel: {
            en: 'Controller model',
            ru: 'Модель контроллера',
        },
        ZWaveSubVersion: {
            en: 'Z-Wave sub version',
            ru: 'Версия Z-Wave субмодуля',
        },
        SdkSubVersion: {
            en: 'SDK sub version',
            ru: 'Версия SDK субмодуля',
        },
        DeviceVersion: {
            en: 'Device Version',
            ru: 'Версия устройства',
        },
        DeviceUuid: {
            en: 'Device UUID',
            ru: 'UUID устройства',
        },
        LinkUser: {
            en: 'Link user',
            ru: 'Привязать пользователя',
        },
        LinkError: {
            en: 'Link error',
            ru: 'Произошла ошибка при привязке пользователя',
        },
        UserNotFound: {
            en: 'User not found',
            ru: 'Такой пользователь не найден',
        },
        GetUserIdError: {
            en: 'Error getting user ID',
            ru: 'Ошибка получения идентификатора пользователя',
        },
        UserLinkedSuccessfully: {
            en: 'User linked successfully',
            ru: 'Пользователь привязан успешно',
        },
        UserLanguage: {
            en: 'User language',
            ru: 'Язык пользователя',
        },
        LoginEmptyError: {
            en: 'Login can not be empty!',
            ru: 'Введите логин!',
        },
        ConfirmUnlinkUser: {
            en: 'Do you really want to unlink this user?',
            ru: 'Вы действительно хотите открепить этого пользователя?',
        },
        ConfirmUnlinkHouse: {
            en: 'Do you really want to unlink this house?',
            ru: 'Вы действительно хотите отвязать этот дом?',
        },
        Unlink: {
            en: 'Unlink',
            ru: 'Открепить',
        },
        UnlinkUserError: {
            en: 'Unlink error',
            ru: 'Произошла ошибка при откреплении пользователя',
        },
        UnlinkHouseError: {
            en: 'Unlink error',
            ru: 'Произошла ошибка при отвязывании дома',
        },
        RemoveHouse: {
            en: 'Remove house',
            ru: 'Удалить дом',
        },
        UnlinkHouse: {
            en: 'Unlink house',
            ru: 'Отвязать дом',
        },
        CopyLoginToClipboard: {
            en: 'Copy login to clipboard',
            ru: 'Скопировать логин в буфер обмена',
        },
        Account: {
            en: 'Account',
            ru: 'Аккаунт',
        },
        ControllerRemoveError: {
            en: 'Remove controller error!',
            ru: 'Ошибка удаления контроллера!',
        },
        ControllerUnlinkError: {
            en: 'Unlink controller error!',
            ru: 'Ошибка отвязки контроллера!',
        },
        LinkAndUnlink: {
            en: 'Link/Unlink',
            ru: 'Привязать/Отвязать',
        },
        updateData: {
            en: 'Update Data',
            ru: 'Обновить данные',
        },
        ControllerManagement: {
            en: 'Controller management',
            ru: 'Управление контроллерами',
        },
        HouseAndAccount: {
            en: 'House/Account',
            ru: 'Дом/Аккаунт',
        },
        IpAddress: {
            en: 'IP',
            ru: 'IP',
        },
        LastActivity: {
            en: 'Last activity',
            ru: 'Последняя активность',
        },
        GetUsersError: {
            en: 'Get users error!',
            ru: 'Ошибка получения списка пользователей!',
        },
        GetUserAccessError: {
            en: 'Get user access error!',
            ru: 'Ошибка получения списка доступных домов и уровеней доступа к ним для пользователя!',
        },
        ChooseHouse: {
            en: 'Choose house...',
            ru: 'Дом...',
        },
        Model: {
            en: 'Model',
            ru: 'Модель',
        },
        Vendor: {
            en: 'Vendor',
            ru: 'Производитель',
        },
        Notification: {
            en: 'Notification',
            ru: 'Уведомление',
        },
        Email: {
            en: 'Email',
            ru: 'Email',
        },
        Sms: {
            en: 'SMS',
            ru: 'SMS',
        },
        Push: {
            en: 'Push notifications',
            ru: 'Push-уведомления',
        },
        GetNotificationSettingsError: {
            en: 'Get notification settings error!',
            ru: 'Ошибка получения настроек уведомлений!',
        },
        UpdateNotificationSettingsError: {
            en: 'Update notification settings error!',
            ru: 'Ошибка сохранения настроек уведомлений!',
        },
        NotificationSettings: {
            en: 'Notification settings',
            ru: 'Настройки уведомлений',
        },
        NotificationModeChange: {
            en: 'Change house mode',
            ru: 'Смена режима дома',
        },
        NotificationLostCtlConnection: {
            en: 'Controller disconnect',
            ru: 'Потеря связи с контроллером',
        },
        NotificationDataStatus: {
            en: 'Potential accident on recorders',
            ru: 'Потенциальная авария на регистраторах',
        },
        NotificationDeviceStatus: {
            en: 'Change device status',
            ru: 'Смена состояния устройства',
        },
        NotificationLowBattery: {
            en: 'Low battery power of the device',
            ru: 'Низкий заряд батареи устройства',
        },
        NotificationDisturbingEvents: {
            en: 'Disturbing events like fire',
            ru: 'Тревожные события (например, пожар)',
        },
        NotificationLogin: {
            en: 'Login',
            ru: 'Вход в систему',
        },
        NotificationNotificationAction: {
            en: 'Script notification',
            ru: 'Уведомления сценариев',
        },
        Settings: {
            en: 'Settings',
            ru: 'Настройки',
        },
        Alarms: {
            en: 'Alarms',
            ru: 'Аварии',
        },
        Panels: {
            en: 'Panels',
            ru: 'Пульты',
        },
        GuardObjects: {
            en: 'Objects',
            ru: 'Объекты',
        },
        Roles: {
            en: 'Role management',
            ru: 'Управление ролями',
        },
        Plans: {
            en: 'Space plans',
            ru: 'Планы помещений',
        },
        NotificationSettingsSaved: {
            en: 'Notification settings successfully saved',
            ru: 'Настройки уведомлений успешно сохранены',
        },
        AddHouseVariableValueToEnumEmptyTitle: {
            en: "The mode can't be empty",
            ru: 'Режим не может быть пустым',
        },
        EditHouseVariableEnumValueEmptyTitle: {
            en: "The mode can't be empty",
            ru: 'Режим не может быть пустым',
        },
        EditRuleBadTimeOfDay: {
            en: 'The time is wrong',
            ru: 'Время задано неверно',
        },
        CreateRuleBadTimeOfDay: {
            en: 'The time is wrong',
            ru: 'Время задано неверно',
        },
        EditRuleBadDayOfWeek: {
            en: "Days of week can't be empty",
            ru: 'Нужно указать дни недели',
        },
        CreateRuleBadDayOfWeek: {
            en: "Days of week can't be empty",
            ru: 'Нужно указать дни недели',
        },
        EditRuleNoTargetValueInCondition: {
            en: "Incorrect Target Value",
            ru: 'Некорректное значение свойства',
        },
        CreateRuleNoTargetValueInCondition: {
            en: "Incorrect Target Value",
            ru: 'Некорректное значение свойства',
        },
        EditScriptBadDelay: {
            en: 'The delay is wrong',
            ru: 'Задержка задана неверно',
        },
        CreateScriptBadDelay: {
            en: 'The delay is wrong',
            ru: 'Задержка задана неверно',
        },
        EditScriptNoMessage: {
            en: 'Type notification message',
            ru: 'Введите текст уведомления',
        },
        CreateScriptNoMessage: {
            en: 'Type notification message',
            ru: 'Введите текст уведомления',
        },
        UsersForNotification: {
            en: 'Users who will receive the notification',
            ru: 'Пользователи, которые получат уведомление',
        },
        AllUsers: {
            en: 'All users',
            ru: 'Все пользователи',
        },
        ForUsers: {
            en: 'for users',
            ru: 'для пользователей',
        },
        AccountsGeneration: {
            en: 'Accounts generation',
            ru: 'Генерация аккаунтов',
        },
        UpdateUserCantChangeUserName: {
            en: "Don't change the login",
            ru: 'Не меняйте логин',
        },
        CreateUserNoUsername: {
            en: 'Type the login',
            ru: 'Введите логин',
        },
        CreateUserEmptyUsername: {
            en: 'Type the login',
            ru: 'Введите логин',
        },
        CreateUserNoPassword: {
            en: 'Type the password',
            ru: 'Введите пароль',
        },
        NumberOfCharactersShouldNotExceed128: {
            en: 'Number of characters should not exceed 128',
            ru: 'Количество символов не должно превышать 128',
        },
        containsInvalidCharacters: {
            en: 'Field contains invalid characters',
            ru: 'Поле содержит недопустимые символы',
        },
        LoginNoSpace: {
            en: 'Login can not be with a space character',
            ru: 'Логин не может содержать знак пробела',
        },
        CreateUserAlreadyExists: {
            en: 'Created user already exists',
            ru: 'Пользователь с таким логином уже существует',
        },
        ErrorCantSaveAbonent: {
            en: 'Error: can\'t save abonent',
            ru: 'Ошибка: невозможно сохранить абонента',
        },
        RemoveUserError: {
            en: 'Error of user removing',
            ru: 'Ошибка удаления пользователя',
        },
        LoadUserErrorX: {
            en: 'Error of loading user with id="#X"',
            ru: 'Ошибка загрузки пользователя с id="#X"',
        },
        CreateHouseEmptyTitle: {
            en: 'Type the house title',
            ru: 'Введите название',
        },
        CreateHouseNoTitleField: {
            en: 'Type the house title',
            ru: 'Введите название',
        },
        CreateHouseDifferentOwner: {
            en: 'No rights to create home for this user',
            ru: 'Нет прав для создания дома для этого пользователя',
        },
        FixErrorsAndTryAgain: {
            en: 'Fix errors and try again',
            ru: 'Исправьте ошибки и повторите действие заново',
        },
        Temperature: {
            en: 'Temperature',
            ru: 'Температура',
        },
        ScriptEnabled: {
            en: 'Enable script',
            ru: 'Включить сценарий',
        },
        ScriptDisabled: {
            en: 'Script disabled',
            ru: 'Сценарий отключен',
        },
        ScriptDisabledShort: {
            en: 'Disabled',
            ru: 'Выключен',
        },
        RunScriptError: {
            en: 'Run script error',
            ru: 'Ошибка запуска сценария',
        },
        CancelScriptError: {
            en: 'Cancel script error',
            ru: 'Ошибка отмены сценария',
        },
        SaveScriptError: {
            en: 'Save script error!',
            ru: 'Ошибка сохранения настроек сценария!',
        },
        TurnedOn: {
            en: 'Turned on',
            ru: 'Включено',
        },
        Yes: {
            en: 'yes',
            ru: 'да',
        },
        No: {
            en: 'no',
            ru: 'нет',
        },
        ON_OFF: {
            en: 'Turned on',
            ru: 'Включено',
        },
        CONSUME_ENERGY: {
            en: 'Consume energy',
            ru: 'Количество потреблённой энергии',
        },
        SWITCH_MULTI_LEVEL: {
            en: 'Level',
            ru: 'Уровень',
        },
        RGB_LAMP: {
            en: 'Color',
            ru: 'Цвет',
        },
        LAMP_WARM_WHITE: {
            en: 'Warm white light',
            ru: 'Теплый белый свет',
        },
        LAMP_COLD_WHITE: {
            en: 'Cold white light',
            ru: 'Холодный белый свет',
        },
        POWER: {
            en: 'Power',
            ru: 'Мощность',
        },
        VOLTAGE: {
            en: 'Voltage',
            ru: 'Напряжение',
        },
        AMPERAGE: {
            en: 'Amperage',
            ru: 'Сила тока',
        },
        POWER_FACTOR: {
            en: 'Power factor',
            ru: 'Коэффициент мощности',
        },
        THERMOSTAT_MODE: {
            en: 'Thermostat mode',
            ru: 'Режим термостата',
        },
        THERMOSTAT_SET_POINT: {
            en: 'Thermostat set point',
            ru: 'Уровень термостата',
        },
        THERMOSTAT_MODE_0: {
            en: 'Off',
            ru: 'Закрыть (выкл)',
        },
        THERMOSTAT_MODE_1: {
            en: 'Auto',
            ru: 'Автоматический',
        },
        PAR01_THERMOSTAT_MODE_0: {
            en: 'Off',
            ru: 'Выкл.',
        },
        PAR01_THERMOSTAT_MODE_1: {
            en: 'Heating',
            ru: 'Обогрев',
        },
        PAR01_THERMOSTAT_MODE_2: {
            en: 'Cooling',
            ru: 'Охлаждение',
        },
        PAR01_THERMOSTAT_MODE_3: {
            en: 'Auto',
            ru: 'Авто',
        },
        PAR01_THERMOSTAT_MODE_5: {
            en: 'On / Resume',
            ru: 'Вкл. / Продолжить',
        },
        PAR01_THERMOSTAT_MODE_6: {
            en: 'Fan mode',
            ru: 'Режим вентилятора',
        },
        PAR01_THERMOSTAT_MODE_8: {
            en: 'Dry airing',
            ru: 'Сухое проветривание',
        },
        PAR01: {
            en: 'IR module',
            ru: 'ИК-модуль',
        },
        PAR01Mode: {
            en: 'Mode',
            ru: 'Режим',
        },
        PAR01Value: {
            en: 'Temperature',
            ru: 'Температура',
        },
        CONSUME_ENERGY_UNIT: {
            en: 'kW⋅h',
            ru: 'кВт⋅ч',
        },
        POWER_UNIT: {
            en: 'W',
            ru: 'Вт',
        },
        VOLTAGE_UNIT: {
            en: 'V',
            ru: 'В',
        },
        AMPERAGE_UNIT: {
            en: 'A',
            ru: 'А',
        },
        LUMINOSITY_UNIT: {
            en: 'lx',
            ru: 'лк',
        },
        LUMINOSITY: {
            en: 'Luminosity',
            ru: 'Освещенность',
        },
        HUMIDITY: {
            en: 'Humidity',
            ru: 'Влажность',
        },
        HUMIDITY_UNIT: {
            en: '%',
            ru: '%',
        },
        TEMPERATURE: {
            en: 'Temperature',
            ru: 'Температура',
        },
        TEMPERATURE_UNIT: {
            en: '℃',
            ru: '℃',
        },
        ControllerName: {
            en: 'Controller name',
            ru: 'Имя контроллера',
        },
        ControllerNameID: {
            en: 'Controller name/ID',
            ru: 'Имя контроллера/ID',
        },
        ControllersCronSetting: {
            en: 'Setting interview of digital metering devices',
            ru: 'Настройка опроса цифровых ПУ',
        },
        Export: {
            en: 'Export',
            ru: 'Экспорт',
        },
        ExportToFile: {
            en: 'Export to file',
            ru: 'Экспорт в файл',
        },
        ImportFromFile: {
            en: 'Import from file',
            ru: 'Импорт из файла',
        },
        FileContainsError: {
            en: 'File contains error',
            ru: 'Файл содержит ошибки',
        },
        InvalidFileFormat: {
            en: 'Invalid file format',
            ru: 'Неверный формат файла',
        },
        BusyNetworkAddress: {
            en: 'This network address "#value" already used',
            ru: 'Сетевой адрес "#value" уже используется',
        },
        NetworkAddressError: {
            en: 'Network address must be unique. This address already used.',
            ru: 'Сетевой адрес должен быть уникальным. Этот адрес уже используется.',
        },
        BusySerial: {
            en: 'Serial "#value" already used in house',
            ru: 'Серийный номер "#value" уже используется в доме',
        },
        BusyMeterSerial: {
            en: 'Serial "#value" already used in device channel',
            ru: 'Серийный номер "#value" уже используется в канале устройства',
        },
        ChooseFile: {
            en: 'Choose file',
            ru: 'Выбрать файл',
        },
        UnknownError: {
            en: 'Unknown error',
            ru: 'Неизвестная ошибка',
        },
        UnstableConnection: {
            en: 'Unstable connection',
            ru: 'Неустойчивое соединение',
        },
        SetNewValueError: {
            en: 'Invalid parameter value',
            ru: 'Недопустимое значение параметра',
        },
        ErrorDataFormat: {
            en: 'Data Format Error',
            ru: 'Ошибка формата данных',
        },
        ScriptImportErrorNoDevice: {
            en: 'There is no suitable device for the imported script!',
            ru: 'Для импортируемого сценария не найдено подходящего устройства!',
        },
        ScriptImportErrorNoVariables: {
            en: 'There is no suitable house variable for the imported script!',
            ru: 'Для импортируемого сценария не найдено подходящей переменной дома!',
        },
        ScriptExportError: {
            en: 'Script exporting error!',
            ru: 'Произошла ошибка при экспорте сценария!',
        },
        WaitDataFromDevice: {
            en: 'Waiting for data from the added device',
            ru: 'Ожидание данных от добавленного устройства',
        },
        DeviceInitialization: {
            en: 'Device initialization',
            ru: 'Инициализация устройства',
        },
        DeviceSuccessfullyAdded: {
            en: 'Device successfully added',
            ru: 'Устройство успешно добавлено',
        },
        DeviceInitializationTakesLonger: {
            en: 'Device initialization takes longer than expected',
            ru: 'Инициализация устройства длится дольше обычного',
        },
        DeviceInitializationTakesLongerDescription: {
            en: 'Device initialization takes longer than expected. What you can do:\n1) Wait longer, initialization takes an indefinite time\n2) Remove the device and add it again',
            ru: 'Инициализация устройства длится дольше обычного. Что вы можете сделать:\n1) Подождать еще, инициализация занимает неопределённое время\n2) Удалить устройство и добавить его повторно',
        },
        ScriptsAutoCreationSuggestion: {
            en: 'You just added a device. Here are script patterns which working with this device. Which would you like to add it to house? After adding, you will need to open each script and set specific devices.',
            ru: 'Вы только что добавили устройство. Здесь представлены шаблоны сценариев, использующих это устройство, которые могут быть вам полезны. Какие вы хотели бы добавить себе? После добавления вам будет нужно открыть сценарий, задать конкретные устройства и включить его.',
        },
        ScriptsAutoCreation: {
            en: 'Scripts auto creation',
            ru: 'Автоматическое создание сценариев',
        },
        ScriptsAutoCreationError: {
            en: 'Scripts auto creation error!',
            ru: 'Ошибка автоматического создания сценариев!',
        },
        ChooseDevice: {
            en: 'Choose device',
            ru: 'Выберите датчик',
        },
        ChooseCamera: {
            en: 'Choose camera',
            ru: 'Выберите камеру',
        },
        AddImageAndLink: {
            en: 'Add image from camera and link to player',
            ru: 'Приложить скриншот и ссылку на воспроизведение с камеры',
        },
        ScriptPatterns: {
            en: 'Script patterns',
            ru: 'Шаблоны сценариев',
        },
        PatternName: {
            en: 'Name',
            ru: 'Название',
        },
        enterPatternName: {
            en: 'enter script pattern name',
            ru: 'введите название шаблона сценария',
        },
        FieldMustContainLetterOrNumber: {
            en: 'The field must contain at least one letter or number',
            ru: 'Поле должно содержать хотя бы одну букву или цифру',
        },
        PatternDescription: {
            en: 'Description',
            ru: 'Описание',
        },
        PatternPropertyKindList: {
            en: 'Property kinds',
            ru: 'Используемые свойства устройств',
        },
        GetPatternsError: {
            en: 'Get patterns error!',
            ru: 'Ошибка получения списка шаблонов!',
        },
        GetPatternError: {
            en: 'Get pattern error!',
            ru: 'Ошибка получения шаблона!',
        },
        ScriptPatternX: {
            en: 'ScriptPattern "#X"',
            ru: 'Шаблон сценария "#X"',
        },
        ConfirmRemovePattern: {
            en: 'Do you really want to remove this pattern?',
            ru: 'Вы действительно хотите удалить этот шаблон?',
        },
        RemovePatternError: {
            en: 'Remove pattern error!',
            ru: 'Произошла ошибка при удалении шаблона!',
        },
        SavePatternError: {
            en: 'Save pattern error!',
            ru: 'Ошибка сохранения шаблона!',
        },
        CreatePattern: {
            en: 'Create pattern',
            ru: 'Добавить новый шаблон',
        },
        Rules: {
            en: 'Rules',
            ru: 'Правила',
        },
        RemoveCondition: {
            en: 'Remove condition',
            ru: 'Удалить условие',
        },
        RemoveRule: {
            en: 'Remove rule',
            ru: 'Удалить правило',
        },
        RemoveAction: {
            en: 'Remove action',
            ru: 'Удалить действие',
        },
        SOURCE_TYPE_DEVICE_PROPERTY: {
            en: 'device property',
            ru: 'свойство устройства',
        },
        SOURCE_TYPE_HOUSE_VARIABLE: {
            en: 'house variable',
            ru: 'переменная дома',
        },
        SOURCE_TYPE_TIME: {
            en: 'time',
            ru: 'время',
        },
        PROPERTY_SMOKE: {
            en: 'smoke',
            ru: 'задымление',
        },
        PROPERTY_GAS: {
            en: 'gas',
            ru: 'утечка газа',
        },
        PROPERTY_MOTION: {
            en: 'motion',
            ru: 'движение',
        },
        PROPERTY_GLASS_BREAKAGE: {
            en: 'breaking',
            ru: 'разбитие',
        },
        PROPERTY_LEAK: {
            en: 'leak',
            ru: 'протечка',
        },
        PROPERTY_TAMPER: {
            en: 'tamper',
            ru: 'вскрытие',
        },
        PROPERTY_OPEN_CLOSE: {
            en: 'open',
            ru: 'открыто',
        },
        PROPERTY_BATTERY: {
            en: 'battery',
            ru: 'заряд батареи',
        },
        PROPERTY_TEMPERATURE: {
            en: 'temperature',
            ru: 'температура',
        },
        PROPERTY_CONSUME_ENERGY: {
            en: 'consume energy',
            ru: 'количество потреблённой энергии',
        },
        PROPERTY_POWER: {
            en: 'power',
            ru: 'мощность',
        },
        PROPERTY_VOLTAGE: {
            en: 'voltage',
            ru: 'напряжение',
        },
        PROPERTY_AMPERAGE: {
            en: 'amperage',
            ru: 'сила тока',
        },
        PROPERTY_POWER_FACTOR: {
            en: 'power factor',
            ru: 'коэффициент мощности',
        },
        PROPERTY_LUMINOSITY: {
            en: 'luminosity',
            ru: 'освещенность',
        },
        PROPERTY_METER_COMPLEX: {
            en: 'meter complex',
            ru: 'считыватель счетчика',
        },
        PROPERTY_RGB_LAMP: {
            en: 'rgb lamp',
            ru: 'RGB лампа',
        },
        PROPERTY_LAMP_WARM_WHITE: {
            en: 'lamp warm white',
            ru: 'теплый белый свет',
        },
        PROPERTY_LAMP_COLD_WHITE: {
            en: 'lamp cold white',
            ru: 'холодный белый свет',
        },
        PROPERTY_HUMIDITY: {
            en: 'humidity',
            ru: 'влажность',
        },
        PROPERTY_SWITCH_MULTI_LEVEL: {
            en: 'level',
            ru: 'уровень',
        },
        PROPERTY_ON_OFF: {
            en: 'turned on',
            ru: 'включено',
        },
        INTERVAL_SET: {
            en: 'set interval',
            ru: 'задать интервал',
        },
        INTERVAL_DURING: {
            en: 'during',
            ru: 'в течении',
        },
        INTERVAL_MINUTE: {
            en: 'minute(s)',
            ru: 'минут(ы)',
        },
        XMinutes: {
            en: params => pluralizeEn(params.X, 'minute'),
            ru: params => pluralizeRu(params.X, ['минуты', 'минут', 'минут'])
        },
        COMPARISON_EQ: {
            en: '=',
            ru: '=',
        },
        COMPARISON_NE: {
            en: '≠',
            ru: '≠',
        },
        COMPARISON_LT: {
            en: '<',
            ru: '<',
        },
        COMPARISON_GT: {
            en: '>',
            ru: '>',
        },
        COMPARISON_LE: {
            en: '⩽',
            ru: '⩽',
        },
        COMPARISON_GE: {
            en: '⩾',
            ru: '⩾',
        },
        NORMA_GT: {
            en: 'more than norm',
            ru: 'норма больше',
        },
        NORMA_LT: {
            en: 'less than normal',
            ru: 'норма меньше',
        },
        DURING_THE_TIME: {
            en: 'during the time',
            ru: 'за время',
        },
        HOUSE_VARIABLE_houseMode: {
            en: 'house mode',
            ru: 'режим дома',
        },
        TIME_TIME_OF_DAY: {
            en: 'time of day',
            ru: 'время суток',
        },
        TIME_DAY_OF_WEEK: {
            en: 'day of week',
            ru: 'дни недели',
        },
        TARGET_VALUE_true: {
            en: 'true',
            ru: 'истина',
        },
        TARGET_VALUE_false: {
            en: 'false',
            ru: 'ложь',
        },
        HOUSE_MODE_atHome: {
            en: 'at home',
            ru: 'дома',
        },
        HOUSE_MODE_notAtHome: {
            en: 'not at home',
            ru: 'не дома',
        },
        HOUSE_MODE_onHoliday: {
            en: 'on holiday',
            ru: 'в отпуске',
        },
        ACTION_SET_HOUSE_VARIABLE: {
            en: 'set house variable',
            ru: 'изменение переменной дома',
        },
        ACTION_DELAY: {
            en: 'delay',
            ru: 'задержка',
        },
        ACTION_SEND_NOTIFICATION: {
            en: 'send notification',
            ru: 'отправка уведомления',
        },
        ACTION_SET_DEVICE_PROPERTY: {
            en: 'set device property',
            ru: 'изменение свойства устройства',
        },
        ActionType: {
            en: 'Action type',
            ru: 'Тип действия',
        },
        Variable: {
            en: 'Variable',
            ru: 'Переменная',
        },
        Value: {
            en: 'Value',
            ru: 'Значение',
        },
        Text: {
            en: 'Text',
            ru: 'Текст',
        },
        Property: {
            en: 'Property',
            ru: 'Свойство',
        },
        Color: {
            en: 'Color',
            ru: 'Цвет',
        },
        ModeOfLight: {
            en: 'Mode of light',
            ru: 'Режим света',
        },
        ColorMode: {
            en: 'Color mode',
            ru: 'Цветной',
        },
        WhiteMode: {
            en: 'White mode',
            ru: 'Белый',
        },
        ofBright: {
            en: 'of bright',
            ru: 'яркости',
        },
        ColorValue: {
            en: 'Color rgb(#r, #g, #b)',
            ru: 'Цвет rgb(#r, #g, #b)',
        },
        Device: {
            en: 'Device',
            ru: 'Устройство',
        },
        Reset: {
            en: 'Reset',
            ru: 'Сбросить',
        },
        ResetFilter: {
            en: 'Reset filter',
            ru: 'Сбросить фильтр',
        },
        Wait: {
            en: 'Wait',
            ru: 'Ожидать',
        },
        Location: {
            en: 'Location',
            ru: 'Местоположение',
        },
        LocationNotSet: {
            en: 'Location is not set',
            ru: 'Местоположение не установлено',
        },
        GetDataError: {
            en: 'Error getting data',
            ru: 'Ошибка получения данных',
        },
        SaveChangesError: {
            en: 'Error saving changes',
            ru: 'Ошибка сохранения изменений',
        },
        SaveChangesSuccess: {
            en: 'Changes saved successfully',
            ru: 'Изменения успешно сохранены',
        },
        GroupName: {
            en: 'Group name',
            ru: 'Название группы',
        },
        AddGroup: {
            en: 'Add group',
            ru: 'Добавить группу',
        },
        RemoveGroup: {
            en: 'Remove group',
            ru: 'Удалить группу',
        },
        Deselect: {
            en: 'Deselect',
            ru: 'Отменить выделение',
        },
        Collapse: {
            en: 'Collapse',
            ru: 'Свернуть',
        },
        CollapseAll: {
            en: 'Collapse all',
            ru: 'Свернуть все',
        },
        Expand: {
            en: 'Expand',
            ru: 'Развернуть',
        },
        ExpandAll: {
            en: 'Expand all',
            ru: 'Развернуть все',
        },
        Reports: {
            en: 'Reports',
            ru: 'Отчеты',
        },
        ReportsHistory: {
            en: 'Reports',
            ru: 'Отчеты',
        },
        Report: {
            en: 'Report',
            ru: 'Отчет',
        },
        ShowAllReports: {
            en: 'Show all reports',
            ru: 'Показать все отчеты',
        },
        ReportParameters: {
            en: 'Parameters',
            ru: 'Параметры',
        },
        ReportType: {
            en: 'Type',
            ru: 'Тип',
        },
        ReportStatus: {
            en: 'Status',
            ru: 'Статус',
        },
        ReportStatusSTARTED: {
            en: 'STARTED',
            ru: 'Выполняется',
        },
        ReportStatusCOMPLETED: {
            en: 'COMPLETED',
            ru: 'Завершен',
        },
        ReportStatusFAILED: {
            en: 'FAILED',
            ru: 'Ошибка',
        },
        ReportStatusNO_DATA: {
            en: 'No data',
            ru: 'Нет данных',
        },
        ReportStatusABANDONED: {
            en: 'Abandoned',
            ru: 'Пропущен',
        },
        ReportStatusSTOPPED: {
            en: 'Stopped',
            ru: 'Остановлен системой',
        },
        StartPreviousReport: {
            en: 'Start report for the previous period',
            ru: 'Запуск за прошедший период',
        },
        StartCurrentReport: {
            en: 'Start report for the current (incomplete) period',
            ru: 'Запуск за текущий (неполный) период',
        },
        TooltipForReportStatusNotFullData: {
            en: 'The report does not contain complete data. This could happen for a reason:<br>1. Invalid date<br>2. Lack of data in the database<br>3. Errors on the device',
            ru: 'В отчете не полные данные. Это могло произойти по причине:<br>1. Неверной указанной даты<br>2. Нехватки данных в базе<br>3. Ошибки на устройстве',
        },
        TooltipForReportWarningStatus: {
            en: 'There is not enough data in the database, and the device is being polled.',
            ru: 'В базе данных не хватает данных, начался опрос устройства.',
        },
        AllTypes: {
            en: 'All types',
            ru: 'Все типы',
        },
        AllStatuses: {
            en: 'All statuses',
            ru: 'Все статусы',
        },
        Download: {
            ru: 'Скачать',
        },
        ReportJobStatusSTARTED: {
            en: 'STARTED',
            ru: 'Выполняется',
        },
        ReportJobStatusCOMPLETED: {
            en: 'COMPLETED',
            ru: 'Завершена',
        },
        ReportJobStatusFAILED: {
            en: 'FAILED',
            ru: 'Ошибка',
        },
        ReportJobStatusSTARTING: {
            en: 'STARTING',
            ru: 'Запускается',
        },
        ReportJobStatusAWAITING: {
            en: 'AWAITING',
            ru: 'Ожидает',
        },
        ReportJobStatusAWAITING_RUN_NEXT: {
            en: 'AWAITING_RUN_NEXT',
            ru: 'Ожидает следующего запуска',
        },
        ReportJobStatusREMOVED: {
            en: 'REMOVED',
            ru: 'Удалена',
        },
        ReportJobStatusSTOPPED: {
            en: 'STOPPED',
            ru: 'Остановлена',
        },
        ReportJobStatusMISFIRED: {
            en: 'MISFIRED',
            ru: 'Пропущена',
        },
        ReportJobStatusUNKNOWN: {
            en: 'UNKNOWN',
            ru: 'Неопознана',
        },
        Delete: {
            ru: 'Удалить',
        },
        ReportStartTime: {
            en: 'Start',
            ru: 'Запуск',
        },
        ReportEndTime: {
            en: 'End time',
            ru: 'Окончание',
        },
        ReportDownload: {
            en: 'Download',
            ru: 'Загрузка',
        },
        AddFileError: {
            en: 'Add file error',
            ru: 'Ошибка добавления файла',
        },
        TaskList: {
            en: 'Task list',
            ru: 'Список задач',
        },
        TaskName: {
            en: 'Task name',
            ru: 'Название задачи',
        },
        TaskType: {
            en: 'Type',
            ru: 'Тип',
        },
        TaskStatus: {
            en: 'Task status',
            ru: 'Статус задачи',
        },
        TaskNextStartTime: {
            en: 'Next start time',
            ru: 'Время следующего запуска',
        },
        TaskActions: {
            en: 'Actions',
            ru: 'Действия',
        },
        ReportSettings: {
            en: 'Settings',
            ru: 'Настройки',
        },
        ReportAdding: {
            en: 'Add reports',
            ru: 'Создание отчетов',
        },
        SaveDataError: {
            en: 'Save data error',
            ru: 'Ошибка сохранения данных',
        },
        RemoveDataError: {
            en: 'Remove data error',
            ru: 'Ошибка удаления данных',
        },
        ReportTemplates: {
            en: 'Report templates',
            ru: 'Настройка пользовательского шаблона',
        },
        Templates: {
            en: 'Templates',
            ru: 'Шаблоны',
        },
        TemplateName: {
            en: 'Template name',
            ru: 'Название шаблона',
        },
        TemplateParameters: {
            en: 'Parameters',
            ru: 'Параметры',
        },
        CreateTemplate: {
            en: 'Create template',
            ru: 'Создать шаблон',
        },
        CreatingTemplate: {
            en: 'Creating template',
            ru: 'Создание шаблона',
        },
        EditingTemplate: {
            en: 'Editing template',
            ru: 'Редактирование шаблона',
        },
        SelectTemplateForSettings: {
            en: 'Select template for settings',
            ru: 'Выберите шаблон для настроек',
        },
        SettingsExampleForRegularReport: {
            en: 'The next report will be started at #date. It will include data from #dateFrom to #dateTo.',
            ru: 'Следующий отчет будет запущен #date. В него войдут данные с #dateFrom по #dateTo.',
        },
        Calculation: {
            en: 'Calculation',
            ru: 'Калькуляция',
        },
        NoReportsBecauseNoHouses: {
            en: 'You do not have any reports, because there is no houses',
            ru: 'У Вас нет ни одного отчета, т.к. нет ни одного дома',
        },
        CurrentReading: {
            en: 'Current reading',
            ru: 'Текущие показания',
        },
        InitialReading: {
            en: 'Initial reading',
            ru: 'Начальные показания',
        },
        FinalReading: {
            en: 'Final reading',
            ru: 'Конечные показания',
        },
        GetReading: {
            en: 'Get reading',
            ru: 'Получить показания',
        },
        Consumption: {
            en: 'Consumption',
            ru: 'Потребление',
        },
        Period: {
            en: 'Period',
            ru: 'Период',
        },
        Additionally: {
            en: 'Additionally',
            ru: 'Дополнительно',
        },
        AddHouseVariableError: {
            en: 'Error house variable creating!',
            ru: 'Ошибка создания переменной дома!',
        },
        UpdateHouseVariableError: {
            en: 'Error house variable updating!',
            ru: 'Ошибка обновления переменной дома!',
        },
        HouseVariables: {
            en: 'House variables',
            ru: 'Переменные дома',
        },
        DataChanged: {
            en: 'Data has been changed',
            ru: 'Данные были изменены',
        },
        ShowInHeader: {
            en: 'Show in header',
            ru: 'Показывать в хедере',
        },
        HouseVariableType: {
            en: 'Variable type',
            ru: 'Тип переменной',
        },
        VARIABLE_TYPE_ENUMERATION: {
            en: 'Enumeration',
            ru: 'Перечисление',
        },
        VARIABLE_TYPE_TEXT: {
            en: 'Text',
            ru: 'Текст',
        },
        VARIABLE_TYPE_INTEGER: {
            en: 'Integer',
            ru: 'Целое число',
        },
        VARIABLE_TYPE_FLOAT: {
            en: 'Float',
            ru: 'Число с плавающей точкой',
        },
        RemoveHouseVariableError: {
            ru: 'Ошибка удаления переменной дома!',
            en: 'Error removing house variable!',
        },
        ConfirmRemoveHouseVariable: {
            en: 'Do you really want to remove this variable?',
            ru: 'Вы действительно хотите удалить эту переменную?',
        },
        AddNewHouseVariable: {
            en: 'Add new house variable',
            ru: 'Добавить новую переменную',
        },
        AddValue: {
            en: 'Add value',
            ru: 'Добавить значение',
        },
        AmountByIndividualMeters: {
            en: 'Amount by individual meters',
            ru: 'Сумма по ИПУ',
        },
        AmountByCommonMeters: {
            en: 'Amount by common meters',
            ru: 'Сумма по ОПУ',
        },
        CommonHouseNeeds: {
            en: 'Common house needs',
            ru: 'ОДН',
        },
        CommonHouseNeedsCalculation: {
            en: 'Common house needs calculation',
            ru: 'Расчет ОДН',
        },
        FileType: {
            en: 'File type',
            ru: 'Тип файла',
        },
        Include: {
            en: 'Include',
            ru: 'Включить',
        },
        MeterData: {
            en: 'Meter Data',
            ru: 'Данные счетчика',
        },
        MeterType: {
            en: 'Meter type',
            ru: 'Тип счетчика',
        },
        METER_TYPE_true: {
            en: 'Individual',
            ru: 'ИПУ',
        },
        METER_TYPE_false: {
            en: 'Common',
            ru: 'ОПУ',
        },
        METER_TYPE_ALL: {
            en: 'All',
            ru: 'Все',
        },
        RECORD_GROUP_UNKNOWN: {
            en: 'UNKNOWN',
            ru: 'UNKNOWN',
        },
        RECORD_GROUP_ELECTRIC: {
            en: 'Electricity',
            ru: 'Электроэнергия',
        },
        RECORD_GROUP_HOT_WATER: {
            en: 'Hot water',
            ru: 'Горячая вода',
        },
        RECORD_GROUP_COLD_WATER: {
            en: 'Cold water',
            ru: 'Холодная вода',
        },
        RECORD_GROUP_HEAT: {
            en: 'Heating',
            ru: 'Отопление',
        },
        RECORD_GROUP_GAS: {
            en: 'Gas',
            ru: 'Газ',
        },
        RECORD_GROUP_CUSTOM: {
            en: 'CUSTOM',
            ru: 'CUSTOM',
        },
        ELECTRIC_UNIT: {
            en: 'kW⋅h',
            ru: 'кВт⋅ч',
        },
        HOT_WATER_UNIT: {
            en: 'm³',
            ru: 'м³',
        },
        COLD_WATER_UNIT: {
            en: 'm³',
            ru: 'м³',
        },
        HEAT_UNIT: {
            en: 'kcal',
            ru: 'ккал',
        },
        GAS_UNIT: {
            en: 'm³',
            ru: 'м³',
        },
        DeviceRemoved: {
            en: 'Device removed',
            ru: 'Устройство удалено',
        },
        NoData: {
            en: 'No data',
            ru: 'Нет данных',
        },
        SetPropertyControllerIsOffline: {
            en: 'Set device property error! Controller "#value" is offline.',
            ru: 'Ошибка редактирования свойств устройства! Контроллер "#value" не доступен.',
        },
        ChooseControllerType: {
            en: 'Choose controller type',
            ru: 'Выберите тип контроллера',
        },
        ChooseDeviceType: {
            en: 'Choose device type',
            ru: 'Выберите тип устройства',
        },
        ActionsWithDeviceHistory: {
            en: 'Actions with device history',
            ru: 'Действия с историей устройства',
        },
        SelectActionToContinue: {
            en: 'Select an action to continue',
            ru: 'Для продолжения выберите действие',
        },
        SpliceHistory: {
            en: 'Splicing the history',
            ru: 'Соединить историю',
        },
        SpliceHistoryError: {
            en: 'Splicing history error',
            ru: 'Не удалось соединить историю',
        },
        SelectHistoryForSplice: {
            en: 'Select history for splice',
            ru: 'Выберите историю для соединения',
        },
        GetMetersHistoryError: {
            en: 'Get meters history error',
            ru: 'Не удалось получить историю для устройства',
        },
        StartNewHistory: {
            en: 'Start new history',
            ru: 'Начать новую историю',
        },
        BackToEditDevice: {
            en: 'Back to edit device',
            ru: 'Вернуться к редактированию устройства',
        },
        ShiftChart: {
            en: 'The chart shift by the amount delta',
            ru: 'Сдвиг графика по дельте',
        },
        ApplyValue: {
            en: 'Apply changes (there will be a jump in the chart)',
            ru: 'Оставить изменения (будет скачок в графике)',
        },
        ValueVeryDifferent: {
            en: 'The new value is very different from the current one.',
            ru: 'Новое значение сильно отличается от текущего.',
        },
        ValidatePropertyError: {
            en: 'The validation error of the new value',
            ru: 'Ошибка валидации нового значения',
        },
        ShiftHistoryError: {
            en: 'An error occurred during the shift',
            ru: 'Произошла ошибка при сдвиге',
        },
        Port: {
            en: 'Port',
            ru: 'Порт',
        },
        IfThereIs: {
            en: 'if there is',
            ru: 'если есть',
        },
        BoundRate: {
            en: 'Bound rate',
            ru: 'Скорость обмена',
        },
        Z_WAY: {
            en: 'Z-Way',
            ru: 'Z-Way',
        },
        Z_WAY_DEVICE: {
            en: 'Z-Way',
            ru: 'Z-Way',
        },
        ETHERNET_COM: {
            en: 'EthernetCom',
            ru: 'EthernetCom',
        },
        ETHERNET_M_BUS: {
            en: 'EthernetMBus',
            ru: 'EthernetMBus',
        },
        VIRTUAL_HTTP: {
            en: 'VirtualHTTP',
            ru: 'VirtualHTTP',
        },
        MERCURY230: {
            en: 'Mercury 230',
            ru: 'Меркурий 230',
        },
        MERCURY206: {
            en: 'Mercury 206',
            ru: 'Меркурий 206',
        },
        TSRV034: {
            en: 'Vzlet TSRV-034',
            ru: 'Взлет ТСРВ-034',
        },
        TSRV043: {
            en: 'Vzlet TSRV-043',
            ru: 'Взлет ТСРВ-043',
        },
        TSRV026M: {
            en: 'Vzlet TSRV-026m',
            ru: 'Взлет ТСРВ-026м',
        },
        PULSAR: {
            en: 'Pulsar',
            ru: 'Пульсар',
        },
        LOGIKA942: {
            en: 'Logika SPT 942',
            ru: 'Логика СПТ 942',
        },
        LOGIKA943: {
            en: 'Logika SPT 943',
            ru: 'Логика СПТ 943',
        },
        LOGIKA941_10: {
            en: 'Logika SPT 941.10',
            ru: 'Логика СПТ 941.10',
        },
        LOGIKA941_20: {
            en: 'Logika SPT 941.20',
            ru: 'Логика СПТ 941.20',
        },
        LOGIKA944: {
            en: 'Logika SPT 944',
            ru: 'Логика СПТ 944',
        },
        LOGIKA761: {
            en: 'Logika SPG 761',
            ru: 'Логика СПГ 761',
        },
        TW7: {
            en: 'TW7',
            ru: 'ТВ7',
        },
        SANEXT_MONO_MBUS: {
            en: 'Sanext Mono MBUS',
            ru: 'Теплосчетчик Sanext Mono MBUS',
        },
        PULSAR_MBUS: {
            en: 'Pulsar M-Bus',
            ru: 'Теплосчетчик Pulsar M-Bus',
        },
        SANEXT_MONO_RS485: {
            en: 'Sanext Mono RS-485',
            ru: 'Теплосчетчик Sanext Mono RS-485',
        },
        VKT7: {
            en: 'VKT-7',
            ru: 'ВКТ-7',
        },
        CE102M_RS485: {
            en: 'Energomera СЕ102М R5',
            ru: 'Энергомера СЕ102М R5',
        },
        ASWEGA_SA943A: {
            en: 'Aswega SA-94/3A',
            ru: 'Асвега SA-94/3A',
        },
        DevicePassword: {
            en: 'Device password',
            ru: 'Пароль устройства',
        },
        NetworkAddress: {
            en: 'Device address',
            ru: 'Адрес устройства',
        },
        enterNetworkAddress: {
            en: 'enter device address',
            ru: 'введите адрес устройства',
        },
        enterDevicePassword: {
            en: 'enter device password',
            ru: 'введите пароль устройства',
        },
        NumberValueFromMinToMax: {
            en: 'Number from #min_value to #max_value',
            ru: 'Число от #min_value до #max_value',
        },
        NumberLengthFromMinToMax: {
            en: '#min-#max numeric characters',
            ru: 'Число длиной от #min до #max',
        },
        StringLengthFromMinToMax: {
            en: '#min-#max string characters',
            ru: 'Строка длиной от #min до #max',
        },
        XNumericCharacters: {
            en: params => pluralizeEn(params.X, 'numeric character'),
            ru: params => pluralizeRu(params.X, ['цифра', 'цифры', 'цифр'])
        },
        HouseAddressWithApartment: {
            en: '#house, apt. #apartment',
            ru: '#house, кв. #apartment',
        },
        ReportByAddresses: {
            en: 'Report by addresses',
            ru: 'Отчет по адресам',
        },
        GetReportByAddressesError: {
            en: 'Error loading report by addresses',
            ru: 'Ошибка загрузки отчета по адресам',
        },
        NoAddresses: {
            en: 'No addresses',
            ru: 'Адреса не заданы',
        },
        EnterManyAddresses: {
            en: 'Enter many addresses',
            ru: 'Ввести несколько адресов',
        },
        Units: {
            en: 'Units',
            ru: 'Единицы измерения',
        },
        NoneSelected: {
            en: 'Nothing selected',
            ru: 'Ничего не выбрано',
        },
        CountElements: {
            en: '#count items',
            ru: '#count элемента(ов)',
        },
        InputSettings: {
            en: 'Assign parameters',
            ru: 'Назначение параметров',
        },
        InputSettingsLoadError: {
            en: 'Error loading configuration',
            ru: 'Ошибка загрузки конфигурации',
        },
        InputSettingsSaveError: {
            en: 'Error saving configuration',
            ru: 'Ошибка сохранения конфигурации',
        },
        InputName: {
            en: 'Name',
            ru: 'Название',
        },
        InputNameDescription: {
            en: 'Description',
            ru: 'Описание',
        },
        InputGroup: {
            en: 'Group',
            ru: 'Группа учета',
        },
        InputGroupNotUsed: {
            en: 'Not used',
            ru: 'Не используется',
        },
        AggregationOptions: {
            en: 'Aggregation options',
            ru: 'Варианты агрегации',
        },
        AggregationOption: {
            en: 'Aggregation',
            ru: 'Агрегация',
        },
        avg: {
            en: 'average',
            ru: 'среднее',
        },
        sum: {
            en: 'sum',
            ru: 'сумма',
        },
        last: {
            en: 'last',
            ru: 'последнее',
        },
        delta: {
            en: 'delta',
            ru: 'дельта',
        },
        Logarithmic: {
            en: 'Logarithmic',
            ru: 'Логарифмический',
        },
        ArchiveData: {
            en: 'Archive data',
            ru: 'Архивные данные',
        },
        SEC: {
            en: 'second',
            ru: 'секунда',
        },
        MIN: {
            en: 'minute',
            ru: 'минута',
        },
        HOUR: {
            en: 'hour',
            ru: 'час',
        },
        DAY: {
            en: 'day',
            ru: 'день',
        },
        Channel: {
            en: 'Channel',
            ru: 'Канал',
        },
        channelNumber: {
            en: 'Channel #number',
            ru: 'Канал #number',
        },
        XChannelCreateAndAssign: {
            en: '#channel (create and assign)',
            ru: '#channel (создать и назначить)',
        },
        EnterApartmentToCorrectReporting: {
            en: 'Enter the apartment number for correct reporting',
            ru: 'Введите номер квартиры для корректного формирования отчетов',
        },
        AccountedAs: {
            en: 'Accounted as "#group"',
            ru: 'Учитывается как "#group"',
        },
        NoResults: {
            en: 'No results',
            ru: 'Нет результатов',
        },
        InputDesc_powerFactor: {
            ru: 'Коэффициент мощности',
            en: 'Power factor',
        },
        InputDesc_consumeEnergy: {
            ru: 'Потребляемая энергия',
            en: 'Consume energy',
        },
        InputDesc_level: {
            ru: 'Уровень',
            en: 'Level',
        },
        InputDesc_frequency: {
            ru: 'Частота',
            en: 'Frequency',
        },
        InputDesc_voltage: {
            ru: 'Напряжение',
            en: 'Voltage',
        },
        InputDesc_amperage: {
            ru: 'Сила тока',
            en: 'Amperage',
        },
        InputDesc_powerActive: {
            ru: 'Мощность активная',
            en: 'Power active',
        },
        InputDesc_powerReactive: {
            ru: 'Мощность реактивная',
            en: 'Power reactive',
        },
        InputDesc_powerTotal: {
            ru: 'Мощность суммарная',
            en: 'Power total',
        },
        InputDesc_transformationRatio: {
            ru: 'Коэффициент трансформации',
            en: 'Transformation ratio',
        },
        InputDesc_resistance: {
            ru: 'Сопротивление',
            en: 'Resistance',
        },
        InputDesc_energy: {
            ru: 'Энергия',
            en: 'Energy',
        },
        InputDesc_time: {
            ru: 'Часов',
            en: 'Hours',
        },
        InputDesc_status: {
            ru: 'Статус',
            en: 'Status',
        },
        InputDesc_version: {
            ru: 'Версия ПО',
            en: 'Software version',
        },
        InputDesc_error: {
            ru: 'Код ошибки',
            en: 'Error code',
        },
        InputDesc_voltageFirstFaze: {
            ru: 'Напряжение, 1 фаза',
            en: 'Voltage, phase 1',
        },
        InputDesc_voltageSecondFaze: {
            ru: 'Напряжение, 2 фаза',
            en: 'Voltage, phase 2',
        },
        InputDesc_voltageThirdFaze: {
            ru: 'Напряжение, 3 фаза',
            en: 'Voltage, phase 3',
        },
        InputDesc_maxVoltage: {
            ru: 'Несбрасываемый максимум напряжения',
            en: 'Non-resettable voltage maximum',
        },
        InputDesc_maxrVoltage: {
            ru: 'Сбрасываемый максимум напряжения',
            en: 'Resettable voltage maximum',
        },
        InputDesc_maxAmperage: {
            ru: 'Несбрасываемый максимум силы тока',
            en: 'Non-resettable amperage maximum',
        },
        InputDesc_maxrAmperage: {
            ru: 'Сбрасываемый максимум силы тока',
            en: 'Resettable amperage maximum',
        },
        InputDesc_maxPower: {
            ru: 'Несбрасываемый максимум мощности',
            en: 'Non-resettable power maximum',
        },
        InputDesc_maxrPower: {
            ru: 'Сбрасываемый максимум мощности',
            en: 'Resettable power maximum',
        },
        InputDesc_currentFirstFaze: {
            ru: 'Ток, 1 фаза',
            en: 'Current, phase 1',
        },
        InputDesc_currentSecondFaze: {
            ru: 'Ток, 2 фаза',
            en: 'Current, phase 2',
        },
        InputDesc_currentThirdFaze: {
            ru: 'Ток, 3 фаза',
            en: 'Current, phase 3',
        },
        InputDesc_reactivePowerFirstFaze: {
            ru: 'Мощность реактивная, 1 фаза',
            en: 'Reactive power, phase 1',
        },
        InputDesc_reactivePowerSecondFaze: {
            ru: 'Мощность реактивная, 2 фаза',
            en: 'Reactive power, phase 2',
        },
        InputDesc_reactivePowerThirdFaze: {
            ru: 'Мощность реактивная, 3 фаза',
            en: 'Reactive power, phase 3',
        },
        InputDesc_reactivePowerSum: {
            ru: 'Мощность реактивная, суммарная',
            en: 'Reactive power, total',
        },
        InputDesc_activePowerFirstFaze: {
            ru: 'Мощность активная, 1 фаза',
            en: 'Active power, phase 1',
        },
        InputDesc_activePowerSecondFaze: {
            ru: 'Мощность активная, 2 фаза',
            en: 'Active power, phase 2',
        },
        InputDesc_activePowerThirdFaze: {
            ru: 'Мощность активная, 3 фаза',
            en: 'Active power, phase 3',
        },
        InputDesc_activePowerSum: {
            ru: 'Мощность активная, суммарная',
            en: 'Active power, total',
        },
        InputDesc_fullPowerFirstFaze: {
            ru: 'Мощность полная, 1 фаза',
            en: 'Full power, phase 1',
        },
        InputDesc_fullPowerSecondFaze: {
            ru: 'Мощность полная, 2 фаза',
            en: 'Full power, phase 2',
        },
        InputDesc_fullPowerThirdFaze: {
            ru: 'Мощность полная, 3 фаза',
            en: 'Full power, phase 3',
        },
        InputDesc_fullPowerSum: {
            ru: 'Мощность полная, суммарная',
            en: 'Full power, total',
        },
        InputDesc_powerFactorFirstFaze: {
            ru: 'Коэффициент мощности, 1 фаза',
            en: 'Power factor, phase 1',
        },
        InputDesc_powerFactorSecondFaze: {
            ru: 'Коэффициент мощности, 2 фаза',
            en: 'Power factor, phase 2',
        },
        InputDesc_powerFactorThirdFaze: {
            ru: 'Коэффициент мощности, 3 фаза',
            en: 'Power factor, phase 3',
        },
        InputDesc_powerFactorSum: {
            ru: 'Коэффициент мощности суммарный',
            en: 'Power factor, total',
        },
        InputDesc_engConsumptionFirstRateActStraight: {
            ru: 'Энергия активная прямая (1 тариф)',
            en: 'Active energy, straight (tariff 1)',
        },
        InputDesc_engConsumptionFirstRateActReverse: {
            ru: 'Энергия активная обратная (1 тариф)',
            en: 'Active energy, reverse (tariff 1)',
        },
        InputDesc_engConsumptionFirstRateReactStraight: {
            ru: 'Энергия реактивная прямая (1 тариф)',
            en: 'Reactive energy, straight (tariff 1)',
        },
        InputDesc_engConsumptionFirstRateReactReverse: {
            ru: 'Энергия реактивная обратная (1 тариф)',
            en: 'Reactive energy, reverse (tariff 1)',
        },
        InputDesc_engConsumptionSecondRateActStraight: {
            ru: 'Энергия активная прямая (2 тариф)',
            en: 'Active energy, straight (tariff 2)',
        },
        InputDesc_engConsumptionSecondRateActReverse: {
            ru: 'Энергия активная обратная (2 тариф)',
            en: 'Active energy, reverse (tariff 2)',
        },
        InputDesc_engConsumptionSecondRateReactStraight: {
            ru: 'Энергия реактивная прямая (2 тариф)',
            en: 'Reactive energy, straight (tariff 2)',
        },
        InputDesc_engConsumptionSecondRateReactReverse: {
            ru: 'Энергия реактивная обратная (2 тариф)',
            en: 'Reactive energy, reverse (tariff 2)',
        },
        InputDesc_engConsumptionThirdRateActStraight: {
            ru: 'Энергия активная прямая (3 тариф)',
            en: 'Active energy, straight (tariff 3)',
        },
        InputDesc_engConsumptionThirdRateActReverse: {
            ru: 'Энергия активная обратная (3 тариф)',
            en: 'Active energy, reverse (tariff 3)',
        },
        InputDesc_engConsumptionThirdRateReactStraight: {
            ru: 'Энергия реактивная прямая (3 тариф)',
            en: 'Reactive energy, straight (tariff 3)',
        },
        InputDesc_engConsumptionThirdRateReactReverse: {
            ru: 'Энергия реактивная обратная (3 тариф)',
            en: 'Reactive energy, reverse (tariff 3)',
        },
        InputDesc_engConsumptionFourthRateActStraight: {
            ru: 'Энергия активная прямая (4 тариф)',
            en: 'Active energy, straight (tariff 4)',
        },
        InputDesc_engConsumptionFourthRateActReverse: {
            ru: 'Энергия активная обратная (4 тариф)',
            en: 'Active energy, reverse (tariff 4)',
        },
        InputDesc_engConsumptionFourthRateReactStraight: {
            ru: 'Энергия реактивная прямая (4 тариф)',
            en: 'Reactive energy, straight (tariff 4)',
        },
        InputDesc_engConsumptionFourthRateReactReverse: {
            ru: 'Энергия реактивная обратная (4 тариф)',
            en: 'Reactive energy, reverse (tariff 4)',
        },
        InputDesc_powerConsumptionSumActStraight: {
            ru: 'Энергия активная прямая (сумма тарифов)',
            en: 'Active energy, straight (sum of tariffs)',
        },
        InputDesc_powerConsumptionSumActReverse: {
            ru: 'Энергия активная обратная (сумма тарифов)',
            en: 'Active energy, reverse (sum of tariffs)',
        },
        InputDesc_powerConsumptionSumReactStraight: {
            ru: 'Энергия реактивная прямая (сумма тарифов)',
            en: 'Reactive energy, straight (sum of tariffs)',
        },
        InputDesc_powerConsumptionSumReactReverse: {
            ru: 'Энергия реактивная обратная (сумма тарифов)',
            en: 'Reactive energy, reverse (sum of tariffs)',
        },
        InputDesc_instantActivePower: {
            ru: 'Мгновенная активная мощность',
            en: 'Instantaneous active power',
        },
        InputDesc_transformationRatioVoltage: {
            ru: 'Коэффициент трансформации по напряжению',
            en: 'Transformer voltage ratio',
        },
        InputDesc_transformationRatioCurrent: {
            ru: 'Коэффициент трансформации по току',
            en: 'Current ratio',
        },
        InputDesc_temperatureObr: {
            ru: 'Температура обратного трубопровода',
            en: 'Return pipeline temperature',
        },
        InputDesc_temperaturePod: {
            ru: 'Температура подводящего трубопровода',
            en: 'Supply pipeline temperature',
        },
        InputDesc_timeI: {
            ru: 'Время счета',
            en: 'Время счета',
        },
        InputDesc_timeN: {
            ru: 'Время работы в нештатном режиме',
            en: 'Время работы в нештатном режиме',
        },
        InputDesc_timeSh: {
            ru: 'Время работы в штатном режиме',
            en: 'Время работы в штатном режиме',
        },
        InputDesc_timeSh1TS: {
            ru: 'Время работы ТС в штатном режиме (Ввод 1)',
            en: 'Время работы ТС в штатном режиме (Ввод 1)',
        },
        InputDesc_timeSh2TS: {
            ru: 'Время работы ТС в штатном режиме (Ввод 2)',
            en: 'Время работы ТС в штатном режиме (Ввод 2)',
        },
        InputDesc_timeSh3TS: {
            ru: 'Время работы ТС в штатном режиме (Ввод 3)',
            en: 'Время работы ТС в штатном режиме (Ввод 3)',
        },
        InputDesc_timeSh4TS: {
            ru: 'Время работы ТС в штатном режиме (Ввод 4)',
            en: 'Время работы ТС в штатном режиме (Ввод 4)',
        },
        InputDesc_timeNsh1TS: {
            ru: 'Время работы ТС в нештатном режиме (Ввод 1)',
            en: 'Время работы ТС в нештатном режиме (Ввод 1)',
        },
        InputDesc_timeNsh2TS: {
            ru: 'Время работы ТС в нештатном режиме (Ввод 2)',
            en: 'Время работы ТС в нештатном режиме (Ввод 2)',
        },
        InputDesc_timeNsh3TS: {
            ru: 'Время работы ТС в нештатном режиме (Ввод 3)',
            en: 'Время работы ТС в нештатном режиме (Ввод 3)',
        },
        InputDesc_timeNsh4TS: {
            ru: 'Время работы ТС в нештатном режиме (Ввод 4)',
            en: 'Время работы ТС в нештатном режиме (Ввод 4)',
        },
        InputDesc_deltaTemp: {
            ru: 'Перепад температур',
            en: 'Differential temperature',
        },
        InputDesc_resistancePod: {
            ru: 'Сопротивление подводящего трубопровода',
            en: 'Supply pipeline resistance',
        },
        InputDesc_resistanceObr: {
            ru: 'Сопротивление обратного трубопровода',
            en: 'Return pipeline resistance',
        },
        InputDesc_volume: {
            ru: 'Объем',
            en: 'Volume',
        },
        InputDesc_consumption: {
            ru: 'Потребление',
            en: 'Consumption',
        },
        InputDesc_timeOfWork: {
            ru: 'Время работы',
            en: 'Active time',
        },
        InputDesc_versionSoftware: {
            ru: 'Версия ПО',
            en: 'Software version',
        },
        InputDesc_volume1Input1: {
            ru: 'Накопленные показания объема по подающему трубопроводу (Ввод 1)',
            en: 'Stored meter readings of volume passing through a supply pipeline (Input 1)',
        },
        InputDesc_volume1Input2: {
            ru: 'Накопленные показания объема по подающему трубопроводу (Ввод 2)',
            en: 'Stored meter readings of volume passing through a supply pipeline (Input 2)',
        },
        InputDesc_volume1Input3: {
            ru: 'Накопленные показания объема по подающему трубопроводу (Ввод 3)',
            en: 'Stored meter readings of volume passing through a supply pipeline (Input 3)',
        },
        InputDesc_volume1Input4: {
            ru: 'Накопленные показания объема по подающему трубопроводу (Ввод 4)',
            en: 'Stored meter readings of volume passing through a supply pipeline (Input 4)',
        },
        InputDesc_volume1Input5: {
            ru: 'Накопленные показания объема по подающему трубопроводу (Ввод 5)',
            en: 'Stored meter readings of volume passing through a supply pipeline (Input 5)',
        },
        InputDesc_volume1Input6: {
            ru: 'Накопленные показания объема по подающему трубопроводу (Ввод 6)',
            en: 'Stored meter readings of volume passing through a supply pipeline (Input 6)',
        },
        InputDesc_massFlowPerHour1TS: {
            ru: 'Массовый расход ТС по подающему трубопроводу (Ввод 1)',
            en: 'Массовый расход ТС по подающему трубопроводу (Ввод 1)'
        },
        InputDesc_massFlowPerHour2TS: {
            ru: 'Массовый расход ТС по подающему трубопроводу (Ввод 2)',
            en: 'Массовый расход ТС по подающему трубопроводу (Ввод 2)'
        },
        InputDesc_massFlowPerHour3TS: {
            ru: 'Массовый расход ТС по подающему трубопроводу (Ввод 3)',
            en: 'Массовый расход ТС по подающему трубопроводу (Ввод 3)'
        },
        InputDesc_massFlowPerHour1: {
            ru: 'Массовый расход по подающему трубопроводу (Ввод 1)',
            en: 'Массовый расход по подающему трубопроводу (Ввод 1)'
        },
        InputDesc_massFlowPerHour2: {
            ru: 'Массовый расход по подающему трубопроводу (Ввод 2)',
            en: 'Массовый расход по подающему трубопроводу (Ввод 2)'
        },
        InputDesc_massFlowPerHour3: {
            ru: 'Массовый расход по подающему трубопроводу (Ввод 3)',
            en: 'Массовый расход по подающему трубопроводу (Ввод 3)'
        },
        InputDesc_massFlowPerHour4: {
            ru: 'Массовый расход по подающему трубопроводу (Ввод 4)',
            en: 'Массовый расход по подающему трубопроводу (Ввод 4)'
        },
        InputDesc_massFlowPerHour5: {
            ru: 'Массовый расход по подающему трубопроводу (Ввод 5)',
            en: 'Массовый расход по подающему трубопроводу (Ввод 5)'
        },
        InputDesc_massFlowPerHour6: {
            ru: 'Массовый расход по подающему трубопроводу (Ввод 6)',
            en: 'Массовый расход по подающему трубопроводу (Ввод 6)'
        },
        InputDesc_thermalEnergyInput1: {
            ru: 'Колличество тепловой энергии (Ввод 1)',
            en: 'Amount of thermal energy (Input 1)',
        },
        InputDesc_thermalEnergyInput2: {
            ru: 'Колличество тепловой энергии (Ввод 2)',
            en: 'Amount of thermal energy (Input 2)',
        },
        InputDesc_thermalEnergyInput3: {
            ru: 'Колличество тепловой энергии (Ввод 3)',
            en: 'Amount of thermal energy (Input 3)',
        },
        InputDesc_thermalEnergyInput4: {
            ru: 'Колличество тепловой энергии (Ввод 4)',
            en: 'Amount of thermal energy (Input 4)',
        },
        InputDesc_thermalEnergyInput5: {
            ru: 'Колличество тепловой энергии (Ввод 5)',
            en: 'Amount of thermal energy (Input 5)',
        },
        InputDesc_thermalEnergyInput6: {
            ru: 'Колличество тепловой энергии (Ввод 6)',
            en: 'Amount of thermal energy (Input 6)',
        },
        InputDesc_thermalEnergyInput1TS: {
            ru: 'Колличество тепловой энергии ТС (Ввод 1)',
            en: 'Amount of thermal energy heating system (Input 1)',
        },
        InputDesc_thermalEnergyInput2TS: {
            ru: 'Колличество тепловой энергии ТС (Ввод 2)',
            en: 'Amount of thermal energy heating system (Input 2)',
        },
        InputDesc_thermalEnergyInput3TS: {
            ru: 'Колличество тепловой энергии ТС (Ввод 3)',
            en: 'Amount of thermal energy heating system (Input 3)',
        },
        InputDesc_thermalEnergyInput4TS: {
            ru: 'Колличество тепловой энергии ТС (Ввод 4)',
            en: 'Amount of thermal energy heating system (Input 4)',
        },
        InputDesc_thermalEnergyInputTs: {
            ru: 'Колличество тепловой энергии ТС',
            en: 'Amount of thermal energy heating system',
        },
        InputDesc_thermalEnergyInputHotWater: {
            ru: 'Колличество тепловой энергии ГВС',
            en: 'DHW heat energy',
        },
        InputDesc_weight1Input6: {
            ru: 'Накопленные показания массы по подающему трубопроводу (Ввод 6)',
            en: 'Stored meter readings of weight passing through a supply pipeline (Input 6)',
        },
        InputDesc_weight1Input5: {
            ru: 'Накопленные показания массы по подающему трубопроводу (Ввод 5)',
            en: 'Stored meter readings of weight passing through a supply pipeline (Input 5)',
        },
        InputDesc_weight1Input4: {
            ru: 'Накопленные показания массы по подающему трубопроводу (Ввод 4)',
            en: 'Stored meter readings of weight passing through a supply pipeline (Input 4)',
        },
        InputDesc_weight1Input3: {
            ru: 'Накопленные показания массы по подающему трубопроводу (Ввод 3)',
            en: 'Stored meter readings of weight passing through a supply pipeline (Input 3)',
        },
        InputDesc_weight1Input2: {
            ru: 'Накопленные показания массы по подающему трубопроводу (Ввод 2)',
            en: 'Stored meter readings of weight passing through a supply pipeline (Input 2)',
        },
        InputDesc_weight1Input1: {
            ru: 'Накопленные показания массы по подающему трубопроводу (Ввод 1)',
            en: 'Stored meter readings of weight passing through a supply pipeline (Input 1)',
        },
        InputDesc_weight1Input1TS: {
            ru: 'Накопленные показания ТС массы по подающему трубопроводу (Ввод 1)',
            en: 'Stored meter heating system readings of weight passing through a supply pipeline (Input 1)',
        },
        InputDesc_weight1Input2TS: {
            ru: 'Накопленные показания ТС массы по подающему трубопроводу (Ввод 2)',
            en: 'Stored meter heating system readings of weight passing through a supply pipeline (Input 2)',
        },
        InputDesc_weight1Input3TS: {
            ru: 'Накопленные показания ТС массы по подающему трубопроводу (Ввод 3)',
            en: 'Stored meter heating system readings of weight passing through a supply pipeline (Input 3)',
        },
        InputDesc_weightDeltaInput1: {
            ru: 'Разность массы по подающему и обратному трубопроводам (Ввод 1)',
            en: 'Differential weight passing through supply and return pipelines (Input 1)',
        },
        InputDesc_weightDeltaInput2: {
            ru: 'Разность массы по подающему и обратному трубопроводам (Ввод 2)',
            en: 'Differential weight passing through supply and return pipelines (Input 2)',
        },
        InputDesc_volume2Input1: {
            ru: 'Накопленные показания объема по обратному трубопроводу (Ввод 1)',
            en: 'Stored meter readings of volume passing through a return pipeline (Input 1)',
        },
        InputDesc_volume2Input2: {
            ru: 'Накопленные показания объема по обратному трубопроводу (Ввод 2)',
            en: 'Stored meter readings of volume passing through a return pipeline (Input 2)',
        },
        InputDesc_volumeDeltaInput1: {
            ru: 'Разность объема по подающему и обратному трубопроводам (Ввод 1)',
            en: 'Differential volume passing through supply and return pipeline (Input 1)',
        },
        InputDesc_volumeDeltaInput2: {
            ru: 'Разность объема по подающему и обратному трубопроводам (Ввод 2)',
            en: 'Differential volume passing through supply and return pipeline (Input 2)',
        },
        InputDesc_weight2Input1: {
            ru: 'Накопленные показания массы по обратному трубопроводу (Ввод 1)',
            en: 'Stored meter readings of weight passing through a return pipeline (Input 1)',
        },
        InputDesc_weight2Input2: {
            ru: 'Накопленные показания массы по обратному трубопроводу (Ввод 2)',
            en: 'Stored meter readings of weight passing through a return pipeline (Input 2)',
        },
        InputDesc_thermalEnergyGVSInput1: {
            ru: 'Колличество тепловой энергии ГВС (Ввод 1)',
            en: 'Колличество тепловой энергии ГВС (Ввод 1)',
        },
        InputDesc_thermalEnergyGVSInput2: {
            ru: 'Колличество тепловой энергии ГВС (Ввод 2)',
            en: 'Колличество тепловой энергии ГВС (Ввод 2)',
        },
        InputDesc_power: {
            ru: 'Мощность',
            en: 'Power',
        },
        InputDesc_temperature1Input1: {
            ru: 'Температура теплоносителя в трубе 1 (Ввод 1)',
            en: 'Температура теплоносителя в трубе 1 (Ввод 1)'
        },
        InputDesc_temperature2Input1: {
            ru: 'Температура теплоносителя в трубе 2 (Ввод 1)',
            en: 'Температура теплоносителя в трубе 2 (Ввод 1)'
        },
        InputDesc_temperature3Input1: {
            ru: 'Температура теплоносителя в трубе 3 (Ввод 1)',
            en: 'Температура теплоносителя в трубе 3 (Ввод 1)'
        },
        InputDesc_temperature1Input2: {
            ru: 'Температура теплоносителя в трубе 1 (Ввод 2)',
            en: 'Температура теплоносителя в трубе 1 (Ввод 2)'
        },
        InputDesc_temperature2Input2: {
            ru: 'Температура теплоносителя в трубе 2 (Ввод 2)',
            en: 'Температура теплоносителя в трубе 2 (Ввод 2)'
        },
        InputDesc_temperature3Input2: {
            ru: 'Температура теплоносителя в трубе 3 (Ввод 2)',
            en: 'Температура теплоносителя в трубе 3 (Ввод 2)'
        },
        InputDesc_timeIInput1: {
            ru: 'Общее время работы (Ввод 1)',
            en: 'Общее время работы (Ввод 1)'
        },
        InputDesc_timeIInput2: {
            ru: 'Общее время работы (Ввод 2)',
            en: 'Общее время работы (Ввод 2)'
        },
        InputDesc_temperatureDiffInput1: {
            ru: 'Разность температур (Ввод 1)',
            en: 'Differential temperature (Input 1)'
        },
        InputDesc_temperatureDiffInput2: {
            ru: 'Разность температур (Ввод 2)',
            en: 'Differential temperature (Input 2)'
        },
        InputDesc_temperatureHot1: {
            ru: 'Температура горячей воды по подающему трубопроводу  (Ввод 1)',
            en: 'Temperature of hot water passing through a supply pipeline (Input 1)'
        },
        InputDesc_temperatureHot2: {
            ru: 'Температура горячей воды по подающему трубопроводу  (Ввод 2)',
            en: 'Temperature of hot water passing through a supply pipeline (Input 2)'
        },
        InputDesc_temperatureHot3: {
            ru: 'Температура горячей воды по подающему трубопроводу  (Ввод 3)',
            en: 'Temperature of hot water passing through a supply pipeline (Input 3)'
        },
        InputDesc_temperatureHot4: {
            ru: 'Температура горячей воды по подающему трубопроводу  (Ввод 4)',
            en: 'Temperature of hot water passing through a supply pipeline (Input 4)'
        },
        InputDesc_temperatureCold: {
            ru: 'Температура холодной воды по подающему трубопроводу',
            en: 'Temperature of cold water passing through a supply pipeline'
        },
        InputDesc_consumptionVolume1Input1: {
            ru: 'Объемный расход теплоносителя по трубопроводу 1 (Ввод 1)',
            en: 'Объемный расход теплоносителя по трубопроводу 1 (Ввод 1)'
        },
        InputDesc_consumptionVolume2Input1: {
            ru: 'Объемный расход теплоносителя по трубопроводу 2 (Ввод 1)',
            en: 'Объемный расход теплоносителя по трубопроводу 2 (Ввод 1)'
        },
        InputDesc_consumptionVolume3Input1: {
            ru: 'Объемный расход теплоносителя по трубопроводу 3 (Ввод 1)',
            en: 'Объемный расход теплоносителя по трубопроводу 3 (Ввод 1)'
        },
        InputDesc_consumptionVolume1Input2: {
            ru: 'Объемный расход теплоносителя по трубопроводу 1 (Ввод 2)',
            en: 'Объемный расход теплоносителя по трубопроводу 1 (Ввод 2)'
        },
        InputDesc_consumptionVolume2Input2: {
            ru: 'Объемный расход теплоносителя по трубопроводу 2 (Ввод 2)',
            en: 'Объемный расход теплоносителя по трубопроводу 2 (Ввод 2)'
        },
        InputDesc_consumptionVolume3Input2: {
            ru: 'Объемный расход теплоносителя по трубопроводу 3 (Ввод 2)',
            en: 'Объемный расход теплоносителя по трубопроводу 3 (Ввод 2)'
        },
        InputDesc_flowTemperature: {
            ru: 'Температура в подающем трубопроводе',
            en: 'Temperature in the sending pipeline'
        },
        InputDesc_returnTemperature: {
            ru: 'Температура в обратном трубопроводе',
            en: 'Temperature in the return pipeline'
        },
        InputDesc_temperatureDifference: {
            ru: 'Разница температур',
            en: 'Temperature difference'
        },
        InputDesc_volumeFlow: {
            ru: 'Мгновенный расход',
            en: 'Instantaneous flow rate'
        },
        InputDesc_temperaturePod1: {
            ru: 'Температура подводящего трубопровода (Ввод 1)',
            en: 'Supply pipeline temperature (Input 1)'
        },
        InputDesc_temperatureObr1: {
            ru: 'Температура обратного трубопровода (Ввод 1)',
            en: 'Return pipeline temperature (Input 1)'
        },
        InputDesc_temperatureAdd1: {
            ru: 'Температура дополнительного трубопровода (Ввод 1)',
            en: 'Additional pipeline temperature (Input 1)'
        },
        InputDesc_temperaturePod2: {
            ru: 'Температура подводящего трубопровода (Ввод 2)',
            en: 'Supply pipeline temperature (Input 2)'
        },
        InputDesc_temperatureObr2: {
            ru: 'Температура обратного трубопровода (Ввод 2)',
            en: 'Return pipeline temperature (Input 2)'
        },
        InputDesc_temperatureAdd2: {
            ru: 'Температура дополнительного трубопровода (Ввод 2)',
            en: 'Additional pipeline temperature (Input 2)'
        },
        InputDesc_volume3Input1: {
            ru: 'Накопленные показания объема по дополнительному трубопроводу (Ввод 1)',
            en: 'Stored meter readings of volume passing through a additional pipeline (Input 1)',
        },
        InputDesc_volume3Input2: {
            ru: 'Накопленные показания объема по дополнительному трубопроводу (Ввод 2)',
            en: 'Stored meter readings of volume passing through a additional pipeline (Input 2)',
        },
        InputDesc_weight3Input1: {
            ru: 'Накопленные показания массы по дополнительному трубопроводу (Ввод 1)',
            en: 'Stored meter readings of weight passing through a additional pipeline (Input 1)',
        },
        InputDesc_weight3Input2: {
            ru: 'Накопленные показания массы по дополнительному трубопроводу (Ввод 2)',
            en: 'Stored meter readings of weight passing through a additional pipeline (Input 2)',
        },
        InputDesc_weight3Input3: {
            ru: 'Накопленные показания массы по дополнительному трубопроводу (Ввод 3)',
            en: 'Stored meter readings of weight passing through a additional pipeline (Input 3)',
        },
        InputDesc_temperatureColdInput1: {
            ru: 'Температура холодной воды (Ввод 1)',
            en: 'Cold water temperature (Input 1)',
        },
        InputDesc_temperatureColdInput2: {
            ru: 'Температура холодной воды (Ввод 2)',
            en: 'Cold water temperature (Input 2)',
        },
        InputDesc_pressureColdInput1: {
            ru: 'Давление холодной воды (Ввод 1)',
            en: 'Cold water pressure (Input 1)',
        },
        InputDesc_pressureColdInput2: {
            ru: 'Давление холодной воды (Ввод 2)',
            en: 'Cold water pressure (Input 2)',
        },
        InputDesc_pressure1Input1: {
            ru: 'Давление подводящего трубопровода (Ввод 1)',
            en: 'Supply pipeline pressure (Input 1)'
        },
        InputDesc_pressure1Input3: {
            ru: 'Давление подводящего трубопровода (Ввод 3)',
            en: 'Supply pipeline pressure (Input 3)'
        },
        InputDesc_pressure1Input4: {
            ru: 'Давление подводящего трубопровода (Ввод 4)',
            en: 'Supply pipeline pressure (Input 4)'
        },
        InputDesc_pressure2Input1: {
            ru: 'Давление обратного трубопровода (Ввод 1)',
            en: 'Return pipeline pressure (Input 1)'
        },
        InputDesc_pressure3Input1: {
            ru: 'Давление дополнительного трубопровода (Ввод 1)',
            en: 'Additional pipeline pressure (Input 1)'
        },
        InputDesc_pressure1Input2: {
            ru: 'Давление подводящего трубопровода (Ввод 2)',
            en: 'Supply pipeline pressure (Input 2)'
        },
        InputDesc_pressure2Input2: {
            ru: 'Давление обратного трубопровода (Ввод 2)',
            en: 'Return pipeline pressure (Input 2)'
        },
        InputDesc_pressure3Input2: {
            ru: 'Давление дополнительного трубопровода (Ввод 2)',
            en: 'Additional pipeline pressure (Input 2)'
        },
        InputDesc_temperatureAirInput1: {
            ru: 'Температура наружного воздуха (Ввод 1)',
            en: 'Out air temperature (Input 1)',
        },
        InputDesc_temperatureAirInput2: {
            ru: 'Температура наружного воздуха (Ввод 2)',
            en: 'Out air temperature (Input 2)',
        },
        InputDesc_timeStopInput1: {
            ru: 'Время остановки счета (Ввод 1)',
            en: 'Time stop read (Input 1)',
        },
        InputDesc_timeStopInput2: {
            ru: 'Время остановки счета (Ввод 2)',
            en: 'Time stop read (Input 2)',
        },
        InputDesc_tempIn: {
            ru: 'Температура в подающем трубопроводе, градусы С',
            en: 'Temperature in the sending pipeline, Celsius degree',
        },
        InputDesc_tempOut: {
            ru: 'Температура в обратном трубопроводе, градусы С',
            en: 'Temperature in the return pipeline, Celsius degree',
        },
        InputDesc_tempDelta: {
            ru: 'Разница температур, градусы С',
            en: 'Temperature difference, Celsius degree',
        },
        InputDesc_totalOperatingTime: {
            ru: 'Время наработки, часы',
            en: 'The time between, hours',
        },
        InputDesc_volumeFlowPerHour1: {
            ru: 'Объемный расход (Ввод 1)',
            en: 'Volume flow (Input 1)',
        },
        InputDesc_volumeFlowPerHour2: {
            ru: 'Объемный расход (Ввод 2)',
            en: 'Volume flow (Input 2)',
        },
        InputDesc_volumeFlowPerHour3: {
            ru: 'Объемный расход (Ввод 3)',
            en: 'Volume flow (Input 3)',
        },
        InputDesc_volumeFlowPerHour4: {
            ru: 'Объемный расход (Ввод 4)',
            en: 'Volume flow (Input 4)',
        },
        InputDesc_volumeFlowPerHour5: {
            ru: 'Объемный расход (Ввод 5)',
            en: 'Volume flow (Input 5)',
        },
        InputDesc_volumeFlowPerHour6: {
            ru: 'Объемный расход (Ввод 6)',
            en: 'Volume flow (Input 6)',
        },
        InputDesc_heatEnergyInput1: {
            ru: 'Тепловая мощность (Ввод 1)',
            en: 'Тепловая мощность (Ввод 1)',
        },
        InputDesc_heatEnergyInput2: {
            ru: 'Тепловая мощность (Ввод 2)',
            en: 'Тепловая мощность (Ввод 2)',
        },
        InputDesc_heatEnergyInput3: {
            ru: 'Тепловая мощность (Ввод 3)',
            en: 'Тепловая мощность (Ввод 3)',
        },
        InputDesc_heatEnergyInput4: {
            ru: 'Тепловая мощность (Ввод 4)',
            en: 'Тепловая мощность (Ввод 4)',
        },
        InputDesc_heatEnergyInput5: {
            ru: 'Тепловая мощность (Ввод 5)',
            en: 'Тепловая мощность (Ввод 5)',
        },
        InputDesc_heatEnergyInput6: {
            ru: 'Тепловая мощность (Ввод 6)',
            en: 'Тепловая мощность (Ввод 6)',
        },
        InputDesc_heatEnergyInput1TS: {
            ru: 'Тепловая мощность ТС (Ввод 1)',
            en: 'Тепловая мощность heating system (Ввод 1)',
        },
        InputDesc_heatEnergyInput2TS: {
            ru: 'Тепловая мощность ТС (Ввод 2)',
            en: 'Тепловая мощность heating system (Ввод 2)',
        },
        InputDesc_heatEnergyInput3TS: {
            ru: 'Тепловая мощность ТС (Ввод 3)',
            en: 'Тепловая мощность heating system (Ввод 3)',
        },
        InputDesc_massFlowHourInput1: {
            ru: 'Среднечасовое значение расхода (Ввод 1)',
            en: 'Среднечасовое значение расхода (Ввод 1)',
        },
        InputDesc_massFlowHourInput2: {
            ru: 'Среднечасовое значение расхода (Ввод 2)',
            en: 'Среднечасовое значение расхода (Ввод 2)',
        },
        InputDesc_massFlowHourInput3: {
            ru: 'Среднечасовое значение расхода (Ввод 3)',
            en: 'Среднечасовое значение расхода (Ввод 3)',
        },
        InputDesc_resistanceInput1: {
            ru: 'Сопротивление ПТ3 (Ввод 1)',
            en: 'Resistance PT3 (Input 1)',
        },
        InputDesc_resistanceInput2: {
            ru: 'Сопротивление ПТ3 (Ввод 2)',
            en: 'Resistance PT3 (Input 2)',
        },
        InputDesc_resistanceInput3: {
            ru: 'Сопротивление ПТ3 (Ввод 3)',
            en: 'Resistance PT3 (Input 3)',
        },
        InputDesc_temperatureTransformedInput1: {
            ru: 'Преобразованная температура (Ввод 1)',
            en: 'Transformed temperature (Input 1)',
        },
        InputDesc_temperatureTransformedInput2: {
            ru: 'Преобразованная температура (Ввод 2)',
            en: 'Transformed temperature (Input 2)',
        },
        InputDesc_temperatureTransformedInput3: {
            ru: 'Преобразованная температура (Ввод 3)',
            en: 'Transformed temperature (Input 3)',
        },
        InputDesc_temperatureTransformedInput4: {
            ru: 'Преобразованная температура (Ввод 4)',
            en: 'Transformed temperature (Input 4)',
        },
        InputDesc_temperatureTransformedInput5: {
            ru: 'Преобразованная температура (Ввод 5)',
            en: 'Transformed temperature (Input 5)',
        },
        InputDesc_densityColdWaterInput1: {
            ru: 'Плотность теплоносителя в канале ХВ (Ввод 1)',
            en: 'Плотность теплоносителя в канале ХВ (Ввод 1)',
        },
        InputDesc_densityInput1: {
            ru: 'Плотность теплоносителя в канале (Ввод 1)',
            en: 'Плотность теплоносителя в канале (Ввод 1)',
        },
        InputDesc_densityInput2: {
            ru: 'Плотность теплоносителя в канале (Ввод 2)',
            en: 'Плотность теплоносителя в канале (Ввод 2)',
        },
        InputDesc_densityInput3: {
            ru: 'Плотность теплоносителя в канале (Ввод 3)',
            en: 'Плотность теплоносителя в канале (Ввод 3)',
        },
        InputDesc_densityInput4: {
            ru: 'Плотность теплоносителя в канале (Ввод 4)',
            en: 'Плотность теплоносителя в канале (Ввод 4)',
        },
        InputDesc_densityInput5: {
            ru: 'Плотность теплоносителя в канале (Ввод 5)',
            en: 'Плотность теплоносителя в канале (Ввод 5)',
        },
        InputDesc_temperatureInput1: {
            ru: 'Измеренная температура (Ввод 1)',
            en: 'Measured temperature (Input 1)',
        },
        InputDesc_temperatureInput2: {
            ru: 'Измеренная температура (Ввод 2)',
            en: 'Measured temperature (Input 2)',
        },
        InputDesc_temperatureInput3: {
            ru: 'Измеренная температура (Ввод 3)',
            en: 'Measured temperature (Input 3)',
        },
        InputDesc_temperatureInput4: {
            ru: 'Измеренная температура (Ввод 4)',
            en: 'Measured temperature (Input 4)',
        },
        InputDesc_temperatureInput5: {
            ru: 'Измеренная температура (Ввод 5)',
            en: 'Measured temperature (Input 5)',
        },
        InputDesc_temperatureInput6: {
            ru: 'Измеренная температура (Ввод 6)',
            en: 'Measured temperature (Input 6)',
        },
        InputDesc_temperatureInput7: {
            ru: 'Измеренная температура (Ввод 7)',
            en: 'Measured temperature (Input 7)',
        },
        InputDesc_temperatureInput8: {
            ru: 'Измеренная температура (Ввод 8)',
            en: 'Measured temperature (Input 8)',
        },
        InputDesc_temperatureInput9: {
            ru: 'Измеренная температура (Ввод 9)',
            en: 'Measured temperature (Input 9)',
        },
        InputDesc_temperatureInput10: {
            ru: 'Измеренная температура (Ввод 10)',
            en: 'Measured temperature (Input 10)',
        },
        InputDesc_temperatureInput11: {
            ru: 'Измеренная температура (Ввод 11)',
            en: 'Measured temperature (Input 11)',
        },
        InputDesc_temperatureInput12: {
            ru: 'Измеренная температура (Ввод 12)',
            en: 'Measured temperature (Input 12)',
        },
        InputDesc_pulseInput1: {
            ru: 'Импульсный вход 1',
            en: 'Pulse input 1',
        },
        InputDesc_pulseInput2: {
            ru: 'Импульсный вход 2',
            en: 'Pulse input 2',
        },
        InputDesc_pulseInput3: {
            ru: 'Импульсный вход 3',
            en: 'Pulse input 3',
        },
        InputDesc_pulseInput4: {
            ru: 'Импульсный вход 4',
            en: 'Pulse input 4',
        },
        InputDesc_pulseWeight1: {
            ru: 'Вес импульсного 1',
            en: 'Pulse weight 1',
        },
        InputDesc_pulseWeight2: {
            ru: 'Вес импульсного 2',
            en: 'Pulse weight 2',
        },
        InputDesc_pulseWeight3: {
            ru: 'Вес импульсного 3',
            en: 'Pulse weight 3',
        },
        InputDesc_pulseWeight4: {
            ru: 'Вес импульсного 4',
            en: 'Pulse weight 4',
        },
        InputDesc_pulseDuration1: {
            ru: 'Длительность импульсного входа 1',
            en: 'Pulse duration 1',
        },
        InputDesc_pulseDuration2: {
            ru: 'Длительность импульсного входа 2',
            en: 'Pulse duration 2',
        },
        InputDesc_pulseDuration3: {
            ru: 'Длительность импульсного входа 3',
            en: 'Pulse duration 3',
        },
        InputDesc_pulseDuration4: {
            ru: 'Длительность импульсного входа 4',
            en: 'Pulse duration 4',
        },
        InputDesc_pulseOutputWeight: {
            ru: 'Вес импульсного выхода',
            en: 'Weight pulse output',
        },
        InputDesc_pulseOutputDuration: {
            ru: 'Длительность импульсного выхода',
            en: 'Duration pulse output',
        },
        InputDesc_timeOfError: {
            ru: 'Время работы с ошибками',
            en: 'Time of work with error',
        },
        InputDesc_consumptionEnergy: {
            ru: 'Потребление энергии',
            en: 'Consumption of energy',
        },
        InputDesc_millisec: {
            ru: 'Миллисекунды',
            en: 'Millisecond',
        },
        InputDesc_outputPulse: {
            ru: 'Значение на импульсном выходе',
            en: 'Value on pulse output',
        },
        InputDesc_pressureInput1: {
            ru: 'Измеренное давление в 1й трубе',
            en: 'Измеренное давление в 1й трубе'
        },
        InputDesc_pressureInput2: {
            ru: 'Измеренное давление в 2й трубе',
            en: 'Измеренное давление в 2й трубе'
        },
        InputDesc_pressureInput3: {
            ru: 'Измеренное давление в 3й трубе',
            en: 'Измеренное давление в 3й трубе'
        },
        InputDesc_pressureInput4: {
            ru: 'Измеренное давление в 4й трубе',
            en: 'Измеренное давление в 4й трубе'
        },
        InputDesc_pressureInput5: {
            ru: 'Измеренное давление в 5й трубе',
            en: 'Измеренное давление в 5й трубе'
        },
        InputDesc_pressureInput6: {
            ru: 'Измеренное давление в 6й трубе',
            en: 'Измеренное давление в 6й трубе'
        },
        InputDesc_pressureInput7: {
            ru: 'Измеренное давление в 7й трубе',
            en: 'Измеренное давление в 7й трубе'
        },
        InputDesc_pressureInput8: {
            ru: 'Измеренное давление в 8й трубе',
            en: 'Измеренное давление в 8й трубе'
        },
        InputDesc_pressureInput9: {
            ru: 'Измеренное давление в 9й трубе',
            en: 'Измеренное давление в 9й трубе'
        },
        InputDesc_pressureInput10: {
            ru: 'Измеренное давление в 10й трубе',
            en: 'Измеренное давление в 10й трубе'
        },
        InputDesc_pressureInput11: {
            ru: 'Измеренное давление в 11й трубе',
            en: 'Измеренное давление в 11й трубе'
        },
        InputDesc_pressureInput12: {
            ru: 'Измеренное давление в 12й трубе',
            en: 'Измеренное давление в 12й трубе'
        },
        InputDesc_consumeGasVolumeInput1: {
            ru: 'Объемный расход газа в 1м трубопроводе',
            en: 'Объемный расход газа в 1м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput2: {
            ru: 'Объемный расход газа в 2м трубопроводе',
            en: 'Объемный расход газа в 2м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput3: {
            ru: 'Объемный расход газа в 3м трубопроводе',
            en: 'Объемный расход газа в 3м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput4: {
            ru: 'Объемный расход газа в 4м трубопроводе',
            en: 'Объемный расход газа в 4м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput5: {
            ru: 'Объемный расход газа в 5м трубопроводе',
            en: 'Объемный расход газа в 5м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput6: {
            ru: 'Объемный расход газа в 6м трубопроводе',
            en: 'Объемный расход газа в 6м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput7: {
            ru: 'Объемный расход газа в 7м трубопроводе',
            en: 'Объемный расход газа в 7м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput8: {
            ru: 'Объемный расход газа в 8м трубопроводе',
            en: 'Объемный расход газа в 8м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput9: {
            ru: 'Объемный расход газа в 9м трубопроводе',
            en: 'Объемный расход газа в 9м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput10: {
            ru: 'Объемный расход газа в 10м трубопроводе',
            en: 'Объемный расход газа в 10м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput11: {
            ru: 'Объемный расход газа в 11м трубопроводе',
            en: 'Объемный расход газа в 11м трубопроводе'
        },
        InputDesc_consumeGasVolumeInput12: {
            ru: 'Объемный расход газа в 12м трубопроводе',
            en: 'Объемный расход газа в 12м трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion1: {
            ru: 'Удельная объемная теплота сгорания в 1 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 1 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion2: {
            ru: 'Удельная объемная теплота сгорания в 2 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 2 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion3: {
            ru: 'Удельная объемная теплота сгорания в 3 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 3 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion4: {
            ru: 'Удельная объемная теплота сгорания в 4 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 4 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion5: {
            ru: 'Удельная объемная теплота сгорания в 5 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 5 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion6: {
            ru: 'Удельная объемная теплота сгорания в 6 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 6 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion7: {
            ru: 'Удельная объемная теплота сгорания в 7 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 7 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion8: {
            ru: 'Удельная объемная теплота сгорания в 8 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 8 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion9: {
            ru: 'Удельная объемная теплота сгорания в 9 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 9 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion10: {
            ru: 'Удельная объемная теплота сгорания в 10 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 10 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion11: {
            ru: 'Удельная объемная теплота сгорания в 11 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 11 трубопроводе'
        },
        InputDesc_specVolHeatOfCombustion12: {
            ru: 'Удельная объемная теплота сгорания в 12 трубопроводе',
            en: 'Удельная объемная теплота сгорания в 12 трубопроводе'
        },
        InputDesc_massInput1: {
            ru: 'Масса газа (Ввод 1)',
            en: 'Масса газа (Ввод 1)'
        },
        InputDesc_massInput2: {
            ru: 'Масса газа (Ввод 2)',
            en: 'Масса газа (Ввод 2)'
        },
        InputDesc_massInput3: {
            ru: 'Масса газа (Ввод 3)',
            en: 'Масса газа (Ввод 3)'
        },
        InputDesc_massInput4: {
            ru: 'Масса газа (Ввод 4)',
            en: 'Масса газа (Ввод 4)'
        },
        InputDesc_massInput5: {
            ru: 'Масса газа (Ввод 5)',
            en: 'Масса газа (Ввод 5)'
        },
        InputDesc_massInput6: {
            ru: 'Масса газа (Ввод 6)',
            en: 'Масса газа (Ввод 6)'
        },
        InputDesc_massInput7: {
            ru: 'Масса газа (Ввод 7)',
            en: 'Масса газа (Ввод 7)'
        },
        InputDesc_massInput8: {
            ru: 'Масса газа (Ввод 8)',
            en: 'Масса газа (Ввод 8)'
        },
        InputDesc_massInput9: {
            ru: 'Масса газа (Ввод 9)',
            en: 'Масса газа (Ввод 9)'
        },
        InputDesc_massInput10: {
            ru: 'Масса газа (Ввод 10)',
            en: 'Масса газа (Ввод 10)'
        },
        InputDesc_massInput11: {
            ru: 'Масса газа (Ввод 11)',
            en: 'Масса газа (Ввод 11)'
        },
        InputDesc_massInput12: {
            ru: 'Масса газа (Ввод 12)',
            en: 'Масса газа (Ввод 12)'
        },
        InputDesc_volumeInput1: {
            ru: 'Объем газа (Ввод 1)',
            en: 'Объем газа (Ввод 1)'
        },
        InputDesc_volumeInput2: {
            ru: 'Объем газа (Ввод 2)',
            en: 'Объем газа (Ввод 2)'
        },
        InputDesc_volumeInput3: {
            ru: 'Объем газа (Ввод 3)',
            en: 'Объем газа (Ввод 3)'
        },
        InputDesc_volumeInput4: {
            ru: 'Объем газа (Ввод 4)',
            en: 'Объем газа (Ввод 4)'
        },
        InputDesc_volumeInput5: {
            ru: 'Объем газа (Ввод 5)',
            en: 'Объем газа (Ввод 5)'
        },
        InputDesc_volumeInput6: {
            ru: 'Объем газа (Ввод 6)',
            en: 'Объем газа (Ввод 6)'
        },
        InputDesc_volumeInput7: {
            ru: 'Объем газа (Ввод 7)',
            en: 'Объем газа (Ввод 7)'
        },
        InputDesc_volumeInput8: {
            ru: 'Объем газа (Ввод 8)',
            en: 'Объем газа (Ввод 8)'
        },
        InputDesc_volumeInput9: {
            ru: 'Объем газа (Ввод 9)',
            en: 'Объем газа (Ввод 9)'
        },
        InputDesc_volumeInput10: {
            ru: 'Объем газа (Ввод 10)',
            en: 'Объем газа (Ввод 10)'
        },
        InputDesc_volumeInput11: {
            ru: 'Объем газа (Ввод 11)',
            en: 'Объем газа (Ввод 11)'
        },
        InputDesc_volumeInput12: {
            ru: 'Объем газа (Ввод 12)',
            en: 'Объем газа (Ввод 12)'
        },
        InputDesc_consumptionInput1: {
            ru: 'Расход теплоносителя в прямом трубопроводе',
            en: 'Расход теплоносителя в прямом трубопроводе',
        },
        InputDesc_consumptionInput2: {
            ru: 'Расход теплоносителя в обратном трубопроводе',
            en: 'Расход теплоносителя в обратном трубопроводе',
        },
        InputDesc_energy1: {
            ru: 'Активная энергия тариф 1',
            en: 'Active energy tariff 1',
        },
        InputDesc_energy2: {
            ru: 'Активная энергия тариф 2',
            en: 'Active energy tariff 2',
        },
        InputDesc_energy3: {
            ru: 'Активная энергия тариф 3',
            en: 'Active energy tariff 3',
        },
        InputDesc_energy4: {
            ru: 'Активная энергия тариф 4',
            en: 'Active energy tariff 4',
        },
        InputDesc_reactiveEnergy1: {
            ru: 'Реактивная энергия тариф 1',
            en: 'Reactive energy tariff 1',
        },
        InputDesc_reactiveEnergy2: {
            ru: 'Реактивная энергия тариф 2',
            en: 'Reactive energy tariff 2',
        },
        InputDesc_reactiveEnergy3: {
            ru: 'Реактивная энергия тариф 3',
            en: 'Reactive energy tariff 3',
        },
        InputDesc_reactiveEnergy4: {
            ru: 'Реактивная энергия тариф 4',
            en: 'Reactive energy tariff 4',
        },
        Parameter: {
            ru: 'Параметр'
        },
        InterviewDevice: {
            ru: 'Опрос устройства',
            en: 'Interview device',
        },
        ToInterviewDevice: {
            ru: 'Опросить устройство',
            en: 'Interview device',
        },
        InterviewDeviceError: {
            ru: 'При опросе утройства произошла ошибка. Возможно, устройство подключено неверно.',
            en: 'An error occurred during device interview. Perhaps the device is connected incorrectly.',
        },
        InterviewParameterError: {
            ru: 'Возникли проблемы при опросе параметра',
            en: 'An error occurred during parameter interview',
        },
        DeviceNotInterviewed: {
            en: 'Device not interviewed',
            ru: 'Устройство ещё не опрашивалось',
        },
        RequestFailed: {
            ru: 'Ошибка запроса.',
            en: 'Request failed.',
        },
        Command: {
            ru: 'Команда',
            en: 'Command',
        },
        Result: {
            ru: 'Результат',
            en: 'Result',
        },
        OK: {
            ru: 'OK',
            en: 'OK',
        },
        Start: {
            en: 'Start',
            ru: 'Пуск',
        },
        Stop: {
            en: 'Stop',
            ru: 'Стоп',
        },
        BackToTop: {
            en: 'Top',
            ru: 'Наверх',
        },
        ChangeHouseVariableTo: {
            en: 'Change value of the house variable «#variable» to',
            ru: 'Изменить значение переменной дома «#variable» на',
        },
        PageNotFound: {
            en: 'Page not found',
            ru: 'Данной страницы не существует'
        },
        Loading: {
            en: 'Loading',
            ru: 'Загрузка'
        },
        SearchByAddress: {
            en: 'Search by address',
            ru: 'Поиск по адресу',
        },
        FileDownloadError: {
            en: 'File download error',
            ru: 'Ошибка скачивания файла',
        },
        ConfirmDeleteTemplate: {
            en: 'Do you really want to remove this template?',
            ru: 'Вы действительно хотите удалить этот шаблон?',
        },
        TemplateDeleteError: {
            en: 'Template delete error',
            ru: 'Ошибка удаления шаблона',
        },
        enterEmailAddress: {
            en: 'enter email address',
            ru: 'введите адрес электронной почты',
        },
        GetReportSettingsError: {
            en: 'Error getting template settings',
            ru: 'Ошибка получения настроек шаблона',
        },
        SaveReportSettingsError: {
            en: 'Error saving template settings',
            ru: 'Ошибка сохранения настроек шаблона',
        },
        ReportStartError: {
            en: 'Report start error',
            ru: 'Ошибка запуска шаблона',
        },
        GetDevicesError: {
            en: 'Get devices error!',
            ru: 'Ошибка получения списка устройств!',
        },
        AttentionDataNotChanged: {
            en: 'Attention! The data not changed more than a day',
            ru: 'Внимание! Данные не изменялись больше суток',
        },
        TotalMeasures: {
            en: 'Total measures',
            ru: 'Всего показаний',
        },
        DevicesDisconnected: {
            en: 'Devices disconnected',
            ru: 'Устройств не подключено',
        },
        DevicesOnline: {
            en: 'Devices online',
            ru: 'Устройств онлайн',
        },
        AllRoles: {
            en: 'All roles',
            ru: 'Все роли',
        },
        AllControllers: {
            en: 'All controllers',
            ru: 'Все контроллеры',
        },
        AllDeviceTypes: {
            en: 'All device types',
            ru: 'Все типы устройств',
        },
        InvalidDateRange: {
            en: 'Invalid date range',
            ru: 'Неверный диапазон дат',
        },
        AtLeastOneValueIsRequired: {
            en: 'At least one value is required',
            ru: 'Требуется хотя бы одно значение',
        },
        IncorrectForm: {
            en: 'Form is incorrect',
            ru: 'Форма заполнена неверно',
        },
        Firmwares: {
            en: 'Firmwares',
            ru: 'Прошивки',
        },
        HousesNeedRefresh: {
            en: 'Houses need refresh',
            ru: 'В следующих домах произошли изменения',
        },
        INFO_CONTROLLER_ADD: {
            en: 'Added controllers',
            ru: 'Добавлено контроллеров',
        },
        INFO_CONTROLLER_REMOVE: {
            en: 'Removed controllers',
            ru: 'Удалено контроллеров',
        },
        INFO_DEVICE_ADD: {
            en: 'Added devices',
            ru: 'Добавлено устройств',
        },
        INFO_DEVICE_REMOVE: {
            en: 'Removed devices',
            ru: 'Удалено устройств',
        },
        DeviceGroupingParameter: {
            en: 'Device grouping parameter',
            ru: 'Параметр группировки устройств',
        },
        ToHouse: {
            en: 'to house',
            ru: 'к дому',
        },
        ToController: {
            en: 'to controller',
            ru: 'к контроллеру',
        },
        DeviceSupportsSafeMode: {
            en: 'The device supports safe mode, please enter the DSK code (last 5 digits)',
            ru: 'Устройство поддерживает безопасный режим, пожалуйста, введите код DSK (последние 5 цифр)'
        },
        DeviceAttachedInInsecureMode: {
            en: 'Entered invalid DSK code. The device was attached in insecure mode. For switch to safe mode - remove device and add it again.',
            ru: 'Введен неверный код DSK. Устройство было добавлено в небезопасном режиме. Для перехода на безопасный режим - удалите устройство и повторите добавление.'
        },
        ConnectInInsecureMode: {
            en: 'Connect in insecure mode',
            ru: 'Подключиться в небезопасном режиме'
        },
        AddDeviceMode: {
            en: 'Add device mode',
            ru: 'Режим добавления устройства'
        },
        NoApartmentsInSelectedHouse: {
            en: 'No apartments in selected house',
            ru: 'В выбранном доме квартиры не заданы'
        },
        GetAddressesError: {
            en: 'Addresses load error',
            ru: 'Ошибка загрузки списка адресов'
        },
        DownloadingAddressesInformation: {
            en: 'Downloading addresses information',
            ru: 'Загрузка информации об адресах'
        },
        Type: {
            en: 'Type',
            ru: 'Тип',
        },
        AddingNewDevices: {
            en: 'Adding of new devices',
            ru: 'Добавление новых устройств',
        },
        ExecuteAdding: {
            en: 'Execute adding',
            ru: 'Выполнить добавление',
        },
        CancelAddingDevices: {
            en: 'Cancel adding devices',
            ru: 'Отменить добавление устройств',
        },
        EnterChannelsCount: {
            en: 'Enter channels count',
            ru: 'Введите количество каналов',
        },
        IndividualSettings: {
            en: 'Individual settings',
            ru: 'Индивидуальные настройки',
        },
        DeviceTitle: {
            en: 'Device title',
            ru: 'Название устройства',
        },
        ChannelTitle: {
            en: 'Channel title',
            ru: 'Название канала',
        },
        enterChannelTitle: {
            en: 'enter channel title',
            ru: 'введите название канала',
        },
        Added: {
            en: 'Added',
            ru: 'Добавлено',
        },
        XDeviceCount: {
            en: params => pluralizeEn(params.X, 'new devices'),
            ru: params => pluralizeRu(params.X, ['новое устройство', 'новых устройства', 'новых устройств'])
        },
        IfDidNotFillOut: {
            en: 'If you didn’t fill out the device information,\nyou can do this later on the device management page.',
            ru: 'Если вы не заполнили информацию об устройстве,\nвы можете сделать это позже на странице управления устройством.',
        },
        GetPseudoDeviceError: {
            en: 'Get device settings error.\nCheck data and try again.',
            ru: 'Ошибка получения настройки устройства.\nПроверьте данные и повторите попытку.',
        },
        GetControllersError: {
            en: 'Get controllers error.\nCheck data and try again.',
            ru: 'Ошибка получения списка контроллеров.\nПроверьте данные и повторите попытку.',
        },
        Done: {
            en: 'Done',
            ru: 'Готово',
        },
        RequiredField: {
            en: 'Required field',
            ru: 'Поле обязательно для заполнения',
        },
        ConfigPattern: {
            en: 'Config pattern',
            ru: 'Шаблон конфигурации',
        },
        BulkAddingError: {
            en: 'Bulk adding error!\nCheck data and try again.',
            ru: 'Ошибка множественного добавления!\nПроверьте данные и попробуйте снова.',
        },
        MustConfigureOneParameter: {
            en: 'You must configure one parameter at least',
            ru: 'Необходимо выполнить настройку хотя бы одного параметра.',
        },
        Select: {
            en: 'Select',
            ru: 'Выберите',
        },
        LimitSettings: {
            en: 'Threshold settings',
            ru: 'Настройка пороговых значений',
        },
        Organizations: {
            en: 'Organizations',
            ru: 'Организации',
        },
        Organization: {
            en: 'Organization',
            ru: 'Организация',
        },
        NoLinkableOrganizations: {
            en: 'No linkable organizations',
            ru: 'Доступные для привязки организации отсутствуют',
        },
        AddOrganization: {
            en: 'Add organization',
            ru: 'Добавить организацию',
        },
        LinkOrganization: {
            en: 'Link organization',
            ru: 'Привязать организацию',
        },
        SelectOrganization: {
            en: 'Select organization...',
            ru: 'Выберите организацию...',
        },
        Configured: {
            en: 'Configured',
            ru: 'Настроено',
        },
        XEntrancesCount: {
            en: params => pluralizeEn(params.X, 'entrances'),
            ru: params => pluralizeRu(params.X, ['вход', 'входа', 'входов'])
        },
        by: {
            en: 'by',
            ru: 'из',
        },
        DistributionOn: {
            en: 'distribution on',
            ru: 'распределение на',
        },
        XChannelsCount: {
            en: params => pluralizeEn(params.X, 'channels'),
            ru: params => pluralizeRu(params.X, ['канал', 'канала', 'каналов'])
        },
        Cameras: {
            en: 'Cameras',
            ru: 'Камеры',
        },
        Camera: {
            en: 'Camera',
            ru: 'Камера',
        },
        VideoPlatform: {
            en: 'Video platform',
            ru: 'Видеоплатформа',
        },
        AddedVideoPlatform: {
            en: 'Added video platform',
            ru: 'Видеоплатформа добавлена',
        },
        ConfirmRemovePlatform: {
            en: 'Do you really want to unlink the user from the video platform and remove all data?',
            ru: 'Вы действительно хотите отвязать пользователя от видеоплатформы и удалить все данные?',
        },
        UserSynchronisedSuccessfully: {
            en: 'User synchronised successfully',
            ru: 'Пользователь синхронизирован',
        },
        Block: {
            en: 'Block',
            ru: 'Заблокировать',
        },
        Unblock: {
            en: 'Unblock',
            ru: 'Разблокировать',
        },
        SyncWithExistingUser: {
            en: 'Synchronize with existing user of video platform',
            ru: 'Синхронизировать с существующим пользователем видеоплатформы',
        },
        AddNewUser: {
            en: 'Add new user',
            ru: 'Добавить нового пользователя',
        },
        EnterLoginForSync: {
            en: 'Enter Flussonic video platform login for synchronization.',
            ru: 'Введите логин пользователя в видеоплатформе Flussonic, с которым нужно провести синхронизацию.',
        },
        UserFoundWithData: {
            en: 'User found with data',
            ru: 'Найден пользователь с данными',
        },
        Synchronize: {
            en: 'Synchronize',
            ru: 'Синхронизировать',
        },
        SynchronizationError: {
            en: 'Synchronization Error',
            ru: 'Ошибка синхронизации',
        },
        UserAlreadySynchronized: {
            en: 'User already synchronized',
            ru: 'Пользователь уже синхронизирован',
        },
        NeedPhoneOrEmail: {
            en: 'Need save phone or email',
            ru: 'Необходимо сохранить телефон или почту',
        },
        UnsupportedControllerTypeX: {
            en: 'Unsupported controller type "#type"',
            ru: 'Неподдерживаемый тип контроллера "#type"',
        },
        ServerNotAvailable: {
            en: 'Attention! Server is not available.',
            ru: 'Внимание! Сервер недоступен.',
        },
        NoActiveLicense: {
            en: 'Attention! No active license.',
            ru: 'Внимание! Нет активной лицензии.',
        },
        LicenseExpiresSoon: {
            en: 'Attention! License expires soon.',
            ru: 'Внимание! Лицензия скоро истекает.',
        },
        TimeZone: {
            en: "Time zone",
            ru: "Часовой пояс",
        },
        TimeZoneParis: {
            en: "(UTC+01:00) Paris",
            ru: "(UTC+01:00) Париж",
        },
        TimeZoneKaliningrad: {
            en: "(UTC+02:00) Kaliningrad",
            ru: "(UTC+02:00) Калининград",
        },
        TimeZoneMoscow: {
            en: "(UTC+03:00) Moscow",
            ru: "(UTC+03:00) Москва",
        },
        TimeZoneVolgograd: {
            en: "(UTC+04:00) Volgograd",
            ru: "(UTC+04:00) Волгоград",
        },
        TimeZoneYekaterinburg: {
            en: "(UTC+05:00) Yekaterinburg",
            ru: "(UTC+05:00) Екатеринбург",
        },
        TimeZoneOmsk: {
            en: "(UTC+06:00) Omsk",
            ru: "(UTC+06:00) Омск",
        },
        TimeZoneKrasnoyarsk: {
            en: "(UTC+07:00) Krasnoyarsk",
            ru: "(UTC+07:00) Красноярск",
        },
        TimeZoneIrkutsk: {
            en: "(UTC+08:00) Irkutsk",
            ru: "(UTC+08:00) Иркутск",
        },
        TimeZoneYakutsk: {
            en: "(UTC+09:00) Yakutsk",
            ru: "(UTC+09:00) Якутск",
        },
        TimeZoneVladivostok: {
            en: "(UTC+10:00) Vladivostok",
            ru: "(UTC+10:00) Владивосток",
        },
        TimeZoneMagadan: {
            en: "(UTC+11:00) Magadan",
            ru: "(UTC+11:00) Магадан",
        },
        TimeZoneKamchatka: {
            en: "(UTC+12:00) Kamchatka",
            ru: "(UTC+12:00) Камчатка",
        },
        TimeZoneEnderbury: {
            en: "(UTC+13:00) Enderbury",
            ru: "(UTC+13:00) Эндербери",
        },
        TimeZoneKiritimati: {
            en: "(UTC+14:00) Kiritimati",
            ru: "(UTC+14:00) Кирибати",
        },
        TimeZoneCape_Verde: {
            en: "(UTC-01:00) Cape Verde",
            ru: "(UTC-01:00) Кабо-Верде",
        },
        TimeZoneGodthab: {
            en: "(UTC-02:00) Godthab",
            ru: "(UTC-02:00) Нуук",
        },
        TimeZoneAtlantic: {
            en: "(UTC-03:00) Atlantic",
            ru: "(UTC-03:00) Атлантическая Канада",
        },
        TimeZoneSantiago: {
            en: "(UTC-04:00) Santiago",
            ru: "(UTC-04:00) Сантьяго",
        },
        TimeZoneChicago: {
            en: "(UTC-05:00) Chicago",
            ru: "(UTC-05:00) Чикаго",
        },
        TimeZoneGuatemala: {
            en: "(UTC-06:00) Guatemala",
            ru: "(UTC-06:00) Гватемала",
        },
        TimeZoneLos_Angeles: {
            en: "(UTC-07:00) Los Angeles",
            ru: "(UTC-07:00) Лос-Анджелес",
        },
        TimeZoneAlaska: {
            en: "(UTC-08:00) Alaska",
            ru: "(UTC-08:00) Аляска",
        },
        TimeZoneAleutian: {
            en: "(UTC-09:00) Aleutian",
            ru: "(UTC-09:00) Алеутские острова",
        },
        TimeZoneHonolulu: {
            en: "(UTC-10:00) Honolulu",
            ru: "(UTC-10:00) Гонолулу",
        },
        TimeZoneSamoa: {
            en: "(UTC-11:00) Samoa",
            ru: "(UTC-11:00) Самоа",
        },
        ShowAll: {
            en: 'Show all',
            ru: 'Показать все',
        },
        NoPages: {
            en: 'This user has no pages available',
            ru: 'У данного пользователя нет доступных страниц',
        },
        Analytics: {
            en: 'Analytics',
            ru: 'Аналитика',
        },
        GoToAnalytics: {
            en: 'Go to analytics',
            ru: 'Перейти к аналитике',
        },
        AnalyticsEditor: {
            en: 'Analytics editor',
            ru: 'Редактор',
        },
        AnalyticsCopying: {
            en: 'Analytics copying',
            ru: 'Копирование настроек аналитики',
        },
        Statistics: {
            en: 'Statistics',
            ru: 'Статистика',
        }
    };
});
