define([
    'knockout',
    'text!./template.html'
], (ko, template) => {

    class ViewModel {
        constructor(params) {
            this.commands = params.commands;
            this.onActionSelect = params.onActionSelect;
            this.currentCommand = ko.observable(this.commands()[0]);
            this.onActionSelect(this.currentCommand());
            this.currentCommandSub = this.currentCommand.subscribe(this.onActionSelect);
        }

        dispose() {
            this.currentCommandSub.dispose();
        }
    }

    ko.components.register('action-command-editor', {viewModel: ViewModel, template});

});
