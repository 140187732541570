define([
    'knockout',
    'knockoutmapping',
    'core/config',
    'core/devices-lib',
    'core/utils',
    'text!./template.html'
], function (ko, komapping, config, DevicesLib, Utils, template) {
    'use strict';

    const notEmptyString = str => typeof str === 'string' && str !== '';

    const DEVICE_TYPES = DevicesLib.DEVICE_TYPES;

    function ViewModel(params) {
        this.DEVICE_TYPES = DEVICE_TYPES;
        this.Z_WAY_DEVICE_TYPES = DevicesLib.Z_WAY_DEVICE_TYPES;
        this.CUSTOM_CODE_MODEL_VALUE = DevicesLib.CUSTOM_CODE_MODEL_VALUE;
        this.device = typeof params.device === 'function' ? params.device() : params.device;
        this.controllerId = this.device.controllerId();
        this.nodeId = this.device.nodeId();

        // вкл/выкл
        this.device.on_offImage = ko.computed(() => {
            if (this.device.needSwitch()) {
                if (this.device.status() !== 'OFFLINE' && this.device.status() !== 'NOT_WORKING') {
                    const value = this.device.zWayDeviceIsComplex() ?
                        this.device.zWayDeviceState().onOff.value :
                        this.device.zWayDeviceState().value;
                    const isOn = value === 'true' ? 'on' : 'off';
                    return 'img/sw_' + isOn + '.png';
                } else
                    return 'img/sw_dis.png';
            }
        });

        this.device.switchMultiLevelSubscriptions = [];

        this.device.switchMultiLevel = ko.computed(() => {
            this.device.switchMultiLevelSubscriptions.forEach(sub => sub.dispose());
            this.device.switchMultiLevelSubscriptions = [];

            const state = this.device.zWayDeviceState();

            if (!state.switchMultiLevel) {
                return [];
            }

            const result = state.switchMultiLevel.map(prop => {
                return {
                    propertyId: prop.propertyId,
                    value: ko.observable(prop.value),
                    valueLimits: ko.observable(prop.valueLimits)
                }
            });

            result.forEach(propSwitchMultiLevel => {
                const property = DevicesLib.getPropertyById(this.device, propSwitchMultiLevel.propertyId);
                const subscription = propSwitchMultiLevel.value
                    .extend({rateLimit: {timeout: 500, method: 'notifyWhenChangesStop'}})
                    .subscribe(newValue => {
                        config.setDeviceProperty({
                            controllerId: this.controllerId,
                            nodeId: this.nodeId,
                            propertyId: propSwitchMultiLevel.propertyId,
                            value: newValue
                        }).next(() => {
                            property.value(newValue)
                        }).error(error => {
                            console.error(error);
                            alert(T('RequestFailed'));
                        });
                    });
                this.device.switchMultiLevelSubscriptions.push(subscription);
            });

            return result;
        });

        //
        this.device.isShowPower = ko.computed(function () {
            if (this.device.property().kind() === 'VOLTAGE') {
                for(var i = 0, property; property = this.device.channels[1].deviceProperties()[i]; i++) {
                    if (property.kind() === 'ON_OFF')
                        return property.value();
                }
            }
            return null;
        }, this);

        //
        this.device.isDangerVoltage = ko.computed(function () {
            var dangerValue = false;
            if (this.device.property().value() < 207 || this.device.property().value() > 253)
                dangerValue = true;
            return dangerValue;
        }, this);

        // нужно ли ставить красный треугольник
        this.device.isDanger = ko.computed(function () {
            var dangerValue = false;
            if (this.device.status() === 'SOMETHING_WRONG')
                dangerValue = false;
            else if (this.device.status() === 'OFFLINE' || this.device.status() === 'NOT_WORKING')
                dangerValue = true;
            else if (this.device.status() === 'DISABLED')
                dangerValue = false;
            else if (this.device.property().hasOwnProperty('value'))
                dangerValue = this.device.property().value() === 'true'
            return dangerValue;
        }, this);

        // нужно ли ставить оранжевый треугольник
        this.device.isWarning = ko.computed(function () {
            return this.device.status() === 'SOMETHING_WRONG'
                || this.device.displayValue() === 'Attention';
        }, this);

        this.onlyDigits = Utils.onlyDigits;

        this.AddrCount = function (address, personal) {
            if (notEmptyString(address)) {
                return address;
            }
            if (notEmptyString(personal)) {
                return Utils.onlyDigits(personal) ? '…' + personal.substr(-4) : personal;
            }
            return '—';
        };

        this.AddrCountTitle = (address, personal) => {
            if (notEmptyString(address)) {
                return Utils.onlyDigits(address) ? '' : address;
            }
            if (notEmptyString(personal)) {
                return personal;
            }
            return '';
        };

        this.needFooter = ko.observable(params.needFooter || false);
        this.canEdit = ko.observable(params.canEdit && params.canEdit() || false);
        this.needCloseButton = ko.observable(params.needCloseButton || false);
        this.closeButtonHandler = params.closeButtonHandler;
        this.on_off = function(data) {
            for(var i = 0, property; property = data.channels[1].deviceProperties()[i]; i++) {
                if (property.kind() === 'ON_OFF') {
                    property.value(property.value() === 'true' ? 'false' : 'true');
                    config
                        .setDeviceProperty({controllerId: data.controllerId(), nodeId: data.nodeId(), propertyId: property.id(), value: property.value()})
                        .error(property.value.bind(property.value, !property.value()));
                }
            }
        };

        this.disabledAndMutedIconsInWidgetFooter = ko.computed(() => {
            return ['VOLTAGE', 'METER_COMPLEX', 'WATER_METER_COMPLEX'].includes(this.device.property().kind()) ||
                this.device.deviceType() === DEVICE_TYPES.ETHERNET_COM.MERCURY_230 ||
                this.device.deviceType() === DEVICE_TYPES.ETHERNET_COM.MERCURY_206 ||
                this.device.deviceType() === DEVICE_TYPES.ETHERNET_COM.TSRV_034 ||
                this.device.deviceType() === DEVICE_TYPES.ETHERNET_COM.TSRV_043 ||
                this.device.deviceType() === DEVICE_TYPES.ETHERNET_COM.TSRV_026M ||
                this.device.deviceType() === DEVICE_TYPES.ETHERNET_COM.PULSAR ||
                this.device.deviceType() === DEVICE_TYPES.ETHERNET_COM.ENERGOMERA_CE102M_R5;
        });

        this.needAlternateBody = ko.computed(() => {
            return this.device.property().kind() === 'METER_COMPLEX' ||
                this.device.property().kind() === 'WATER_METER_COMPLEX' ||
                !this.device.isZWayDevice();
        });

        this.getApartmentValue = device => {
            let apartments = [];
            for (let channel in device.channels) {
                const apartment = device.channels[channel].apartment();
                if (apartment !== '') {
                    apartments.push(apartment);
                }
            }
            apartments.sort(Utils.compareNumeric);
            return apartments.join(', ');
        };

        this.getPulsarWidgetValue = device => {
            for (let channel in device.ethernetComProperties()) {
                const property = device.ethernetComProperties()[channel].find(prop => prop.kind === 'WATER_ENERGY');
                if (property !== undefined) {
                    return property;
                }
            }
        };
    }

    ko.components.register('widget-device', {
        viewModel: ViewModel,
        template: template
    });
});
